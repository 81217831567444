import { useSelector } from "react-redux"
import Icon from "../../../../../assets/gifs/amaizing-icon.gif"
import { TourCloseIcon } from "../../../../../assets/svgs/tourCloseIcon.svg"
import { AuthStateSelector } from "../../../../../selectors/authState.selector"

import { ButtonElement } from "../../../../elements/button.element"
import { PrimaryText } from "../../../../elements/primaryText.element"
const PtQuickTourStepOne: React.FC<PerformanceTrackerQuickTourProps> = ({
    headerText,
    handleCloseDialog,
    handleNext,
    image,
    currentStep,
    totalSteps,
}) => {
    const { getAccessLevel } = useSelector(AuthStateSelector)
    const displayName = getAccessLevel?.first_name
        ? getAccessLevel?.first_name
        : getAccessLevel && getAccessLevel?.username

    return (
        <>
            <div className="relative bg-gray-900 h-screen w-screen">
                <div
                    className=" mx-auto max-w-[348px] bg-[#088AB2] rounded-[16px] p-[12px] absolute top-[50%] left-[50%] -translate-y-2/4	-translate-x-2/4"
                    style={{
                        boxShadow: "0px 8px 8px -4px #10182808, 0px 20px 24px -4px #10182814",
                    }}
                >
                    {/* Header */}
                    <div className="flex justify-between items-center mb-[26px]">
                        <div className="flex items-center space-x-2">
                            <img src={Icon} className="h-[26px] w-[28px]" alt="icon" />

                            <PrimaryText size="small" weight="medium" className="text-white leading-[14px]">
                                {headerText}
                            </PrimaryText>
                        </div>
                        {handleCloseDialog && (
                            <div onClick={handleCloseDialog} className="cursor-pointer">
                                <TourCloseIcon />
                            </div>
                        )}
                    </div>

                    {/* Image */}
                    <div className="w-[324px]">
                        <img className="w-full rounded-lg" src={image} alt="Performance Tracker" />
                    </div>

                    {/* Content */}
                    <div className="mt-[12px] p-[12px] bg-[#06AED4] rounded-[8px]">
                        <PrimaryText weight="book" size="xs" className="text-white mb-[13px] capitalize">
                            Hey {displayName}!
                        </PrimaryText>
                        <PrimaryText weight="book" size="xs" className="text-white mb-[13px]">
                            Welcome to my{" "}
                            <PrimaryText size="xs" className="text-white inline font-[700]">
                                Performance Tracker.
                            </PrimaryText>
                        </PrimaryText>
                        <PrimaryText weight="book" size="xs" className="text-white">
                            Here you’ll be able to see how the ASINs in each of your Trackpacks are performing against
                            their most relevant competitors. And, more importantly, you’ll be clearer on how those
                            impacted customer purchases and affected market positions.
                        </PrimaryText>

                        {/* Footer */}
                        <div className="flex justify-between items-center mt-[24px]">
                            <PrimaryText weight="book" size="xs-medium" className="text-white">
                                {currentStep} / {totalSteps}
                            </PrimaryText>
                            <ButtonElement
                                size="small"
                                textClass="text-[500] text-[12px] text-white"
                                className="!w-[42px] !h-[26px] px-[8px] py-[4px] !bg-none text-white rounded-[6px] !hover:bg-transparent border border-white"
                                onClick={handleNext}
                            >
                                Next
                            </ButtonElement>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PtQuickTourStepOne
