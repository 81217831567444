export const NotificationHalfRedIcon = () => {
    return (
        <>
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="5" cy="5" r="3.5" fill="white" stroke="#F04438" />
                <path d="M9 5C9 7.20914 7.20914 9 5 9V1C7.20914 1 9 2.79086 9 5Z" fill="#F04438" />
            </svg>
        </>
    )
}
