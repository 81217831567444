export enum NotificationsAction {
    GET_ALL_NOTIFICATIONS = "GET_ALL_NOTIFICATIONS",
    SET_NOTIFICATIONS_DATA = "SET_NOTIFICATIONS_DATA",
    READ_NOTIFICATION = "READ_NOTIFICATION",
}

export const NotificationsActionsCreator = {
    getAllNotifications: () => {
        return {
            type: NotificationsAction.GET_ALL_NOTIFICATIONS,
        }
    },
    setNotificationsData: (data: any) => ({
        type: NotificationsAction.SET_NOTIFICATIONS_DATA,
        payload: data,
    }),

    markNotificationAsRead: (
        payload: Partial<ReadNotifications>,
        onSuccess?: () => void,
        onError?: (data: { message: string }) => void
    ): ISagaAction => {
        return {
            type: NotificationsAction.READ_NOTIFICATION,
            payload,
            onSuccess,
            onError,
        }
    },
}
