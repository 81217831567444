import { API_ROUTES } from "../config/routes.config"
import { HttpHelper } from "../helpers/http.helper"
import { UtilHelper } from "../helpers/util.helper"

export class NotificationService {
    static async getAllNotifications() {
        const result = await HttpHelper.sendRequest(API_ROUTES.GET_ALL_NOTIFICATIONS, {}, "GET")

        return HttpHelper.validateResponse<IAlertSettingState>(result)
    }

    static async readNotifications(payload: any): Promise<Response> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.READ_NOTIFICATION,
            {
                ...payload,
            },
            "POST"
        )

        return result && !UtilHelper.isEmptyObject(result) && !result.error ? result : undefined
    }
}
