import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { ProductsActionCreator } from "../../../../actions/products.action"
import { CrossNewIcon } from "../../../../assets/svgs/xIcon.svg"
import { ProductStateSelector } from "../../../../selectors/product.selector"
import StepIndicator from "../../../common/stepIndicator.component"
import { ButtonElement } from "../../../elements/button.element"
import { PrimaryText } from "../../../elements/primaryText.element"
import SelectInputElement from "../../../elements/selectInput.component"
import { AMAZON_DOMAINS } from "../../../marketing/domains.config"

const AddTrackpackStepInput: React.FC<AddTrackPackProps> = ({
    totalSteps,
    asinHeaderText,
    asinHeaderDescription,
    icon,
    handleCloseDialog,
    handleNext,
    buttonText,
    currentStep,
}) => {
    const { control, handleSubmit, watch, setValue } = useForm()
    const dispatch = useDispatch()
    const amazon = watch("amazon_tId")
    const data = useSelector(ProductStateSelector)

    const selected_country = AMAZON_DOMAINS?.find((val) => {
        return val?.value === data?.selectedTrackpackPayload?.amazon_tld
    })

    const onSubmit = () => {
        dispatch(ProductsActionCreator.selectedTrackpackPayload({ amazon_tld: amazon.value }))
        handleNext && handleNext()
    }

    useEffect(() => {
        if (selected_country) {
            setValue("amazon_tId", selected_country)
        }
    }, [selected_country, setValue])

    return (
        <>
            <div className="flex items-center justify-between">
                <div className="bg-[#A5F0FC] rounded-[12px] p-[10px]">{icon}</div>
                <div className="cursor-pointer">
                    <CrossNewIcon onClick={handleCloseDialog} />
                </div>
            </div>
            <PrimaryText className="text-gray-700 text-[24px] font-medium mt-[24px] ml-[6px]">
                {asinHeaderText}
            </PrimaryText>
            {asinHeaderDescription ? (
                <PrimaryText size="sm-medium" weight="light" className="text-gray-700 mt-[16px] ml-[6px]">
                    {asinHeaderDescription}
                </PrimaryText>
            ) : (
                <PrimaryText size="sm-medium" weight="light" className="text-gray-700 mt-[16px] ml-[6px]">
                    Give me the ASIN numbers you want me to add to your
                    <br />
                    <PrimaryText weight="medium" className="text-gray-700 inline-block">
                        Men’s Suit Jackets&nbsp;
                    </PrimaryText>
                    <PrimaryText size="sm-medium" weight="light" className="inline text-gray-700">
                        Trackpack.
                    </PrimaryText>
                </PrimaryText>
            )}

            <form onSubmit={handleSubmit(onSubmit)} className=" mt-[40px]">
                <>
                    {" "}
                    <SelectInputElement
                        name="amazon_tId"
                        reactHookControl={control}
                        reactHookValidations={{ required: true }}
                        defaultValue={null}
                        countries={AMAZON_DOMAINS}
                        placeholder="Choose market..."
                        isOptionImage={true}
                        isInputImage={true}
                        errorMessage="Please choose your market to continue "
                    />
                    <div className="flex justify-center items-center mt-12">
                        <StepIndicator currentStep={currentStep} totalSteps={totalSteps} />
                    </div>
                    <div className="flex justify-center items-center mt-[48px]">
                        <ButtonElement
                            type="submit"
                            size="large"
                            className={"w-[99px] bg-[#A5F0FC] rounded-[8px] h-[44px] text-[#0E7090] text-[14px]"}
                        >
                            {buttonText}
                        </ButtonElement>
                    </div>
                </>
            </form>
        </>
    )
}

export default AddTrackpackStepInput
