import AddTrackPackOutlet from "./addTrackPackOutlet.component"
import { AddTrackPackWrapper } from "./addtrackPackWrapper"

const AddTrackPackMain = () => {
    return (
        <AddTrackPackWrapper
            className={`p-[48px] pb-[24px] absolute top-[50%] left-[50%] -translate-y-2/4 -translate-x-2/4 bg-white rounded-[24px] w-[600px]  shadow-modal-shadow border-[1px] border-solid border-[#E7EBEF] transition-all`}
        >
            <AddTrackPackOutlet />
        </AddTrackPackWrapper>
    )
}

export default AddTrackPackMain
