import { ArrowLeft } from "../../assets/svgs/arrowLeftIcon.svg"
import { ArrowRight } from "../../assets/svgs/arrowRightIcon.svg"
import { ButtonElement } from "../elements/button.element"

const ArrowModalActions = ({ active, total, setActive }: IModalArrowActionsProps) => {
    return (
        <div>
            {total > 1 && (
                <div className="flex gap-[4px] items-center">
                    <ButtonElement
                        onClick={() => {
                            if (active <= 1) {
                                return
                            }
                            setActive(active - 1)
                        }}
                        viewType="only-icon"
                        disabled={active <= 1}
                    >
                        <ArrowLeft />
                    </ButtonElement>
                    <ButtonElement
                        onClick={() => {
                            if (!(active < total)) {
                                return
                            }
                            setActive(active + 1)
                        }}
                        viewType="only-icon"
                        disabled={!(active < total)}
                    >
                        <ArrowRight />
                    </ButtonElement>
                </div>
            )}
        </div>
    )
}
export default ArrowModalActions
