import { useDispatch, useSelector } from "react-redux"
import { AuthActionCreator } from "../../../../../actions/auth.action"
import { SettingsActionsCreater } from "../../../../../actions/settings.action"
import Icon from "../../../../../assets/gifs/amaizing-icon.gif"
import { TourCloseIcon } from "../../../../../assets/svgs/tourCloseIcon.svg"
import { AuthStateSelector } from "../../../../../selectors/authState.selector"
import { ButtonElement } from "../../../../elements/button.element"
import { PrimaryText } from "../../../../elements/primaryText.element"
const PtQuickTourStepTwelve: React.FC<PerformanceTrackerQuickTourProps> = ({
    headerText,
    handleCloseDialog,
    totalSteps,
    currentStep,
}) => {
    const dispatch = useDispatch()
    const { getAccessLevel } = useSelector(AuthStateSelector)
    const handleClose = () => {
        const payload = {
            initial_qt_completed: true,
        }

        dispatch(
            SettingsActionsCreater.updateInitialQuickTour({ ...payload }, () => {
                dispatch(AuthActionCreator.getAccessLevel())
            })
        )

        handleCloseDialog && handleCloseDialog()
    }

    return (
        <>
            {/* Header */}
            <div className="flex justify-between items-center">
                <div className="flex items-center space-x-2">
                    <img src={Icon} className="h-[26px] w-[28px]" alt="icon" />

                    <PrimaryText size="small" weight="medium" className="text-white">
                        {headerText}
                    </PrimaryText>
                </div>
                {getAccessLevel && getAccessLevel?.user_settings?.initial_qt_completed === true && (
                    <div onClick={handleCloseDialog} className="cursor-pointer">
                        <TourCloseIcon />
                    </div>
                )}
            </div>

            {/* Content */}
            <div className="mt-[26px] p-[12px] bg-[#06AED4] rounded-[8px]">
                <PrimaryText weight="book" size="xs" className="text-white mb-[10px]">
                    Anything I need to make you aware of sits in your Alerts dropdown. This can be anything from changes
                    to ASINs in your Trackpacks, to changes to your account, profile or billing.
                </PrimaryText>
                <PrimaryText weight="book" size="xs" className="text-white ">
                    I’ll also let you know when I’ve got news or new features to share.
                </PrimaryText>
                {/* Footer */}
                <div className="flex justify-between items-center mt-[24px]">
                    <PrimaryText weight="book" size="xs-medium" className="text-white">
                        {currentStep} / {totalSteps}
                    </PrimaryText>
                    <ButtonElement
                        size="small"
                        textClass="text-[500] text-[12px] text-white"
                        className="!w-[49px] !h-[26px] px-[8px] py-[4px] !bg-none text-white rounded-[6px] !hover:bg-transparent border border-white"
                        onClick={handleClose}
                    >
                        Finish
                    </ButtonElement>
                </div>
            </div>
        </>
    )
}

export default PtQuickTourStepTwelve
