import Icon from "../../../../../assets/gifs/amaizing-icon.gif"
import { TourCloseIcon } from "../../../../../assets/svgs/tourCloseIcon.svg"
import { ButtonElement } from "../../../../elements/button.element"
import { PrimaryText } from "../../../../elements/primaryText.element"
const ThQuickTourStepSix: React.FC<PerformanceTrackerQuickTourProps> = ({
    headerText,
    icon,
    handleCloseDialog,
    handleNext,
    image,
}) => {
    return (
        <>
            {/* <div className="relative bg-gray-900 h-screen" id="trackpack-hub">
                <img
                    src={image}
                    alt="PT Step Three"
                    className="  w-[984px] absolute left-[428px] top-[136px]"
                />
                <div
                    className=" max-w-[248px] w-full bg-[#088AB2] rounded-[16px]  p-[12px] absolute top-[75px] left-[932px] right_custom_arrow step6 translate-x-[0%] translate-y-[0%]"
                    style={{
                        boxShadow: "0px 8px 8px -4px #10182808, 0px 20px 24px -4px #10182814",
                    }}
                > */}
            {/* Header */}
            <div className="flex justify-between items-center">
                <div className="flex items-center space-x-2">
                    <img src={Icon} className="h-[26px] w-[28px]" alt="icon" />
                    <PrimaryText size="small" weight="medium" className="text-white">
                        {headerText}
                    </PrimaryText>
                </div>
                {handleCloseDialog && (
                    <div onClick={handleCloseDialog} className="cursor-pointer">
                        <TourCloseIcon />
                    </div>
                )}
            </div>

            {/* Content */}
            <div className="mt-[26px] p-[12px] bg-[#06AED4] rounded-[8px]">
                <PrimaryText weight="book" size="xs" className="text-white mb-[10px] whitespace-nowrap">
                    This cluster of controls tells you
                    <br />
                    which marketplace your&nbsp;
                    <PrimaryText size="xs" className="text-white inline font-[700]">
                        Trackpack&nbsp;
                    </PrimaryText>
                    <br />
                    relates to via the relevant flag.
                </PrimaryText>
                <PrimaryText weight="book" size="xs" className="text-white mb-[10px]">
                    If you want to view this&nbsp;
                    <PrimaryText size="xs" className="text-white inline font-[700]">
                        Trackpack&nbsp;
                    </PrimaryText>
                    in the&nbsp;
                    <PrimaryText size="xs" className="text-white inline font-[700]">
                        Performance Tracker,&nbsp;
                    </PrimaryText>
                    just click on the middle button.
                </PrimaryText>
                <PrimaryText weight="book" size="xs" className="text-white">
                    And finally, to add new&nbsp;
                    <PrimaryText size="xs" className="text-white inline font-[700]">
                        ASINs&nbsp;
                    </PrimaryText>
                    to your&nbsp;
                    <PrimaryText size="xs" className="text-white inline font-[700]">
                        Trackpacks,&nbsp;
                    </PrimaryText>
                    simply click on
                    <br /> the plus symbol and follow the
                    <br /> 2-step instructions.
                </PrimaryText>
                {/* Footer */}
                <div className="flex justify-between items-center mt-[24px]">
                    <PrimaryText weight="book" size="xs-medium" className="text-white">
                        6 / 9
                    </PrimaryText>
                    <ButtonElement
                        size="small"
                        textClass="text-[500] text-[12px] text-white"
                        className="!w-[42px] !h-[26px] px-[8px] py-[4px] !bg-none text-white rounded-[6px] !hover:bg-transparent border border-white"
                        onClick={handleNext}
                    >
                        Next
                    </ButtonElement>
                </div>
            </div>
            {/* </div>
            </div> */}
        </>
    )
}

export default ThQuickTourStepSix
