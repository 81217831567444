import { useEffect, useRef, useState } from "react"

import { useSelector } from "react-redux"
import { NotificationStateSelector } from "../../selectors/notificationStateSelector"
import { NotificationCard } from "../common/notificationCard.component"
import { PrimaryText } from "../elements/primaryText.element"

export const NotificationAlert = (props: INotificationCard) => {
    const [showNextItems, setShowNextItems] = useState(false)
    const [showMarkAllRead, setShowMarkAllRead] = useState(false)
    const [showOnlyUnread, setShowOnlyUnread] = useState(false)
    const notificationRef = useRef<HTMLDivElement | null>(null)
    const [hasScroll, setHasScroll] = useState<boolean>(false)

    const { notificationsData } = useSelector(NotificationStateSelector)

    const toggleNextItems = () => {
        setShowNextItems((prev) => !prev)
    }
    const toggleMarkAllRead = () => {
        setShowMarkAllRead((prev) => !prev)
    }

    const toggleShowOnlyUnread = () => {
        setShowOnlyUnread((prev) => !prev)
    }

    const filteredNotifications = notificationsData?.notifications.filter((notification) => {
        const { setup_alerts_and_issues, listing_changes } = notification?.product_notifications || {}

        const hasUnreadNotifications = (notificationsArray: ProductNotification[]) =>
            notificationsArray?.some((notif) => notif?.read_at === null)

        if (showOnlyUnread) {
            return hasUnreadNotifications(setup_alerts_and_issues) || hasUnreadNotifications(listing_changes)
        }

        return setup_alerts_and_issues?.length > 0 || listing_changes?.length > 0
    })

    useEffect(() => {
        const checkForScroll = () => {
            if (notificationRef.current) {
                const hasVerticalScrollbar = notificationRef.current.scrollHeight > notificationRef.current.clientHeight
                setHasScroll(hasVerticalScrollbar)
            }
        }
        checkForScroll()
        window.addEventListener("resize", checkForScroll)
        const observer = new MutationObserver(() => {
            checkForScroll()
        })

        if (notificationRef.current) {
            observer.observe(notificationRef.current, {
                childList: true,
                subtree: true,
                attributes: true,
                characterData: true,
            })
        }
        return () => {
            window.removeEventListener("resize", checkForScroll)
            observer.disconnect()
        }
    }, [])

    return (
        <div
            className={
                "w-[386px] bg-white right-0 absolute p-[12px] rounded-[22px] pb-0 border-l-gray-200 top-[40px] z-50"
            }
            style={{
                zIndex: "1000",
                // height:'calc(100vh - 108px)',
                border: "1px solid",
                borderImageSource: "linear-gradient(180deg, #F9FAFB 0%, #E7EBEF 100%)",
                boxShadow: "0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A",
            }}
        >
            <div className={"flex justify-between py-[4px] pl-[12px] pr-[0px]"}>
                <PrimaryText size={"xl"} weight={"medium"}>
                    Alerts
                </PrimaryText>
                <div className="flex items-center gap-[4px]">
                    <PrimaryText size="xs" weight="medium" className="text-cyan-500">
                        Show only unread
                    </PrimaryText>
                    <label className="switch">
                        <input
                            id="toggle-input"
                            type="checkbox"
                            checked={showOnlyUnread}
                            onChange={toggleShowOnlyUnread}
                        />
                        <span className="slider round"></span>
                    </label>
                </div>

                {/* <div className={"flex gap-[12px]"}>
                    <Menus onClick={toggleMarkAllRead} className={"cursor-pointer"} />
                    <CloseNotifications onClick={props.onClose} className={"cursor-pointer"} />
                </div> */}
            </div>
            {/* {showMarkAllRead && (
                <div className={" mt-[-30px] flex justify-center ml-[140px]"}>
                    <div
                        className={
                            "flex gap-[8px] px-[8px] py-[10px] items-center rounded-md lg:max-w-[144px] lg:max-h-[44px] border border-gray-200 shadow-md"
                        }
                    >
                        <PrimaryText size={"small"} weight={"light"} className={"text-gray-900"}>
                            Mark as all read
                        </PrimaryText>
                        <CheckIcon />
                    </div>
                </div>
            )} */}
            <div
                ref={notificationRef}
                className={`mt-[10px] overflow-auto ${hasScroll ? "pr-[8px]" : ""} notification-scroll`}
                style={{
                    maxHeight: "calc(100vh - 157px)",
                }}
            >
                {filteredNotifications?.length > 0 ? (
                    filteredNotifications?.map((product, id) => (
                        <div key={id} className={"bg-[#EAECF0] rounded-[14px] mb-[12px] p-[12px] last:mb-[12px]"}>
                            <NotificationCard product={product} onClose={props.onClose} />
                        </div>
                    ))
                ) : (
                    <PrimaryText size="small" weight="medium" className="text-gray-600 text-center my-4">
                        No notifications to display.
                    </PrimaryText>
                )}
            </div>
        </div>
    )
}
