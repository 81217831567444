export const RightArrow = (props: IArrowProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
            <rect width="10" height="10" rx="2" fill={`${props.color}`} />
            <path
                d="M3.75 7.5L6.25 5L3.75 2.5"
                stroke="white"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
