import Icon from "../../../../../assets/gifs/amaizing-icon.gif"
import { TourCloseIcon } from "../../../../../assets/svgs/tourCloseIcon.svg"
import { ButtonElement } from "../../../../elements/button.element"
import { PrimaryText } from "../../../../elements/primaryText.element"
const PtQuickTourStepEleven: React.FC<PerformanceTrackerQuickTourProps> = ({
    headerText,
    handleCloseDialog,
    handleNext,
    totalSteps,
    currentStep,
}) => {
    return (
        <>
            {/* Header */}
            <div className="flex justify-between items-center">
                <div className="flex items-center space-x-2">
                    <img src={Icon} className="h-[26px] w-[28px]" alt="icon" />

                    <PrimaryText size="small" weight="medium" className="text-white">
                        {headerText}
                    </PrimaryText>
                </div>
                {handleCloseDialog && (
                    <div onClick={handleCloseDialog} className="cursor-pointer">
                        <TourCloseIcon />
                    </div>
                )}
            </div>

            {/* Content */}
            <div className="mt-[26px] p-[12px] bg-[#06AED4] rounded-[8px]">
                <PrimaryText weight="book" size="xs" className="text-white mb-[10px]">
                    The Log view is ideal if you want to just focus on one ASIN at a time. You’ll be able to see every
                    change captured with corresponding performance metrics.
                </PrimaryText>
                {/* Footer */}
                <div className="flex justify-between items-center mt-[24px]">
                    <PrimaryText weight="book" size="xs-medium" className="text-white">
                        {currentStep} / {totalSteps}
                    </PrimaryText>
                    <ButtonElement
                        size="small"
                        textClass="text-[500] text-[12px] text-white"
                        className="!w-[42px] !h-[26px] px-[8px] py-[4px] !bg-none text-white rounded-[6px] !hover:bg-transparent border border-white"
                        onClick={handleNext}
                    >
                        Next
                    </ButtonElement>
                </div>
            </div>
        </>
    )
}

export default PtQuickTourStepEleven
