import Icon from "../../../../../assets/gifs/amaizing-icon.gif"
import { TourCloseIcon } from "../../../../../assets/svgs/tourCloseIcon.svg"
import { ButtonElement } from "../../../../elements/button.element"
import { PrimaryText } from "../../../../elements/primaryText.element"
const PtQuickTourStepTwo: React.FC<PerformanceTrackerQuickTourProps> = ({
    headerText,
    handleCloseDialog,
    handleNext,
    currentStep,
    totalSteps,
}) => {
    return (
        <>
            {/* Header */}
            <div className="flex justify-between items-center mb-[26px]">
                <div className="flex items-center space-x-2">
                    <img src={Icon} className="h-[26px] w-[28px]" alt="icon" />

                    <PrimaryText size="small" weight="medium" className="text-white">
                        {headerText}
                    </PrimaryText>
                </div>
                {handleCloseDialog && (
                    <div onClick={handleCloseDialog} className="cursor-pointer">
                        <TourCloseIcon />
                    </div>
                )}
            </div>

            {/* Content */}
            <div className=" p-[12px] bg-[#06AED4] rounded-[8px]">
                <PrimaryText weight="book" size="xs" className="text-white mb-[10px]">
                    The ASINs you give me I put into
                    <br />
                    <PrimaryText size="xs" className="text-white inline font-[700]">
                        Trackpacks,&nbsp;
                    </PrimaryText>
                    tracking every ASIN’s performance over time. Use this dropdown to select which Trackpack you want to
                    view.
                </PrimaryText>

                <PrimaryText weight="book" size="xs" className="text-white">
                    To add new&nbsp;
                    <PrimaryText size="xs" className="text-white inline font-[700]">
                        Trackpacks,&nbsp;&nbsp;
                    </PrimaryText>
                    simply click on the plus symbol and follow the
                    <br /> 4-step instructions.
                </PrimaryText>

                {/* Footer */}
                <div className="flex justify-between items-center mt-[24px]">
                    <PrimaryText weight="book" size="xs-medium" className="text-white">
                        {currentStep} / {totalSteps}
                    </PrimaryText>
                    <ButtonElement
                        size="small"
                        textClass="text-[500] text-[12px] text-white"
                        className="!w-[42px] !h-[26px] px-[8px] py-[4px] !bg-none text-white rounded-[6px] !hover:bg-transparent border border-white"
                        onClick={handleNext}
                    >
                        Next
                    </ButtonElement>
                </div>
            </div>
        </>
    )
}

export default PtQuickTourStepTwo
