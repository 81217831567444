export const NotificationIcon = () => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.6667 18.6666V21.9333C18.6667 23.24 18.6667 23.8934 18.4124 24.3926C18.1887 24.8316 17.8317 25.1886 17.3927 25.4123C16.8936 25.6666 16.2402 25.6666 14.9334 25.6666H6.06671C4.75992 25.6666 4.10652 25.6666 3.6074 25.4123C3.16835 25.1886 2.8114 24.8316 2.58769 24.3926C2.33337 23.8934 2.33337 23.24 2.33337 21.9333V13.0666C2.33337 11.7598 2.33337 11.1064 2.58769 10.6073C2.8114 10.1682 3.16835 9.81127 3.6074 9.58757C4.10652 9.33325 4.75992 9.33325 6.06671 9.33325H9.33337M13.0667 18.6666H21.9334C23.2402 18.6666 23.8936 18.6666 24.3927 18.4123C24.8317 18.1886 25.1887 17.8316 25.4124 17.3926C25.6667 16.8934 25.6667 16.24 25.6667 14.9333V6.06659C25.6667 4.7598 25.6667 4.1064 25.4124 3.60727C25.1887 3.16823 24.8317 2.81127 24.3927 2.58757C23.8936 2.33325 23.2402 2.33325 21.9334 2.33325H13.0667C11.7599 2.33325 11.1065 2.33325 10.6074 2.58757C10.1684 2.81127 9.8114 3.16823 9.58769 3.60727C9.33337 4.1064 9.33337 4.7598 9.33337 6.06658V14.9333C9.33337 16.24 9.33337 16.8934 9.58769 17.3926C9.8114 17.8316 10.1684 18.1886 10.6074 18.4123C11.1065 18.6666 11.7599 18.6666 13.0667 18.6666Z"
                stroke="#0E7090"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
