import { useMemo } from "react"
import { useSelector } from "react-redux"

import { ProductStateSelector } from "../selectors/product.selector"

export const useContainsChanges = (product?: IProductData) => {
    const { selectedProductView } = useSelector(ProductStateSelector)

    const checkIfAvailable = (item: IIndexable[] | undefined): boolean => {
        return item ? item.length > 0 : false
    }

    return useMemo(() => {
        if (product) {
            const {
                bullets,
                carousel_images,
                main_image,
                description,
                price,
                title,
                videos,
                seller_info,
                availability,
                actual_asin,
            } = product

            const show = selectedProductView?.selectedViews
                .map((view) => {
                    switch (view) {
                        case "price":
                            return checkIfAvailable(price)

                        case "main_image":
                            return checkIfAvailable(main_image)

                        case "carousel_images":
                            return checkIfAvailable(carousel_images)

                        case "title":
                            return checkIfAvailable(title)

                        case "description":
                            return checkIfAvailable(description)

                        case "bullets":
                            return checkIfAvailable(bullets)

                        case "videos":
                            return checkIfAvailable(videos)
                        case "seller_info":
                            return checkIfAvailable(seller_info)
                        case "stock":
                            return checkIfAvailable(availability)
                        case "actual_asin":
                            return checkIfAvailable(actual_asin)
                        default:
                            return false
                    }
                })
                .filter(Boolean)

            return show && show.length > 0
        }
    }, [product, selectedProductView?.selectedViews])
}
