import { FocusEvent, useState } from "react"
import { Controller } from "react-hook-form"
// @ts-ignore
import CreatableSelect from "react-select/creatable"
import { ReactComponent as AlertIcon } from "../../assets/svgs/alert-icon.svg"

const createOption = (label: string) => ({
    label,
    value: label,
})

const createOptionsFromString = (value: string) => {
    return value
        ? value?.split(",").map((value: string) => {
              return createOption(value)
          })
        : []
}

const TagInputElement = (props: ITagInputElementProps) => {
    const [inputValue, setInputValue] = useState("")
    const validateTags = (value: string) => {
        if (!value || value === "") {
            return "Please enter at least 1 ASIN number to continue"
        }
        const tags = value?.split(",")?.map((tag) => tag.trim())
        for (let tag of tags) {
            if (!/^[a-zA-Z0-9]{10}$/.test(tag)) {
                return `ASIN numbers must contain 10 characters`
            }
        }
        return true
    }

    function findInvalidLengthIndex(options: { label: string; value: string }[]) {
        const invalidIndexes = []
        for (let i = 0; i < options.length; i++) {
            if (options[i].label.length !== 10 || options[i].value.length !== 10) {
                invalidIndexes.push(i)
            }
        }
        return invalidIndexes
    }

    return (
        <Controller
            rules={{ validate: validateTags }}
            control={props.reactHookControl}
            name={props.name}
            render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => {
                const options = createOptionsFromString(value)
                const isSelectedError = error && findInvalidLengthIndex(options)

                return (
                    <>
                        <CreatableSelect
                            styles={{
                                control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: error?.message ? "#FECDCA" : "#D0D5DD",
                                    boxShadow: value && "0px 1px 2px 0px #1018280D",
                                    borderRadius: "12px",
                                    padding: "9px 14px 9px 11px",
                                    "&:hover": {
                                        borderColor: "#67E8F9",
                                    },
                                }),
                                input: (baseStyles) => ({
                                    ...baseStyles,
                                    height: 37,
                                    minHeight: 37,
                                    position: "relative",
                                    top: 0,
                                }),
                                multiValue: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderRadius: "6px",
                                    backgroundColor: isSelectedError?.includes(state.index)
                                        ? "#FEE4E2"
                                        : baseStyles.backgroundColor,
                                    color: isSelectedError?.includes(state.index) ? "#B42318" : baseStyles.color,
                                }),
                                multiValueLabel: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderRadius: "6px",
                                    color: isSelectedError?.includes(state.index) ? "#B42318" : baseStyles.color,
                                }),
                                multiValueRemove: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderRadius: "6px",
                                    color: isSelectedError?.includes(state.index) ? "#B42318" : baseStyles.color,
                                    "&:hover": {
                                        backgroundColor: isSelectedError?.includes(state.index)
                                            ? "#FEE4E2"
                                            : baseStyles.backgroundColor,
                                        color: isSelectedError?.includes(state.index) ? "#B42318" : baseStyles.color,
                                    },
                                }),
                            }}
                            ref={ref}
                            name={name}
                            value={options}
                            components={{
                                DropdownIndicator: null,
                                ClearIndicator: () => {
                                    return (
                                        <>
                                            {error?.message ? <AlertIcon /> : ""}
                                            <div className="pr-2">
                                                {props.postIcon && !error?.message && props.postIcon}
                                            </div>
                                        </>
                                    )
                                },
                            }}
                            inputValue={inputValue}
                            onBlur={(event: FocusEvent<HTMLInputElement>) => {
                                event.preventDefault()
                                if (inputValue.trim()) {
                                    if (value) {
                                        onChange(value + "," + inputValue)
                                    } else {
                                        onChange(inputValue)
                                    }
                                    setInputValue("")
                                }
                            }}
                            isClearable
                            menuIsOpen={false}
                            onInputChange={(newValue: string) => setInputValue(newValue)}
                            onChange={(newValue) => {
                                let value = newValue?.map((value) => value.value).join(",")
                                onChange(value)
                            }}
                            onKeyDown={(event) => {
                                if (!inputValue) return
                                switch (event.key) {
                                    case "Enter":
                                    case ",":
                                    case "Tab":
                                        if (value) {
                                            onChange(value + "," + inputValue)
                                        } else {
                                            onChange(inputValue)
                                        }

                                        setInputValue("")
                                        event.preventDefault()
                                }
                            }}
                            placeholder={props.placeholder}
                            className={props.className}
                            isMulti={true}
                            isDisabled={props.disabled}
                        />

                        {error && (
                            <span
                                className="text-[#F04438] pl-[14px] pt-2 text-[12px] font-[300]"
                                style={{ display: "block" }}
                            >
                                {error.message}
                            </span>
                        )}
                    </>
                )
            }}
        />
    )
}

export default TagInputElement
