import { useSelector } from "react-redux"
import { RunTimeStateSelector } from "../../../selectors/RunTimeStateSelector"
import NewDataNotificationAlert from "./newDataNotificationAlert.component"
import RestoreNotificationAlert from "./restoreNotifcationAlert.component"

const NotificationAlertOutlet = () => {
    const { notificationAlerts } = useSelector(RunTimeStateSelector)
    const getModalBody = () => {
        switch (notificationAlerts?.notificationType) {
            case "restore":
                return <RestoreNotificationAlert {...notificationAlerts} />
            case "new_Data":
                return <NewDataNotificationAlert {...notificationAlerts} />
            default:
                return <></>
        }
    }
    return <>{notificationAlerts?.open ? <>{getModalBody()}</> : null}</>
}

export default NotificationAlertOutlet
