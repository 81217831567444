import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useLocation } from "react-router-dom"
import { ProductsActionCreator } from "../../../actions/products.action"
import { RuntimeActionCreators } from "../../../actions/runTime.action"
import { AlertDotIconSvg } from "../../../assets/svgs/alertDotIcon.svg"
import { ChatRefreshIcon } from "../../../assets/svgs/ChatRefreshIcon.svg"
import { ROUTES_CONFIG } from "../../../config/routes.config"
import { PrimaryText } from "../../elements/primaryText.element"

const NewDataNotificationAlert = (props: INotificationAlertProps) => {
    const trackpackID = localStorage.getItem("selectedTrackpackID")
    const productID = localStorage.getItem("product_id")

    const location = useLocation()

    const dispatch = useDispatch()

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(RuntimeActionCreators.closeNotificaitonAlert())
        }, props.duration)
        if (ROUTES_CONFIG.dashboard !== location.pathname) {
            dispatch(RuntimeActionCreators.closeNotificaitonAlert())
        }

        return () => clearTimeout(timer)
    }, [dispatch, props.duration, location])

    const handleNotificationClose = () => {
        dispatch(RuntimeActionCreators.closeNotificaitonAlert())
    }

    const handleActionClick = () => {
        dispatch(
            ProductsActionCreator.getDropdownList({}, {}, (data: any) => {
                dispatch(
                    ProductsActionCreator.getProductsByGroup(
                        trackpackID!,
                        "",
                        {},
                        productID ? { product_id: productID } : {},
                        true
                    )
                )
            })
        )

        handleNotificationClose()
    }

    return (
        <div
            className={`py-[4px] pl-[4px] pr-[8px] m-auto max-w-[496px] rounded-[12px] border z-10 absolute left-[50%] top-[38px] translate-x-[-50%] border-[#FAA7E0] bg-[#FDF2FA] `}
            style={{ boxShadow: "0px 4px 8px 4px #1018281A" }}
        >
            <div className="flex items-center gap-[12px]">
                <div className="flex items-center gap-[4px] rounded-[8px] border border-[#FAA7E0] bg-white py-[2px] px-[8px] pl-[4px]">
                    <AlertDotIconSvg fillColor="#EE46BC" color="#FCCEEE" />
                    <PrimaryText weight="light" size="small" className="text-[#C11574] pt-[2px] whitespace-nowrap">
                        New Data Available
                    </PrimaryText>
                </div>
                <div className="flex items-center gap-[4px] py-[2px]">
                    <PrimaryText weight="light" size="small" className={`text-[#C11574] whitespace-nowrap `}>
                        Click to&nbsp;
                        <PrimaryText
                            weight="medium"
                            size="small"
                            className={`text-[#C11574] whitespace-nowrap cursor-pointer inline`}
                            onClick={handleActionClick}
                        >
                            Refresh Page
                        </PrimaryText>
                    </PrimaryText>

                    <ChatRefreshIcon color="#EE46BC" />
                </div>
            </div>
        </div>
    )
}

export default NewDataNotificationAlert
