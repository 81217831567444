import dayjs from "dayjs"
import { useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { NotificationsActionsCreator } from "../../actions/notifications.action"
import { ReactComponent as NoImage } from "../../assets/svgs/no-image.svg"
import { NotificationChevronDownIcon } from "../../assets/svgs/NotificationChevronDownIcon"
import { NotificationHalfRedIcon } from "../../assets/svgs/notificationHalfRedDot.svg"
import { NotificationRedIcon } from "../../assets/svgs/notificationRedDot.svg"
import { NotificationWhiteIcon } from "../../assets/svgs/notificationWhiteIcon.svg"
import { img_url } from "../../config/dashboard.config"
import { ROUTES_CONFIG } from "../../config/routes.config"
import { renderNotificationIcon } from "../../helpers/util.helper"
import { PrimaryText } from "../elements/primaryText.element"
import NotificationAsinCard from "./notificationAsinCard.component"

export const NotificationCard = (props: INotificationCard) => {
    const [menuIconClicked, setMenuIconClicked] = useState(false)

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const { pathname } = location

    const { setup_alerts_and_issues = [], listing_changes = [] } = props?.product?.product_notifications || {}

    const notificationSetupAlerts = useMemo(() => {
        if (setup_alerts_and_issues.length > 0) {
            const allIssuesNull = setup_alerts_and_issues.every((notification) => notification?.current_issue === null)
            const { length: notificationLength, [0]: firstNotification } = setup_alerts_and_issues
            const { main_image_filename: mainImageFilename, created_at: notificationDate } = firstNotification || {}

            return { allIssuesNull, notificationLength, mainImageFilename, notificationDate }
        }
        return null
    }, [setup_alerts_and_issues])

    const notificationListingChanges = useMemo(() => {
        if (listing_changes.length > 0) {
            const { length: notificationLength, [0]: firstNotification } = listing_changes
            const { main_image_filename: mainImageFilename, created_at: notificationDate } = firstNotification || {}

            return { notificationLength, mainImageFilename, notificationDate }
        }
        return null
    }, [listing_changes])

    const iconType = renderNotificationIcon(props?.product?.product_notifications)

    const renderIcon = () => {
        switch (iconType) {
            case "red":
                return <NotificationRedIcon />
            case "gray":
                return <NotificationWhiteIcon />
            case "half":
                return <NotificationHalfRedIcon />
            default:
                return null
        }
    }

    const handleTrackpackRedirect = (id: number, asins: any[]) => {
        if (
            props?.product?.metadata?.status === "ACTIVE" ||
            props?.product?.metadata?.status === "AWAITING_INITIAL_DATA"
        ) {
            const asinsArray = asins.map((asin: any) => asin.requested_asin)
            localStorage.setItem("notifiAsin", JSON.stringify(asinsArray))
            localStorage.setItem("selectedTrackpackID", id.toString())
            // @ts-ignore
            if (typeof window.updateSelectedTrackpackID === "function") {
                // @ts-ignore
                window.updateSelectedTrackpackID(id.toString())
            }
            if (pathname !== ROUTES_CONFIG.dashboard) {
                navigate(ROUTES_CONFIG.dashboard)
            }
            let payload = {
                notification_ids: asins.map((asin: any) => asin.notification_id),
            }
            dispatch(
                NotificationsActionsCreator.markNotificationAsRead(payload, () => {
                    dispatch(NotificationsActionsCreator.getAllNotifications())
                })
            )
        }
    }

    return (
        <>
            {setup_alerts_and_issues.length > 0 && (
                <div className="relative flex gap-[12px] w-full">
                    {notificationSetupAlerts?.mainImageFilename ? (
                        <img
                            src={`${img_url}${notificationSetupAlerts.mainImageFilename}`}
                            alt="Trackpack"
                            className="h-[44px] max-w-[44px] min-w-[44px] border border-gray-300 rounded-[6px] object-contain bg-white"
                        />
                    ) : (
                        <div className="h-[44px] min-w-[44px] border border-gray-300 rounded-[4px] flex items-center justify-center bg-gray-800">
                            <NoImage />
                        </div>
                    )}
                    <div className="w-full">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center gap-[8px] mb-[2px]">
                                <PrimaryText size="xs-medium" weight="medium" className="leading-[16px] text-gray-700">
                                    {notificationSetupAlerts?.notificationLength}{" "}
                                    {notificationSetupAlerts?.notificationLength &&
                                    notificationSetupAlerts?.notificationLength > 1
                                        ? "ASINs"
                                        : "ASIN"}{" "}
                                    {notificationSetupAlerts?.allIssuesNull ? "added" : "error"}
                                </PrimaryText>
                                <PrimaryText weight="book" className="text-[#667085] text-[9px] leading-[10px]">
                                    {dayjs(notificationSetupAlerts?.notificationDate)
                                        .format("MMM-DD-YYYY")
                                        .toUpperCase()}
                                </PrimaryText>
                            </div>
                            <div className="mr-[12px]">{renderIcon()}</div>
                        </div>

                        <div className="flex items-start w-full justify-between">
                            <PrimaryText size="small" weight="light" className="text-gray-700 max-w-[200px] w-full">
                                {notificationSetupAlerts?.allIssuesNull ? (
                                    <PrimaryText
                                        size="small"
                                        weight="light"
                                        className="text-gray-700 max-w-[200px] w-full"
                                    >
                                        New ASINs added to your Trackpack
                                        <PrimaryText weight="medium" size="small" className="text-gray-700 inline">
                                            &nbsp;{props?.product?.metadata?.name}&nbsp;
                                        </PrimaryText>
                                    </PrimaryText>
                                ) : (
                                    <PrimaryText
                                        size="small"
                                        weight="light"
                                        className="text-gray-700 max-w-[200px] w-full "
                                    >
                                        Your Trackpack
                                        <PrimaryText weight="medium" size="small" className="text-gray-700 inline">
                                            &nbsp;{props?.product?.metadata?.name}&nbsp;
                                        </PrimaryText>
                                        has ASINs with errors.
                                    </PrimaryText>
                                )}
                                <PrimaryText
                                    weight="medium"
                                    size="small"
                                    className={`text-cyan-500 ${
                                        props?.product?.metadata?.status !== "ACTIVE"
                                            ? "cursor-not-allowed"
                                            : "cursor-pointer"
                                    } `}
                                    onClick={() =>
                                        handleTrackpackRedirect(props?.product?.metadata?.id!, [
                                            ...setup_alerts_and_issues,
                                            ...listing_changes,
                                        ])
                                    }
                                >
                                    {notificationSetupAlerts?.allIssuesNull ? "View now" : "Review now"}
                                </PrimaryText>
                            </PrimaryText>

                            <div className="w-[16px] h-[16px] cursor-pointer flex items-center mr-[9px]">
                                <NotificationChevronDownIcon
                                    className={`cursor-pointer w-[16px] h-[16px] ${
                                        menuIconClicked ? "rotate-0" : "rotate-[-90deg]"
                                    }`}
                                    onClick={() => setMenuIconClicked(!menuIconClicked)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {!setup_alerts_and_issues.length && listing_changes.length > 0 && (
                <div className="relative flex gap-[12px] w-full">
                    {notificationListingChanges?.mainImageFilename ? (
                        <img
                            src={`${img_url}${notificationListingChanges.mainImageFilename}`}
                            alt="Trackpack"
                            className="h-[44px] max-w-[44px]  min-w-[44px] border border-gray-300 rounded-[6px] object-contain bg-white"
                        />
                    ) : (
                        <div className="h-[44px] min-w-[44px] border border-gray-300 rounded-[4px] flex items-center justify-center bg-gray-800">
                            <NoImage />
                        </div>
                    )}

                    <div className="w-full">
                        <div className="flex items-center justify-between mb-[2px]">
                            <div className="flex items-center gap-x-[4px]">
                                <PrimaryText size="xs-medium" weight="medium" className="leading-[16px] text-gray-700">
                                    Listing changes
                                </PrimaryText>
                                <PrimaryText weight="book" className="text-[#667085] text-[9px] leading-[10px]">
                                    {dayjs(notificationListingChanges?.notificationDate)
                                        .format("MMM-DD-YYYY")
                                        .toUpperCase()}
                                </PrimaryText>
                            </div>
                            <div className="mr-[12px]">{renderIcon()}</div>
                        </div>
                        <div className="flex items-center justify-between">
                            <PrimaryText size="small" weight="light" className="text-gray-700 max-w-[232px] w-full">
                                <PrimaryText weight="medium" size="small" className="text-gray-700 inline">
                                    {props?.product?.metadata?.name}
                                </PrimaryText>{" "}
                                <PrimaryText
                                    weight="medium"
                                    size="small"
                                    className={`text-cyan-500  inline ${
                                        props?.product?.metadata?.status !== "ACTIVE"
                                            ? "cursor-not-allowed"
                                            : "cursor-pointer"
                                    } `}
                                    onClick={() =>
                                        handleTrackpackRedirect(props?.product?.metadata?.id!, [
                                            ...setup_alerts_and_issues,
                                            ...listing_changes,
                                        ])
                                    }
                                >
                                    View now
                                </PrimaryText>
                            </PrimaryText>

                            <div className="w-[16px] h-[16px] cursor-pointer flex items-center mr-[9px]">
                                <NotificationChevronDownIcon
                                    className={`cursor-pointer w-[16px] h-[16px] ${
                                        menuIconClicked ? "rotate-0" : "rotate-[-90deg]"
                                    }`}
                                    onClick={() => setMenuIconClicked(!menuIconClicked)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {menuIconClicked &&
                setup_alerts_and_issues.map((item, index) => (
                    <NotificationAsinCard
                        key={index}
                        notification_id={item.notification_id}
                        {...item}
                        trackpackId={props?.product?.metadata?.id}
                        onClose={props.onClose}
                        amazon_tld={props?.product?.metadata?.amazon_tld}
                    />
                ))}

            {menuIconClicked &&
                listing_changes.map((item: JSX.IntrinsicAttributes & ProductNotification, index: number) => (
                    <NotificationAsinCard
                        key={index}
                        notification_id={item.notification_id}
                        {...item}
                        trackpackId={props?.product?.metadata?.id}
                        onClose={props.onClose}
                        islistingChange={true}
                        amazon_tld={props?.product?.metadata?.amazon_tld}
                    />
                ))}
        </>
    )
}
