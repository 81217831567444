export const ToolsIcon = () => {
    return (
        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.5 3L5.75 5.25M3.5 3H2L1.5 1.5L2 1L3.5 1.5V3ZM10.1295 1.37051L8.81569 2.68431C8.61768 2.88232 8.51867 2.98133 8.48158 3.09549C8.44895 3.19591 8.44895 3.30409 8.48158 3.40451C8.51867 3.51867 8.61768 3.61768 8.81568 3.81568L8.93431 3.93431C9.13232 4.13232 9.23133 4.23133 9.34549 4.26842C9.44591 4.30105 9.55409 4.30105 9.65451 4.26842C9.76867 4.23133 9.86768 4.13232 10.0657 3.93431L11.2946 2.70536C11.427 3.02744 11.5 3.3802 11.5 3.75C11.5 5.26878 10.2688 6.5 8.75 6.5C8.5669 6.5 8.38797 6.4821 8.21488 6.44797C7.97181 6.40003 7.85027 6.37606 7.77659 6.3834C7.69826 6.39121 7.65966 6.40295 7.59026 6.44009C7.52497 6.47503 7.45949 6.54051 7.32851 6.67149L3.75 10.25C3.33579 10.6642 2.66421 10.6642 2.25 10.25C1.83579 9.83578 1.83579 9.16421 2.25 8.74999L5.82851 5.17148C5.95949 5.04051 6.02497 4.97503 6.05991 4.90974C6.09705 4.84034 6.10879 4.80173 6.1166 4.72341C6.12394 4.64973 6.09997 4.52819 6.05203 4.28512C6.0179 4.11203 6 3.9331 6 3.75C6 2.23122 7.23122 1 8.75 1C9.25275 1 9.72399 1.13491 10.1295 1.37051ZM6.50003 7.49997L9.25 10.2499C9.66421 10.6642 10.3358 10.6642 10.75 10.2499C11.1642 9.83573 11.1642 9.16416 10.75 8.74994L8.48766 6.48765C8.32751 6.47249 8.17135 6.4436 8.02039 6.40217C7.82586 6.34877 7.61247 6.38753 7.46983 6.53017L6.50003 7.49997Z"
                stroke="#B54708"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
