import { Transition } from "@headlessui/react"
import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"

import { AuthStateSelector } from "../../selectors/authState.selector"
import { PrimaryText } from "../elements/primaryText.element"
import { NotificationAlert } from "./notification.component"

import { useLocation, useNavigate } from "react-router-dom"
import { AdminLogo } from "../../assets/svgs/adminLogo"
import { AlertArrowIconSvg } from "../../assets/svgs/alertArrowIcon.svg"
import { AlertDotIconSvg } from "../../assets/svgs/alertDotIcon.svg"
import { ReactComponent as BellIcon } from "../../assets/svgs/bellIcon.svg"
import { ReactComponent as Logo } from "../../assets/svgs/dashboard-logo.svg"
import { ROUTES_CONFIG } from "../../config/routes.config"
import { AccountStateSelector } from "../../selectors/accountState.selector"
import { NotificationStateSelector } from "../../selectors/notificationStateSelector"
export const DashboardHeader = () => {
    const { getAccessLevel } = useSelector(AuthStateSelector)
    const { userActionData } = useSelector(AccountStateSelector)
    const navigate = useNavigate()
    const pathname = window.location.pathname
    const location = useLocation()
    const notificationRef = useRef(null)
    const [isNotificationOpen, setNotificationOpen] = useState(false)
    const { notificationsData } = useSelector(NotificationStateSelector)

    const unreadNotificationCount =
        notificationsData?.notifications?.reduce((total, notification) => {
            const countUnread = (notifArray: ProductNotification[]) =>
                notifArray?.filter((notif) => notif?.read_at === null)?.length || 0

            const setupAlertsAndIssuesUnreadCount = countUnread(
                notification?.product_notifications?.setup_alerts_and_issues
            )
            const listingChangeAlertsUnreadCount = countUnread(notification?.product_notifications?.listing_changes)

            return total + setupAlertsAndIssuesUnreadCount + listingChangeAlertsUnreadCount
        }, 0) || 0

    const toggleNotification = () => {
        setNotificationOpen((prevState) => !prevState)
    }
    const displayName = getAccessLevel?.first_name
        ? getAccessLevel?.first_name.charAt(0)?.toUpperCase() + getAccessLevel?.first_name.slice(1)
        : getAccessLevel && getAccessLevel?.username?.charAt(0)?.toUpperCase() + getAccessLevel?.username?.slice(1)

    const handleArrowClick = () => {
        if (pathname !== "/dashboard/user-accounts") {
            navigate(ROUTES_CONFIG.userAccounts)
        }
    }

    useEffect(() => {
        setNotificationOpen(false)
    }, [location])

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (
                notificationRef.current &&
                // @ts-ignore
                !notificationRef.current.contains(event.target) &&
                !event.target.closest("#notification-icon")
            ) {
                setNotificationOpen(false)
            }
        }

        document.addEventListener("mousedown", handleClickOutside)

        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [isNotificationOpen])

    function updateLastNotificationId() {
        let maxNotificationId = 0

        notificationsData?.notifications?.forEach((notification) => {
            const listingChangesIds =
                notification.product_notifications?.listing_changes?.map(
                    (item: ProductNotification) => item.notification_id
                ) || []

            const setupAlertsIds =
                notification.product_notifications?.setup_alerts_and_issues?.map((item) => item.notification_id) || []

            const allNotificationIds = [...listingChangesIds, ...setupAlertsIds]

            const currentMaxId = Math.max(...allNotificationIds, 0)

            if (currentMaxId > maxNotificationId) {
                maxNotificationId = currentMaxId
            }
        })

        if (maxNotificationId) {
            localStorage.setItem("lastNotificationId", maxNotificationId.toString())
        }
    }
    updateLastNotificationId()

    return (
        <>
            <div className="flex pb-[48px] justify-between">
                <div className="flex gap-[36px] ">
                    {getAccessLevel && getAccessLevel?.is_staff ? <AdminLogo /> : <Logo />}
                    <div className="flex flex-col">
                        <PrimaryText className="text-[1.875rem]  text-slate-700 text-3xl font-light flex items-center gap-x-[3px]">
                            Hello <span className="font-medium"> {getAccessLevel && displayName} </span>
                            <span className="text-2xl leading-[38px] block mb-[3px]">
                                {" "}
                                {getAccessLevel && getAccessLevel?.is_staff ? "👍" : "👋"}{" "}
                            </span>
                        </PrimaryText>
                        <PrimaryText color="text-slate-700" weight="light" size="small" className="pl-[2px]">
                            {getAccessLevel && getAccessLevel?.is_staff
                                ? "Here’s where you take control and view my User accounts"
                                : "Here’s how your Trackpacks have been performing"}
                        </PrimaryText>
                    </div>
                </div>
                {/* selected asins notfications */}
                {/* {pathname === "/dashboard/trackpack-central" && (
                    <div
                        className="py-[4px] pl-[4px] pr-[8px] m-auto max-w-[496px] rounded-[12px] border border-[#75E0A7] bg-[#ECFDF3] z-10 absolute left-[50%] top-[38px] translate-x-[-50%]"
                        style={{ boxShadow: "0px 4px 8px 4px #1018281A" }}
                    >
                        <div className="flex items-center gap-[12px]">
                            <div className="flex items-center gap-[4px] rounded-[8px] border border-[#75E0A7] bg-white py-[2px] px-[8px] pl-[4px]">
                                <AlertDotIconSvg fillColor="#17B26A" color="#ABEFC6" />

                                <PrimaryText
                                    weight="light"
                                    size="small"
                                    className="text-[#067647] pt-[2px] whitespace-nowrap"
                                >
                                    1 ASIN in <span className="font-medium">Men’s Suit Jackets</span> has been moved to
                                    Tracking
                                </PrimaryText>
                            </div>
                            <div className="flex items-center gap-[4px] py-[2px]">
                                <PrimaryText weight="light" size="small" className="text-[#067647] whitespace-nowrap">
                                    View now
                                </PrimaryText>
                                <TrackpackArrowIcon />
                            </div>
                        </div>
                    </div>
                )} */}

                {userActionData && pathname !== ROUTES_CONFIG.userAccounts && (
                    <div
                        className="flex items-center pl-[4px] pr-[8px] h-[32px] m-auto w-auto rounded-[12px] border border-[#BDB4FE] bg-[#F4F3FF] z-10 absolute left-[50%] top-[38px] translate-x-[-50%]"
                        style={{ boxShadow: "0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A" }}
                    >
                        <div className="flex items-center gap-[12px]">
                            <div className="flex items-center gap-[4px] rounded-[8px] border border-[#BDB4FE] bg-white py-[2px] px-[8px] pl-[4px] h-[24px]">
                                <AlertDotIconSvg />

                                <PrimaryText
                                    weight="light"
                                    size="small"
                                    className="text-[#5925DC] pt-[2px] whitespace-nowrap"
                                >
                                    Admin Control
                                </PrimaryText>
                            </div>
                            <div className="flex items-center gap-[4px] mt-[1px]">
                                <PrimaryText
                                    weight="light"
                                    size="small"
                                    className="flex items-center gap-x-[2px] text-[#5925DC] whitespace-nowrap"
                                >
                                    You are viewing as
                                    <PrimaryText className="text-[14px]" weight="medium">
                                        {userActionData.name}
                                    </PrimaryText>
                                </PrimaryText>
                                <div onClick={handleArrowClick} className="cursor-pointer">
                                    <AlertArrowIconSvg />
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className=" h-8 relative">
                    {/* <div
                        className=" left-0 top-[17.78px] absolute"
                        data-tooltip-id="dashboard-tooltip"
                        data-tooltip-place="bottom-end"
                        data-tooltip-offset={-5}
                        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                            <CommonTooltip
                                className="w-[109px] h-3.5  flex-col justify-start items-start inline-flex"
                                label="Stay up-to-date with the Alerts panel"
                            />
                        )}
                    >
                        <Info className="cursor-pointer" />
                    </div> */}

                    <div
                        id="notification-icon"
                        className="justify-start items-center gap-3 inline-flex cursor-pointer"
                        onClick={toggleNotification}
                    >
                        <div
                            className="flex py-[6px] pr-[6px] pl-[8px] bg-white rounded-[10px] justify-center items-center gap-2 h-[32px]"
                            style={{
                                border: "1px solid",
                                borderImageSource: "linear-gradient(180deg, #F9FAFB 0%, #E7EBEF 100%)",
                                boxShadow: "0px 1px 2px 0px #1018280D",
                            }}
                        >
                            <div className="w-5 h-5 relative cursor-pointer">
                                <BellIcon />
                            </div>
                            {unreadNotificationCount !== 0 && (
                                <div className="px-[4px] py-[2px] bg-[#F04438] min-w-[20px] h-[20px]  rounded-[4px] justify-center items-center flex">
                                    <PrimaryText
                                        weight="book"
                                        size="xs"
                                        className=" text-white leading-[19px]  flex items-center justify-center align-middle"
                                    >
                                        {unreadNotificationCount}
                                    </PrimaryText>
                                </div>
                            )}
                        </div>
                    </div>
                    <Transition
                        show={isNotificationOpen}
                        // enter="transition-transform duration-75"
                        // enterFrom="transform opacity-0 translate-x-full"
                        // enterTo="transform opacity-100 translate-x-0"
                        // leave="transition-transform duration-300"
                        // leaveFrom="transform opacity-100 translate-x-0"
                        // leaveTo="transform opacity-0 translate-x-full"
                    >
                        <div id="notification-alert-wrapper" ref={notificationRef}>
                            <NotificationAlert onClose={toggleNotification} />
                        </div>
                    </Transition>
                </div>
            </div>
        </>
    )
}
