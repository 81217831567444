import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ProductsActionCreator } from "../../../../actions/products.action"
import { RuntimeActionCreators } from "../../../../actions/runTime.action"
import { NewAsinIcon } from "../../../../assets/svgs/newAsinIcon.svg"
import { NotificationIcon } from "../../../../assets/svgs/notificationIcon.svg"
import { TrackPackError } from "../../../../assets/svgs/trackPackError.svg"
import { TrackPackNameIcon } from "../../../../assets/svgs/trackPackName.svg"
import { RunTimeStateSelector } from "../../../../selectors/RunTimeStateSelector"
import AddTrackPackStepFourView from "./addTrackPackStepFour.component"
import AddTrackpackStepInput from "./addTrackpackStepInput.component"
import AddTrackPackStepOneView from "./addTrackPackStepOne.component"
import AddTrackPackStepThreeView from "./addTrackPackStepThree.component"
import AddTrackPackStepTwoView from "./addTrackPackStepTwo.component"

const AddTrackPackOutlet = () => {
    const { newAsinTrackPack } = useSelector(RunTimeStateSelector)
    const dispatch = useDispatch()
    const totalSteps = ["one", "two", "three", "four"]
    const [hasError, setHasError] = useState(false)

    const handleCloseDialog = () => {
        dispatch(RuntimeActionCreators.closeNewTrackPackAsinModal())
        dispatch(
            ProductsActionCreator.selectedTrackpackPayload({
                name: "",
                amazon_tld: "",
                competitor_asins: [],
                own_asins: [],
                total_asins: [],
                selected_Asins: {},
            })
        )
        setHasError(false)
    }

    const handleErrorModal = () => {
        setHasError(true)
        dispatch(
            RuntimeActionCreators.openNewTrackPackAsinModal({
                open: true,
                stepNumber: "five",
            })
        )
    }

    const getModalBody = () => {
        if (hasError && newAsinTrackPack?.stepNumber === "five") {
            return (
                <AddTrackPackStepFourView
                    icon={<TrackPackError />}
                    totalSteps={totalSteps}
                    handleCloseDialog={handleCloseDialog}
                    handlePrev={() =>
                        dispatch(
                            RuntimeActionCreators.openNewTrackPackAsinModal({
                                open: true,
                                stepNumber: "four",
                            })
                        )
                    }
                    currentStep={5}
                    asinHeaderText="Error adding new Trackpack"
                    asinHeaderDescription="I couldn’t complete the setup of your new Trackpack at this time due to a server communication issue. Please try again later."
                    buttonText="Done"
                />
            )
        }
        switch (newAsinTrackPack?.stepNumber) {
            case "one":
                return (
                    <AddTrackpackStepInput
                        icon={<NotificationIcon />}
                        totalSteps={totalSteps}
                        handleCloseDialog={handleCloseDialog}
                        asinHeaderText="New Trackpack"
                        asinHeaderDescription="Please tell me which Amazon market this Trackpack is for."
                        buttonText="Next"
                        currentStep={1}
                        handleNext={() =>
                            dispatch(
                                RuntimeActionCreators.openNewTrackPackAsinModal({
                                    open: true,
                                    stepNumber: "two",
                                })
                            )
                        }
                    />
                )
            case "two":
                return (
                    <AddTrackPackStepOneView
                        icon={<NotificationIcon />}
                        totalSteps={totalSteps}
                        handleCloseDialog={handleCloseDialog}
                        asinHeaderText="New Trackpack"
                        asinHeaderDescription="Please give me the ASINs you want me to track."
                        buttonText="Next"
                        currentStep={2}
                        isPrevButton={true}
                        handleNext={() =>
                            dispatch(
                                RuntimeActionCreators.openNewTrackPackAsinModal({
                                    open: true,
                                    stepNumber: "three",
                                })
                            )
                        }
                        handlePrev={() =>
                            dispatch(
                                RuntimeActionCreators.openNewTrackPackAsinModal({
                                    open: true,
                                    stepNumber: "one",
                                })
                            )
                        }
                    />
                )
            case "three":
                return (
                    <AddTrackPackStepTwoView
                        icon={<NewAsinIcon />}
                        totalSteps={totalSteps}
                        handleCloseDialog={handleCloseDialog}
                        asinHeaderText="Your ASINs"
                        asinHeaderDescription="Tick any ASINs that belong to you."
                        buttonText="Next"
                        currentStep={3}
                        isAddtionalSteps={false}
                        handleNext={() =>
                            dispatch(
                                RuntimeActionCreators.openNewTrackPackAsinModal({
                                    open: true,
                                    stepNumber: "four",
                                })
                            )
                        }
                        handlePrev={() =>
                            dispatch(
                                RuntimeActionCreators.openNewTrackPackAsinModal({
                                    open: true,
                                    stepNumber: "two",
                                })
                            )
                        }
                    />
                )
            case "four":
                return (
                    <AddTrackPackStepThreeView
                        icon={<TrackPackNameIcon />}
                        totalSteps={totalSteps}
                        handleCloseDialog={handleCloseDialog}
                        handlePrev={() =>
                            dispatch(
                                RuntimeActionCreators.openNewTrackPackAsinModal({
                                    open: true,
                                    stepNumber: "three",
                                })
                            )
                        }
                        currentStep={4}
                        asinHeaderText="Trackpack Name"
                        asinHeaderDescription="Let’s give your new Trackpack a name."
                        buttonText="Done"
                        isError={handleErrorModal}
                        isAddtionalSteps={false}
                    />
                )

            default:
                return <></>
        }
    }
    return <>{newAsinTrackPack?.open ? <>{getModalBody()}</> : null}</>
}

export default AddTrackPackOutlet
