import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import { useProductHistory } from "../../../hooks/productHistory.hook"
import { InnerTooltipHeader } from "../../common/innerTooltipHeader.component"
import { PrimaryText } from "../../elements/primaryText.element"

import { useSelector } from "react-redux"
import { AsinLinkExternalSvg } from "../../../assets/svgs/AsinExternalLink.svg"
import { AsinChangeTooltip } from "../../../assets/svgs/tooltips/popups/asin-change.svg"
import { handleAmazonProductRedirect } from "../../../helpers/util.helper"
import { ProductStateSelector } from "../../../selectors/product.selector"
import ErrorTooltipAlert from "../tooltip-alerts/ErrorTooltipAlert.component"
import WarningTooltipAlert from "../tooltip-alerts/warningTooltipAlert.component"
export const AsinChange = (props: ITooltipData) => {
    const { t } = useTranslation("tooltip", {
        keyPrefix: "asinChange",
    })
    const { t: tCommon } = useTranslation("tooltip", {
        keyPrefix: "commonLabels",
    })

    const { active, history, setActive, total } = useProductHistory(props.data, "actual_asin")
    const { selectedProductResult, selectedDropdownList } = useSelector(ProductStateSelector)
    const selectedTrackpackID = localStorage.getItem("selectedTrackpackID")

    const selectedObject = selectedDropdownList?.trackpacks?.find(
        (item: { metadata: { id: number | string } }) => item.metadata.id == selectedTrackpackID
    )

    const isSuccessWarning = props?.data?.warnings?.includes("success")
    const selectedValue = selectedProductResult?.selectedResults?.value ?? "bsr_large"
    const isWarning = props?.data?.warnings?.includes(
        selectedValue as "bsr_large" | "bsr_small" | "ratings_count" | "rating" | "actual_asin"
    )
    const handleRedirect = (link: string) => {
        if (link) {
            handleAmazonProductRedirect(selectedObject?.metadata?.amazon_tld, link)
        }
    }
    return (
        <div className={"w-[216px] p-[12px] bg-gray-900 rounded-2xl"}>
            <InnerTooltipHeader
                title={t("Asin Change")}
                linkText={tCommon("Go to ASIN")}
                timestamp={tCommon("timestamp", {
                    timestamp: dayjs(history.present?.timestamp).format("MMM-DD-YYYY @ HH:mm [GMT]"),
                })}
                active={active}
                total={total}
                setActive={setActive}
                color={props.color?.active}
                id={props.id}
                icon={<AsinChangeTooltip stroke={props.color?.default} />}
            />

            <div className="mt-[24px]">
                {history.past && history.present ? (
                    <div className="w-full flex-col gap-[8px] flex">
                        <div
                            onClick={() => handleRedirect(history?.past?.value)}
                            className="cursor-pointer h-12 p-2 bg-slate-600 rounded-lg flex-col justify-start items-start flex"
                        >
                            <PrimaryText
                                size={"xs-small"}
                                style={{
                                    color: props.color?.active,
                                }}
                                weight={"light"}
                                className="text-[12px] leading-[10px] tracking-[0.96px]"
                            >
                                {tCommon("was")}
                            </PrimaryText>

                            <div className="w-full  justify-between items-center gap-2.5 inline-flex mt-[6px]">
                                <PrimaryText size={"xs"} weight={"light"} className="w-full text-white leading-[18px]">
                                    {history?.past?.value ?? "N/A"}
                                </PrimaryText>

                                <AsinLinkExternalSvg color={props.color?.active} className="w-[14px] h-[12px]" />
                            </div>
                        </div>

                        <div
                            onClick={() => handleRedirect(history?.present?.value)}
                            className="cursor-pointer h-12 p-2 bg-slate-600 rounded-lg flex-col justify-start items-start flex"
                        >
                            <PrimaryText
                                size={"xs-small"}
                                style={{
                                    color: props.color?.active,
                                }}
                                weight={"light"}
                                className="text-[12px] leading-[10px] tracking-[0.96px]"
                            >
                                {tCommon("now")}
                            </PrimaryText>

                            <div className="w-full  justify-start items-center gap-2.5 inline-flex mt-[6px]">
                                <PrimaryText size={"xs"} weight={"light"} className=" w-full text-white leading-[18px]">
                                    {history?.present?.value ?? "N/A"}
                                </PrimaryText>
                                <AsinLinkExternalSvg color={props.color?.active} className="w-[14px] h-[12px]" />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="w-full flex-col gap-2 inline-flex">
                        <div
                            onClick={() => handleRedirect(history?.present?.value)}
                            className="cursor-pointer p-2 bg-slate-600 rounded-lg flex-col justify-start items-start gap-1.5 flex"
                        >
                            <div className="w-[108px] cursor-pointer justify-start items-center gap-2.5 inline-flex">
                                <PrimaryText size={"xs"} weight={"light"} className=" w-full text-white leading-[18px]">
                                    {history?.present?.value ?? "N/A"}
                                </PrimaryText>
                                <AsinLinkExternalSvg color={props.color?.active} className="w-[14px] h-[12px]" />
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {isSuccessWarning && (
                <div className="w-full  pt-[8px]">
                    <ErrorTooltipAlert />
                </div>
            )}

            {isWarning && (
                <div className="w-full pt-[8px]">
                    <WarningTooltipAlert />
                </div>
            )}
        </div>
    )
}
