import ReactDOMServer from "react-dom/server"
import { useLocation, useNavigate } from "react-router-dom"

import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Tooltip } from "react-tooltip"
import { ProductsActionCreator } from "../../actions/products.action"
import { ContactIconSidebar } from "../../assets/svgs/contactIconside.svg"
import { GraphIconSidebar } from "../../assets/svgs/graphIconSidebar.svg"
import { GridIconSidebar } from "../../assets/svgs/gridIcon.svg"
import { NewLogoutIcon } from "../../assets/svgs/newLogoutIcon.svg"
import { SettingNewIcon } from "../../assets/svgs/settingSideIcon.svg"
import { AdminUserIcon } from "../../assets/svgs/tooltips/adminUserIcon.svg"
import { AccountUserIcon } from "../../assets/svgs/userAccountIcon.svg"
import { VideoIconSidebar } from "../../assets/svgs/videoIconsidebar.svg"
import { ROUTES_CONFIG } from "../../config/routes.config"
import { UtilHelper } from "../../helpers/util.helper"
import { unregisterServiceWorker } from "../../hooks/notificationServiceWorker.hook"
import { AuthStateSelector } from "../../selectors/authState.selector"
import { ProductStateSelector } from "../../selectors/product.selector"
import { PrimaryText } from "../elements/primaryText.element"
import QuickTourModal from "./modals/quickTourModals/quickTour.component"
import QuickTourTrackpackModal from "./modals/quickTourModals/quickTourTrackpack.component"
import CommonTooltip from "./tooltipItems/commonTooltip.component"

export const SideNavigation = () => {
    const { token, getAccessLevel } = useSelector(AuthStateSelector)
    const { selectedDropdownList } = useSelector(ProductStateSelector)

    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()

    const clickRef: any = useRef(null)
    const { pathname } = location
    const iconColor = getAccessLevel && getAccessLevel?.is_staff ? "#9B8AFB" : "#06AED4"
    const handleLogoutClick = () => {
        localStorage.clear()
        unregisterServiceWorker()
        window.location.reload()
    }

    const [open, setOpen] = useState(false)

    useEffect(() => {
        if (
            pathname === ROUTES_CONFIG.dashboard &&
            getAccessLevel &&
            getAccessLevel?.user_settings?.initial_qt_completed === false &&
            selectedDropdownList?.trackpacks?.length === 0
        ) {
            setTimeout(() => {
                if (clickRef?.current) {
                    clickRef.current.click()
                }
            }, 0)
        }
    }, [getAccessLevel?.user_settings?.initial_qt_completed, selectedDropdownList?.trackpacks?.length, pathname])

    useEffect(() => {
        if (
            pathname === ROUTES_CONFIG.trackpackCentral &&
            selectedDropdownList &&
            selectedDropdownList?.trackpacks?.length === 0
        ) {
            setTimeout(() => {
                if (clickRef?.current) {
                    clickRef.current.click()
                }
            }, 0)
        }
    }, [selectedDropdownList?.trackpacks?.length, pathname])

    return (
        <div className="w-[60px] h-full bg-gradient-to-tr from-slate-800 to-slate-700 rounded-2xl justify-start items-start inline-flex">
            <div className="w-[60px] h-full flex-col justify-between items-center inline-flex">
                <div className="p-3 flex-col justify-start items-start gap-3 flex">
                    <div
                        data-tooltip-id="dashboard-tooltip"
                        data-tooltip-place="bottom-end"
                        data-tooltip-offset={-18}
                        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                            <CommonTooltip
                                className="ml-[30px] w-auto  mb-[10px] h-3.5 shadow flex-col justify-start items-start inline-flex"
                                label="Trackpack Hub"
                            />
                        )}
                        onClick={() => navigate(ROUTES_CONFIG.trackpackCentral)}
                        className={`p-2 cursor-pointer
                        hover:bg-[#475467]
                        
                        ${
                            ROUTES_CONFIG.trackpackCentral === pathname && getAccessLevel && getAccessLevel?.is_staff
                                ? "bg-gradient-to-br from-[#BDB4FE] to-[#D9D6FE]"
                                : ROUTES_CONFIG.trackpackCentral === pathname && !getAccessLevel?.is_staff
                                ? "bg-gradient-to-br from-cyan-100 to-cyan-200"
                                : ""
                        } rounded-[10px] justify-start items-start gap-2 inline-flex`}
                    >
                        <div className="w-5 h-5 relative flex items-center justify-center ">
                            <GridIconSidebar
                                color={
                                    ROUTES_CONFIG.trackpackCentral === pathname &&
                                    getAccessLevel &&
                                    getAccessLevel?.is_staff
                                        ? "#5925DC"
                                        : ROUTES_CONFIG.trackpackCentral === pathname && !getAccessLevel?.is_staff
                                        ? "#06AED4"
                                        : getAccessLevel && getAccessLevel?.is_staff
                                        ? "#9B8AFB"
                                        : "#06AED4"
                                }
                            />
                        </div>
                    </div>

                    <div
                        data-tooltip-id="dashboard-tooltip"
                        data-tooltip-place="bottom-end"
                        data-tooltip-offset={-18}
                        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                            <CommonTooltip
                                className="ml-[30px] w-auto  mb-[10px] h-3.5 shadow flex-col justify-start items-start inline-flex"
                                label="Performance Tracker"
                            />
                        )}
                        onClick={() => {
                            navigate(ROUTES_CONFIG.dashboard)
                            dispatch(ProductsActionCreator.selectedOptions({ selectedOption: "Graph" }))
                        }}
                        className={`p-2 cursor-pointer hover:bg-[#475467] ${
                            ROUTES_CONFIG.dashboard === pathname && getAccessLevel && getAccessLevel?.is_staff
                                ? "bg-gradient-to-br from-[#BDB4FE] to-[#D9D6FE]"
                                : ROUTES_CONFIG.dashboard === pathname && !getAccessLevel?.is_staff
                                ? "bg-gradient-to-br from-cyan-100 to-cyan-200"
                                : ""
                        } rounded-[10px] justify-start items-start gap-2 inline-flex`}
                    >
                        <div className="w-5 h-5 relative flex items-center justify-center">
                            <GraphIconSidebar
                                color={
                                    ROUTES_CONFIG.dashboard === pathname && getAccessLevel && getAccessLevel?.is_staff
                                        ? "#5925DC"
                                        : ROUTES_CONFIG.dashboard === pathname && !getAccessLevel?.is_staff
                                        ? "#06AED4"
                                        : getAccessLevel && getAccessLevel?.is_staff
                                        ? "#9B8AFB"
                                        : "#06AED4"
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className="flex-col justify-center items-center gap-3 flex">
                    <div className="flex-col justify-center items-center gap-2 flex">
                        {getAccessLevel && getAccessLevel.is_staff && (
                            <div
                                data-tooltip-id="dashboard-tooltip"
                                data-tooltip-place="bottom-end"
                                data-tooltip-offset={-18}
                                data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                    <CommonTooltip
                                        className="ml-[30px] w-auto  mb-[10px] h-3.5 shadow flex-col justify-start items-start inline-flex"
                                        label="User Accounts"
                                    />
                                )}
                                onClick={() =>
                                    getAccessLevel && getAccessLevel.is_staff && navigate(ROUTES_CONFIG.userAccounts)
                                }
                                className={`p-2 ${
                                    getAccessLevel && getAccessLevel.is_staff ? "cursor-pointer" : "cursor-not-allowed"
                                } hover:bg-[#475467] ${
                                    ROUTES_CONFIG.userAccounts === pathname &&
                                    getAccessLevel &&
                                    getAccessLevel?.is_staff
                                        ? "bg-gradient-to-br from-[#BDB4FE] to-[#D9D6FE]"
                                        : ROUTES_CONFIG.userAccounts === pathname && !getAccessLevel?.is_staff
                                        ? "bg-gradient-to-br from-cyan-100 to-cyan-200"
                                        : ""
                                } rounded-[10px] justify-start items-start gap-2 inline-flex`}
                            >
                                <div className="w-5 h-5 relative flex items-center justify-center">
                                    <AccountUserIcon
                                        color={
                                            ROUTES_CONFIG.userAccounts === pathname &&
                                            getAccessLevel &&
                                            getAccessLevel?.is_staff
                                                ? "#5925DC"
                                                : ROUTES_CONFIG.userAccounts === pathname && !getAccessLevel?.is_staff
                                                ? "#06AED4"
                                                : getAccessLevel && getAccessLevel?.is_staff
                                                ? "#9B8AFB"
                                                : "#06AED4"
                                        }
                                    />
                                </div>
                            </div>
                        )}
                        {/* <div className="p-2 cursor-not-allowed rounded-[10px] justify-start items-start gap-2 inline-flex">
                            <div
                                className="w-5 h-5 relative"
                                data-tooltip-id="dashboard-tooltip"
                                data-tooltip-class-name={"!ml-[35px]"}
                                data-tooltip-place="bottom-end"
                                data-tooltip-offset={-6}
                                data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                    <CommonTooltip
                                        className=" w-auto  mb-[10px] h-3.5 shadow flex-col justify-start items-start inline-flex"
                                        label="Learn More"
                                    />
                                )}
                            >
                                <VideoIconSidebar color={iconColor} />
                            </div>
                        </div> */}
                        <div className="relative" data-tooltip-id={"tour-tooltip"}>
                            <div
                                className={`p-2 ${
                                    pathname === ROUTES_CONFIG.dashboard || pathname === ROUTES_CONFIG.trackpackCentral
                                        ? "cursor-pointer"
                                        : "cursor-not-allowed"
                                }  rounded-[10px] justify-start items-start gap-2 inline-flex`}
                            >
                                <div
                                    ref={clickRef}
                                    className="w-5 h-5 relative"
                                    data-tooltip-id="dashboard-tooltip"
                                    data-tooltip-class-name={"!ml-[35px]"}
                                    data-tooltip-place="bottom-end"
                                    data-tooltip-offset={-6}
                                    data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                        <>
                                            {!open && (
                                                <CommonTooltip
                                                    className=" w-auto  mb-[10px] h-3.5 shadow flex-col justify-start items-start inline-flex"
                                                    label="Quick Tour"
                                                />
                                            )}
                                        </>
                                    )}
                                    onClick={() => setOpen(!open)}
                                >
                                    <VideoIconSidebar color={iconColor} />
                                </div>
                                {pathname === ROUTES_CONFIG.dashboard && (
                                    <Tooltip
                                        id="tour-tooltip"
                                        style={{
                                            padding: "12px",
                                            backgroundColor: "#088AB2",
                                            borderRadius: "16px",
                                            zIndex: 1000,
                                            maxWidth: "248px",
                                            boxShadow: "0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814",
                                        }}
                                        place="right"
                                        delayShow={200}
                                        classNameArrow="custom-arrow light-blue"
                                        openOnClick
                                        clickable
                                        isOpen={open}
                                        render={() => {
                                            return (
                                                <div>
                                                    <QuickTourModal
                                                        onClose={() => {
                                                            setOpen(false)
                                                        }}
                                                    />
                                                </div>
                                            )
                                        }}
                                    />
                                )}
                                {pathname === ROUTES_CONFIG.trackpackCentral && (
                                    <Tooltip
                                        id="tour-tooltip"
                                        style={{
                                            padding: "12px",
                                            backgroundColor: "#088AB2",
                                            borderRadius: "16px",
                                            zIndex: 1000,
                                            maxWidth: "248px",
                                            boxShadow: "0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814",
                                        }}
                                        place="right"
                                        delayShow={200}
                                        classNameArrow="custom-arrow light-blue"
                                        openOnClick
                                        clickable
                                        isOpen={open}
                                        render={() => {
                                            return (
                                                <div>
                                                    <QuickTourTrackpackModal
                                                        onClose={() => {
                                                            setOpen(false)
                                                        }}
                                                    />
                                                </div>
                                            )
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                        <div
                            data-tooltip-id="dashboard-tooltip"
                            data-tooltip-place="bottom-end"
                            data-tooltip-offset={-16}
                            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                <CommonTooltip
                                    className="ml-[26px] w-auto mb-[10px] h-3.5 shadow flex-col justify-start items-start inline-flex"
                                    label="Help & Support"
                                />
                            )}
                            className="p-2 cursor-not-allowed rounded-[10px] justify-start items-start gap-2 inline-flex"
                        >
                            <div className="w-5 h-5 justify-center items-center flex">
                                <div className="w-5 h-5 relative flex-col justify-start items-start flex">
                                    <ContactIconSidebar color={iconColor} />
                                </div>
                            </div>
                        </div>

                        <div
                            data-tooltip-id="dashboard-tooltip"
                            data-tooltip-place="bottom-end"
                            data-tooltip-offset={-16}
                            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                <CommonTooltip
                                    className="ml-[26px] w-[100px]  mb-[10px] h-3.5 shadow flex-col justify-start items-start inline-flex"
                                    label="Account Settings"
                                />
                            )}
                            onClick={() => {
                                navigate(ROUTES_CONFIG.userSetting)
                            }}
                            className={`p-2 cursor-pointer hover:bg-[#475467] ${
                                pathname.startsWith(ROUTES_CONFIG.userSetting) &&
                                getAccessLevel &&
                                getAccessLevel?.is_staff
                                    ? "bg-gradient-to-br from-[#BDB4FE] to-[#D9D6FE]"
                                    : pathname.startsWith(ROUTES_CONFIG.userSetting) && !getAccessLevel?.is_staff
                                    ? "bg-gradient-to-r from-[#A5F0FC] to-[#67E3F9]"
                                    : ""
                            } rounded-[10px] justify-start items-start gap-2 inline-flex`}
                        >
                            <div className="w-5 h-5 justify-center items-center flex">
                                <div className="w-5 h-5 relative flex-col justify-start items-start flex">
                                    <SettingNewIcon
                                        color={
                                            ROUTES_CONFIG.userSetting === pathname &&
                                            getAccessLevel &&
                                            getAccessLevel?.is_staff
                                                ? "#5925DC"
                                                : ROUTES_CONFIG.userAccounts === pathname && !getAccessLevel?.is_staff
                                                ? "#06AED4"
                                                : getAccessLevel && getAccessLevel?.is_staff
                                                ? "#9B8AFB"
                                                : "#06AED4"
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div data-tooltip-id={"logout-tooltip"}>
                            <div className="mb-3 cursor-pointer">
                                <div
                                    className={`w-9 h-9 relative rounded-[10px] justify-center items-center flex ${
                                        getAccessLevel && getAccessLevel?.is_staff ? " bg-[#6938EF]" : "bg-[#475467]"
                                    } `}
                                >
                                    {getAccessLevel && getAccessLevel?.is_staff ? (
                                        <AdminUserIcon />
                                    ) : (
                                        <div className="w-full font-light text-base leading-6 text-[#EAECF0] h-full relative flex-col justify-center items-center flex border border-transparent ">
                                            {UtilHelper.getInitials(token?.username ?? "User")}
                                        </div>
                                    )}

                                    <Tooltip
                                        id="logout-tooltip"
                                        style={{
                                            padding: "6px 10px",
                                            backgroundColor: "#088AB2",
                                            borderRadius: "6px",
                                            zIndex: 1000,
                                            boxShadow: "0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A",
                                        }}
                                        place="right"
                                        delayShow={200}
                                        classNameArrow="custom-arrow light-blue"
                                        openOnClick
                                        clickable
                                        render={() => {
                                            return (
                                                <div>
                                                    <div
                                                        className="flex items-center gap-[6px] cursor-pointer"
                                                        onClick={handleLogoutClick}
                                                    >
                                                        <NewLogoutIcon />
                                                        <PrimaryText
                                                            weight="book"
                                                            size="small"
                                                            className="text-white leading-[20px]"
                                                            onClick={handleLogoutClick}
                                                        >
                                                            Logout
                                                        </PrimaryText>
                                                    </div>
                                                </div>
                                            )
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Tooltip
                id="dashboard-tooltip"
                style={{
                    backgroundColor: "transparent",
                    color: "#222",
                    zIndex: 9000,
                    padding: 0,
                    margin: 0,
                }}
                delayShow={200}
            />
        </div>
    )
}
