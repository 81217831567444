import { useDispatch, useSelector } from "react-redux"

import { RunTimeStateSelector } from "../../../../../selectors/RunTimeStateSelector"

import { useEffect } from "react"
import { RuntimeActionCreators } from "../../../../../actions/runTime.action"
import PTImage from "../../../../../assets/images/PT_QT_Step_1.png"
import PTStep10Bg from "../../../../../assets/images/PT_QT_Step_10.png"
import PTStep11Bg from "../../../../../assets/images/PT_QT_Step_11.png"
import PTStep12Bg from "../../../../../assets/images/PT_QT_Step_12.png"
import PTStep2Bg from "../../../../../assets/images/PT_QT_Step_2.png"
import PTStep3Bg from "../../../../../assets/images/PT_QT_Step_3.png"
import PTStep4Bg from "../../../../../assets/images/PT_QT_Step_4.png"
import PTStep5Bg from "../../../../../assets/images/PT_QT_Step_5.png"
import PTStep6Bg from "../../../../../assets/images/PT_QT_Step_6.png"
import PTStep7Bg from "../../../../../assets/images/PT_QT_Step_7.png"
import PTStep8Bg from "../../../../../assets/images/PT_QT_Step_8.png"
import PTStep9Bg from "../../../../../assets/images/PT_QT_Step_9.png"
import { NotificationIcon } from "../../../../../assets/svgs/notificationIcon.svg"
import { AuthStateSelector } from "../../../../../selectors/authState.selector"
import { ProductStateSelector } from "../../../../../selectors/product.selector"
import PtQuickTourStepEight from "./ptQuickTourStepEight.component"
import PtQuickTourStepEleven from "./ptQuickTourStepEleven.component"
import PtQuickTourStepFive from "./ptQuickTourStepFive.component"
import PtQuickTourStepFour from "./ptQuickTourStepFour.component"
import PtQuickTourStepNine from "./ptQuickTourStepNine.component"
import PtQuickTourStepOne from "./ptQuickTourStepOne.component"
import PtQuickTourStepSeven from "./ptQuickTourStepSeven.component"
import PtQuickTourStepSix from "./ptQuickTourStepSix.component"
import PtQuickTourStepTen from "./ptQuickTourStepTen.component"
import PtQuickTourStepThree from "./ptQuickTourStepThree.component"
import PtQuickTourStepTwelve from "./ptQuickTourStepTwelve.component"
import PtQuickTourStepTwo from "./ptQuickTourStepTwo.component"

const PerformanceTrackerTourOutlet = () => {
    const { performanceTrackerQuickTour } = useSelector(RunTimeStateSelector)
    const { getAccessLevel } = useSelector(AuthStateSelector)
    const { selectedDropdownList } = useSelector(ProductStateSelector)

    const dispatch = useDispatch()

    const handleNextStep = (stepNumber: string) => {
        dispatch(
            RuntimeActionCreators.openPerformanceTourModal({
                open: true,
                stepNumber: stepNumber,
            })
        )
    }

    useEffect(() => {
        const images = [
            PTImage,
            PTStep2Bg,
            PTStep3Bg,
            PTStep4Bg,
            PTStep5Bg,
            PTStep6Bg,
            PTStep7Bg,
            PTStep8Bg,
            PTStep9Bg,
            PTStep10Bg,
            PTStep11Bg,
            PTStep12Bg,
        ]

        images.forEach((src) => {
            const img = new Image()
            img.src = src
        })
    }, [])

    const totalStepsData = [
        {
            stepNumber: "one",
            component: PtQuickTourStepOne,
            headerText: "Performance Tracker",
            image: PTImage,
        },
        { stepNumber: "two", component: PtQuickTourStepTwo, headerText: "Trackpacks" },
        { stepNumber: "three", component: PtQuickTourStepThree, headerText: "ASIN cards" },
        { stepNumber: "four", component: PtQuickTourStepFour, headerText: "Graph view" },
        { stepNumber: "five", component: PtQuickTourStepFive, headerText: "Changes" },
        { stepNumber: "six", component: PtQuickTourStepSix, headerText: "Viewing your data" },
        { stepNumber: "seven", component: PtQuickTourStepSeven, headerText: "Change icons" },
        { stepNumber: "eight", component: PtQuickTourStepEight, headerText: "Time" },
        { stepNumber: "nine", component: PtQuickTourStepNine, headerText: "Tracking status" },
        { stepNumber: "ten", component: PtQuickTourStepTen, headerText: "Table view" },
        { stepNumber: "eleven", component: PtQuickTourStepEleven, headerText: "Log view" },
        { stepNumber: "twelve", component: PtQuickTourStepTwelve, headerText: "High alert" },
    ]

    const filteredSteps = totalStepsData.filter((step) => {
        if (
            step.stepNumber === "nine" &&
            selectedDropdownList?.trackpacks?.length === 0 &&
            getAccessLevel &&
            getAccessLevel?.user_settings?.initial_qt_completed === false
        ) {
            return false
        }
        return true
    })

    const currentStepIndex = filteredSteps.findIndex(
        (step) => step.stepNumber === performanceTrackerQuickTour?.stepNumber
    )
    const currentStepData = filteredSteps[currentStepIndex]
    const totalSteps = filteredSteps.length

    const handleCloseDialog = () => {
        dispatch(RuntimeActionCreators.closePerformanceTourModal())
    }

    const getModalBody = () => {
        if (!currentStepData) return null

        const CurrentComponent = currentStepData.component
        const handleCloseProp =
            selectedDropdownList?.trackpacks?.length > 0 ||
            performanceTrackerQuickTour?.stepNumber === "twelve" ||
            (performanceTrackerQuickTour?.stepNumber !== "twelve" &&
                getAccessLevel?.user_settings?.initial_qt_completed === true)
                ? { handleCloseDialog }
                : {}

        return (
            <CurrentComponent
                icon={<NotificationIcon />}
                headerText={currentStepData.headerText}
                handleNext={() => handleNextStep(filteredSteps[currentStepIndex + 1]?.stepNumber || "")}
                currentStep={currentStepIndex + 1}
                totalSteps={totalSteps}
                image={currentStepData.image}
                {...handleCloseProp}
            />
        )
    }

    const getImageAndCss = () => {
        switch (performanceTrackerQuickTour?.stepNumber) {
            case "two":
                return {
                    image: PTStep2Bg,
                    imageCss: "w-[340px] left-[90px] top-[120px]",
                    modalCss: "top-[145px] left-[440px] custom_arrow step2",
                }
            case "three":
                return {
                    image: PTStep3Bg,
                    imageCss: "w-[340px]  left-[86px] top-[250px]",
                    modalCss: "top-[166px] left-[440px] custom_arrow step3 translate-x-[0%] translate-y-[0%]",
                }
            case "four":
                return {
                    image: PTStep4Bg,
                    imageCss: "w-[1020px]  left-[410px] top-[118px]",
                    modalCss: "top-[395px] left-[149px] right_custom_arrow step4 translate-x-[0%] translate-y-[0%]",
                }
            case "five":
                return {
                    image: PTStep5Bg,
                    imageCss: "w-[1020px] left-[410px] top-[118px]",
                    modalCss: "top-[495px] left-[666px] right_custom_arrow step5 translate-x-[0%] translate-y-[0%]",
                }
            case "six":
                return {
                    image: PTStep6Bg,
                    imageCss: "w-[984px]   left-[430px] top-[132px]",
                    modalCss: "top-[135px] left-[170px] right_custom_arrow step6 translate-x-[0%] translate-y-[0%]",
                }
            case "seven":
                return {
                    image: PTStep7Bg,
                    imageCss: "w-[984px]  left-[430px] top-[130px]",
                    modalCss: "top-[128px] left-[322px]  right_custom_arrow step7 translate-x-[0%] translate-y-[0%]",
                }
            case "eight":
                return {
                    image: PTStep8Bg,
                    imageCss: "w-[984px]  left-[430px] top-[130px]",
                    modalCss: "top-[132px] left-[550px]  right_custom_arrow step8 translate-x-[0%] translate-y-[0%]",
                }
            case "nine":
                return {
                    image: PTStep9Bg,
                    imageCss: " left-[104px] top-[130px] w-[1308px]",
                    modalCss:
                        " max-w-[249px] top-[400px] left-[1035px]  right_custom_arrow step9 translate-x-[0%] translate-y-[0%]",
                }
            case "ten":
                return {
                    image: PTStep10Bg,
                    imageCss: "w-[984px]  left-[430px] top-[130px]",
                    modalCss: "top-[212px] left-[1106px]  right_custom_arrow step10 translate-x-[0%] translate-y-[0%]",
                }

            case "eleven":
                return {
                    image: PTStep11Bg,
                    imageCss: "w-[984px]  left-[430px] top-[130px]",
                    modalCss:
                        "max-w-[249px] top-[209px] left-[1170px]  right_custom_arrow step11 translate-x-[0%] translate-y-[0%]",
                }
            case "twelve":
                return {
                    image: PTStep12Bg,
                    imageCss: "w-[424px]  right-[28px] top-[53px]",
                    modalCss:
                        "max-w-[249px]  top-[125px] right-[465px]  right_custom_arrow step12 translate-x-[0%] translate-y-[0%]",
                }

            default:
                return {
                    image: "",
                    imageCss: "",
                    modalCss: "",
                }
        }
    }

    const modalClassNames =
        performanceTrackerQuickTour?.stepNumber !== "one"
            ? `max-w-[248px] w-full bg-[#088AB2] rounded-[16px] p-[12px] absolute ${getImageAndCss().modalCss}`
            : ""

    return (
        <>
            {performanceTrackerQuickTour?.open ? (
                <>
                    <div className="relative bg-gray-900 h-screen w-screen">
                        {getImageAndCss().image && (
                            <img
                                src={getImageAndCss().image}
                                alt="PT Step"
                                className={`absolute ${getImageAndCss().imageCss}`}
                            />
                        )}
                        <div
                            className={modalClassNames}
                            style={{
                                boxShadow: "0px 8px 8px -4px #10182808, 0px 20px 24px -4px #10182814",
                            }}
                        >
                            {getModalBody()}
                        </div>
                    </div>
                </>
            ) : null}
        </>
    )
}

export default PerformanceTrackerTourOutlet
