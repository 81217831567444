export const ArrowLeft = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="12" height="12" rx="3" fill="#98A2B3" />
            <path
                d="M7.5 9L4 6L7.5 3"
                stroke="white"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
