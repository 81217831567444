import { AccountAction } from "../actions/account.action"
import { AccountStateClass } from "../classes/accountState.class"

const getInitialState = (): IAccountState => {
    const storedUserActionData = localStorage.getItem("userActionData")
    const initialState = new AccountStateClass()

    if (storedUserActionData) {
        initialState.userActionData = JSON.parse(storedUserActionData)
    }

    return initialState
}

export const AccountsReducers = (
    state: IAccountState = getInitialState(),
    action: ISagaAction
): Partial<IAccountState> => {
    switch (action.type) {
        case AccountAction.GET_USER_ACCOUNTS: {
            return {
                ...state,
            }
        }
        case AccountAction.SET_USER_ACCOUNTS: {
            return {
                ...state,
                data: action.payload,
            }
        }
        case AccountAction.SET_USER_ACCOUNTS_ACTIONS_DATA: {
            return {
                ...state,
                userActionData: action.payload,
            }
        }
        case AccountAction.SET_USER_ACCOUNTS_ACTIONS_DATA_EMPTY: {
            return {
                ...state,
                userActionData: undefined,
            }
        }
        case AccountAction.GET_TRACKPACK_DATA: {
            return {
                ...state,
            }
        }
        case AccountAction.SET_TRACKPACK_DATA: {
            return {
                ...state,
                trackpacksData: action.payload,
            }
        }

        case AccountAction.SELECTED_TRACKPACKS: {
            return {
                ...state,
                selectedTrackpacksList: action.payload,
            }
        }
        case AccountAction.SELECTED_ASINS: {
            return {
                ...state,
                selectedAsinsLength: action.payload,
            }
        }
        case AccountAction.TRACKPACK_TYPE: {
            return {
                ...state,
                trackpackType: action.payload,
            }
        }
        case AccountAction.SET_SEARCH_DATA: {
            return {
                ...state,
                searchData: action.payload,
            }
        }
        default:
            return state
    }
}
