import { DiffMethod, StringDiff } from "react-string-diff"

export const StringDiffElement = (props: IStringDiffElementProps) => {
    const styles: {
        added: React.CSSProperties
        removed: React.CSSProperties
        default: React.CSSProperties
    } = {
        added: {
            color: "#17B26A",
        },
        removed: {
            color: "#F04438",
            textDecorationLine: "line-through",
        },
        default: {},
    }
    return (
        <>
            <StringDiff
                method={DiffMethod.WordsWithSpace}
                styles={styles}
                oldValue={props.pastValue || ""}
                newValue={props.presentValue || ""}
            />
        </>
    )
}
