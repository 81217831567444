import { ButtonElement } from "../elements/button.element"
import { PrimaryText } from "../elements/primaryText.element"

import { useDispatch } from "react-redux"
import { RuntimeActionCreators } from "../../actions/runTime.action"
import { PreviousArrow } from "../../assets/svgs/previousArrow.svg"
import { RightArrow } from "../../assets/svgs/rightArrow.svg"
import { ReactComponent as XCloseIcon } from "../../assets/svgs/x-close.svg"

export const InnerTooltipHeader = (props: IInnerTooltipNavigationProps) => {
    const dispatch = useDispatch()
    return (
        <>
            <div className="flex gap-[8px] w-full">
                <div
                    className={`min-w-[40px] h-[40px] bg-[${props.color}] rounded-lg justify-center items-center flex`}
                >
                    {props.icon}
                </div>
                <div className="flex flex-col w-full gap-[6px]">
                    <div className="mt-[8px]">
                        <PrimaryText
                            weight="medium"
                            color="text-white"
                            className="text-[12px] leading-[10px] h-[10px] uppercase"
                        >
                            {props.title}
                        </PrimaryText>
                    </div>
                    <div className="absolute right-[12px] top-[6px]">
                        <ButtonElement
                            viewType="only-icon"
                            onClick={() => {
                                dispatch(RuntimeActionCreators.closeAllPopup())
                            }}
                        >
                            <XCloseIcon />
                        </ButtonElement>
                    </div>
                    <div className="flex items-center justify-between">
                        <PrimaryText
                            weight={"light"}
                            className="text-[9px] pt-[1px] flex-1 text-gray-400 leading-[9px] tracking-[0.28px] uppercase"
                        >
                            {props.timestamp}
                        </PrimaryText>

                        {props.total > 1 && (
                            <div className="flex gap-[2px] items-center">
                                <button
                                    className={`bg-slate-600 rounded-sm ${
                                        props.active <= 1 ? "disabled:opacity-[0.5]" : ""
                                    } `}
                                    onClick={() => {
                                        if (props.active <= 1) {
                                            return
                                        }
                                        props.setActive(props.active - 1)
                                    }}
                                    disabled={props.active <= 1}
                                >
                                    <PreviousArrow />
                                </button>

                                <button
                                    className={`bg-slate-700 rounded-sm ${
                                        !(props.active < props.total) ? "disabled:opacity-[0.5]" : ""
                                    }`}
                                    onClick={() => {
                                        if (!(props.active < props.total)) {
                                            return
                                        }
                                        props.setActive(props.active + 1)
                                    }}
                                    disabled={!(props.active < props.total)}
                                >
                                    <RightArrow />
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
