import { useRef, useState } from "react"

import { useDispatch } from "react-redux"
import { RuntimeActionCreators } from "../../actions/runTime.action"
import { AsinChangeTooltip } from "../../assets/svgs/tooltips/asin-change.svg"
import { CarouselIconTooltip } from "../../assets/svgs/tooltips/carousel.svg"
import { DescriptionIconTooltip } from "../../assets/svgs/tooltips/description.svg"
import { MainImageTooltip } from "../../assets/svgs/tooltips/main-image.svg"
import { PriceIconTooltip } from "../../assets/svgs/tooltips/price-icon.svg"
import { SellerInfoIconTooltip } from "../../assets/svgs/tooltips/seller-info.svg"
import { StockTooltipIcon } from "../../assets/svgs/tooltips/stock.svg"
import { TitleIconTooltip } from "../../assets/svgs/tooltips/title.svg"
import { BulletsIconTooltip } from "../../assets/svgs/tooltips/tooltip-bullets.svg"
import { VideoIconTooltip } from "../../assets/svgs/tooltips/video.svg"
import { UtilHelper } from "../../helpers/util.helper"
import "../../styles/tooltipIcons.component.css"
import { PrimaryText } from "../elements/primaryText.element"

export const TooltipIcon = (props: ITooltipIcons) => {
    const [hoveredKey, setHoveredIcon] = useState("")
    const dispatch = useDispatch()

    const { type, color, product, checked, id } = props
    const ref = useRef(null)

    // const getColor = (key: string) => {
    //     if (product?.price) {
    //         if (hoveredKey === key && !checked) {
    //             return props.color.hover
    //         } else if (checked) {
    //             return props.color.default
    //         } else {
    //             return props.color.active
    //         }
    //     } else {
    //         return props.color.inActive
    //     }
    // }
    const getColor = (key: string) => {
        let condition: boolean | undefined = false
        if (key === "actual_asin") {
            condition = UtilHelper.areIdsSame(product?.actual_asin as unknown as IActualAsin[])
        } else {
            condition = product && key in product
        }

        if (condition) {
            if (hoveredKey === key && !checked) {
                return props.color.active
            } else if (checked) {
                return props.color.default
            } else {
                return props.color.active
            }
        } else {
            return props.color.hover
        }
    }
    const getIconColor = (key: string) => {
        if (hoveredKey === key && !checked) {
            return props.color.hover
        } else {
            return ""
        }
    }

    const getbgColor = (key: string) => {
        let condition: boolean | undefined = false
        if (key === "actual_asin") {
            condition = UtilHelper.areIdsSame(product?.actual_asin as unknown as IActualAsin[])
        } else {
            condition = product && key in product
        }

        if (condition) {
            if ((hoveredKey === key || (hoveredKey === "stock" && key === "availability")) && !checked) {
                return props.color.hover
            } else {
                return ""
            }
        } else {
            return ""
        }
    }
    return (
        <>
            <div
                className={`relative flex  items-center  rounded-[4px] p-[2px] 
                hover:transition-all justify-center ${type} ${`${type}-${props.iconNumber}`}
                ${
                    type && product && product[type] && product[type]!.length
                        ? `${product[type]![0]?.timestamp}-${type}`
                        : ""
                }
                ${
                    type && product && product[type] && product[type]?.length && product[type]!.length > 2
                        ? " justify-between col-span-2"
                        : "justify-center"
                }
                ${
                    type === "stock" &&
                    product &&
                    product["availability"] &&
                    product["availability"]?.length &&
                    product["availability"]!.length > 2
                        ? " justify-between col-span-2"
                        : "justify-center"
                }
                bg-[${getbgColor(type === "stock" ? "availability" : type)}]
                ${checked ? `bg-[${color.active}]` : `bg-[]`}`}
                onClick={(e) => {
                    if (product && product[type]) {
                        if (type === "actual_asin") {
                            if (!UtilHelper.areIdsSame(product?.actual_asin as unknown as IActualAsin[])) {
                                return
                            }
                        }
                    }
                }}
            >
                <div ref={ref} className="flex ">
                    {(() => {
                        switch (type) {
                            case "price":
                                return (
                                    <>
                                        <div
                                            onMouseEnter={() => setHoveredIcon("price")}
                                            onMouseLeave={() => setHoveredIcon("")}
                                            data-tooltip-content={JSON.stringify({
                                                color: color,
                                                data: product,
                                                type: "price",
                                                id: id,
                                                totalIcons: props.totalIcons,
                                            })}
                                            data-tooltip-id={product?.price ? "price-tooltip" : ""}
                                            onClick={() =>
                                                product?.price
                                                    ? dispatch(
                                                          RuntimeActionCreators.openPopup(
                                                              "price-tooltip" + id + product?.timestamp
                                                          )
                                                      )
                                                    : null
                                            }
                                        >
                                            <div className="flex items-center gap-[3px]">
                                                <PriceIconTooltip
                                                    stroke={getColor("price")}
                                                    // stroke={
                                                    //     product?.price
                                                    //         ? hoveredKey === "price" && !checked
                                                    //             ? props.color.active
                                                    //             : "white"
                                                    //         : props.color.inActive
                                                    // }
                                                    className={` rounded-[4px] ${
                                                        product?.price
                                                            ? `cursor-pointer  bg-[${getIconColor("price")}]`
                                                            : "cursor-not-allowed"
                                                    } `}
                                                />
                                                {type &&
                                                    product &&
                                                    product[type] &&
                                                    product[type]?.length &&
                                                    product[type]!.length > 2 && (
                                                        <div
                                                            className={`
                                                                pt-[2.5px] pb-[2px] rounded-[3px] w-[16px] h-[14px] flex items-center justify-center 
                                                               bg-[${
                                                                   checked ? props.color.default : props.color.active
                                                               }]`}
                                                        >
                                                            <PrimaryText
                                                                weight={"medium"}
                                                                className={`text-[7.5px] text-[${
                                                                    checked ? "#FFF" : props.color.default
                                                                }]`}
                                                            >
                                                                {product && product[type]!.length - 1}
                                                            </PrimaryText>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    </>
                                )
                            case "main_image":
                                return (
                                    <>
                                        <div
                                            onMouseEnter={() => setHoveredIcon("main_image")}
                                            onMouseLeave={() => setHoveredIcon("")}
                                            data-tooltip-id={product?.main_image ? "main_image-tooltip" : ""}
                                            data-tooltip-content={JSON.stringify({
                                                color: color,

                                                data: product,
                                                type: "main_image",
                                                id: id,
                                                totalIcons: props.totalIcons,
                                            })}
                                            onClick={() =>
                                                product?.main_image
                                                    ? dispatch(
                                                          RuntimeActionCreators.openPopup(
                                                              "main_image-tooltip" + id + product?.timestamp
                                                          )
                                                      )
                                                    : null
                                            }
                                        >
                                            <div className="flex items-center gap-[3px] ">
                                                <MainImageTooltip
                                                    stroke={getColor("main_image")}
                                                    className={` rounded-[4px] ${
                                                        product?.main_image
                                                            ? `cursor-pointer  bg-[${getIconColor("main_image")}]`
                                                            : "cursor-not-allowed"
                                                    } `}
                                                />
                                                {type &&
                                                    product &&
                                                    product[type] &&
                                                    product[type]?.length &&
                                                    product[type]!.length > 2 && (
                                                        <div
                                                            className={`bg-[${
                                                                checked ? props.color.default : props.color.active
                                                            }]  pt-[2.5px] pb-[2px] rounded-[3px] w-[16px] h-[14px] flex items-center justify-center `}
                                                        >
                                                            <PrimaryText
                                                                weight={"medium"}
                                                                className={`text-[7.5px] text-[${
                                                                    checked ? "#FFF" : props.color.default
                                                                }]`}
                                                            >
                                                                {product && product[type]!.length - 1}
                                                            </PrimaryText>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    </>
                                )
                            case "carousel_images":
                                return (
                                    <>
                                        <div
                                            onMouseEnter={() => setHoveredIcon("carousel_images")}
                                            onMouseLeave={() => setHoveredIcon("")}
                                            data-tooltip-id={product?.carousel_images ? "carousel_images-tooltip" : ""}
                                            data-tooltip-content={JSON.stringify({
                                                color: color,

                                                data: product,
                                                type: "carousel_images",
                                                id: id,
                                                totalIcons: props.totalIcons,
                                            })}
                                            onClick={() =>
                                                product?.carousel_images
                                                    ? dispatch(
                                                          RuntimeActionCreators.openPopup(
                                                              "carousel_images-tooltip" + id + product?.timestamp
                                                          )
                                                      )
                                                    : null
                                            }
                                        >
                                            <div className="flex items-center gap-[3px] ">
                                                <CarouselIconTooltip
                                                    stroke={getColor("carousel_images")}
                                                    className={` rounded-[4px] ${
                                                        product?.carousel_images
                                                            ? `cursor-pointer  bg-[${getIconColor("carousel_images")}]`
                                                            : "cursor-not-allowed"
                                                    } `}
                                                />
                                                {type &&
                                                    product &&
                                                    product[type] &&
                                                    product[type]?.length &&
                                                    product[type]!.length > 2 && (
                                                        <div
                                                            className={`bg-[${
                                                                checked ? props.color.default : props.color.active
                                                            }]   pt-[2.5px] pb-[2px] rounded-[3px] w-[16px] h-[14px] flex items-center justify-center `}
                                                        >
                                                            <PrimaryText
                                                                weight={"medium"}
                                                                className={`text-[7.5px] text-[${
                                                                    checked ? "#FFF" : props.color.default
                                                                }]`}
                                                            >
                                                                {product && product[type]!.length - 1}
                                                            </PrimaryText>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    </>
                                )
                            case "title":
                                return (
                                    <>
                                        <div
                                            onMouseEnter={() => setHoveredIcon("title")}
                                            onMouseLeave={() => setHoveredIcon("")}
                                            data-tooltip-content={JSON.stringify({
                                                color: color,

                                                data: product,
                                                type: "title",
                                                id: id,
                                                totalIcons: props.totalIcons,
                                            })}
                                            data-tooltip-id={product?.title ? "title-tooltip" : ""}
                                            onClick={() =>
                                                product?.title
                                                    ? dispatch(
                                                          RuntimeActionCreators.openPopup(
                                                              "title-tooltip" + id + product?.timestamp
                                                          )
                                                      )
                                                    : null
                                            }
                                        >
                                            <div className="flex items-center gap-[3px] ">
                                                <TitleIconTooltip
                                                    stroke={getColor("title")}
                                                    className={` rounded-[4px] ${
                                                        product?.title
                                                            ? `cursor-pointer  bg-[${getIconColor("title")}]`
                                                            : "cursor-not-allowed"
                                                    } `}
                                                />
                                                {type &&
                                                    product &&
                                                    product[type] &&
                                                    product[type]?.length &&
                                                    product[type]!.length > 2 && (
                                                        <div
                                                            className={`bg-[${
                                                                checked ? props.color.default : props.color.active
                                                            }] pt-[2.5px] pb-[2px] rounded-[3px] w-[16px] h-[14px] flex items-center justify-center `}
                                                        >
                                                            <PrimaryText
                                                                weight={"medium"}
                                                                className={`text-[7.5px] text-[${
                                                                    checked ? "#FFF" : props.color.default
                                                                }]`}
                                                            >
                                                                {product && product[type]!.length - 1}
                                                            </PrimaryText>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    </>
                                )
                            case "bullets":
                                return (
                                    <>
                                        <div
                                            onMouseEnter={() => setHoveredIcon("bullets")}
                                            onMouseLeave={() => setHoveredIcon("")}
                                            data-tooltip-content={JSON.stringify({
                                                color: color,

                                                data: product,
                                                type: "bullets",
                                                id: id,
                                                totalIcons: props.totalIcons,
                                            })}
                                            data-tooltip-id={product?.bullets ? "bullets-tooltip" : ""}
                                            onClick={() =>
                                                product?.bullets
                                                    ? dispatch(
                                                          RuntimeActionCreators.openPopup(
                                                              "bullets-tooltip" + id + product?.timestamp
                                                          )
                                                      )
                                                    : null
                                            }
                                        >
                                            <div className="flex items-center gap-[3px]">
                                                <BulletsIconTooltip
                                                    stroke={getColor("bullets")}
                                                    className={` rounded-[4px] ${
                                                        product?.bullets
                                                            ? `cursor-pointer  bg-[${getIconColor("bullets")}]`
                                                            : "cursor-not-allowed"
                                                    } `}
                                                />
                                                {type &&
                                                    product &&
                                                    product[type] &&
                                                    product[type]?.length &&
                                                    product[type]!.length > 2 && (
                                                        <div
                                                            className={`bg-[${
                                                                checked ? props.color.default : props.color.active
                                                            }] pt-[2.5px] pb-[2px] rounded-[3px] w-[16px] h-[14px] flex items-center justify-center `}
                                                        >
                                                            <PrimaryText
                                                                weight={"medium"}
                                                                className={`text-[7.5px] text-[${
                                                                    checked ? "#FFF" : props.color.default
                                                                }]`}
                                                            >
                                                                {product && product[type]!.length - 1}
                                                            </PrimaryText>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    </>
                                )
                            case "description":
                                return (
                                    <>
                                        <div
                                            onMouseEnter={() => setHoveredIcon("description")}
                                            onMouseLeave={() => setHoveredIcon("")}
                                            data-tooltip-content={JSON.stringify({
                                                color: color,
                                                data: product,
                                                type: "description",
                                                id: id,
                                                totalIcons: props.totalIcons,
                                            })}
                                            data-tooltip-id={product?.description ? "description-tooltip" : ""}
                                            onClick={() =>
                                                product?.description
                                                    ? dispatch(
                                                          RuntimeActionCreators.openPopup(
                                                              "description-tooltip" + id + product?.timestamp
                                                          )
                                                      )
                                                    : null
                                            }
                                        >
                                            <div className="flex items-center gap-[3px] ">
                                                <DescriptionIconTooltip
                                                    stroke={getColor("description")}
                                                    className={` rounded-[4px] ${
                                                        product?.description
                                                            ? `cursor-pointer  bg-[${getIconColor("description")}]`
                                                            : "cursor-not-allowed"
                                                    } `}
                                                />
                                                {type &&
                                                    product &&
                                                    product[type] &&
                                                    product[type]?.length &&
                                                    product[type]!.length > 2 && (
                                                        <div
                                                            className={`bg-[${
                                                                checked ? props.color.default : props.color.active
                                                            }] pt-[2.5px] pb-[2px] rounded-[3px] w-[16px] h-[14px] flex items-center justify-center `}
                                                        >
                                                            <PrimaryText
                                                                weight={"medium"}
                                                                className={`text-[7.5px] leading-[8px] text-[${
                                                                    checked ? "#FFF" : props.color.default
                                                                }]`}
                                                            >
                                                                {product && product[type]!.length - 1}
                                                            </PrimaryText>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    </>
                                )
                            case "videos":
                                return (
                                    <>
                                        <div
                                            onMouseEnter={() => setHoveredIcon("videos")}
                                            onMouseLeave={() => setHoveredIcon("")}
                                            data-tooltip-content={JSON.stringify({
                                                color: color,

                                                data: product,
                                                type: "videos",
                                                id: id,
                                                totalIcons: props.totalIcons,
                                            })}
                                            data-tooltip-id={product?.videos ? "videos-tooltip" : ""}
                                            onClick={() =>
                                                product?.videos
                                                    ? dispatch(
                                                          RuntimeActionCreators.openPopup(
                                                              "videos-tooltip" + id + product?.timestamp
                                                          )
                                                      )
                                                    : null
                                            }
                                        >
                                            <div className="flex items-center gap-[3px] ">
                                                <VideoIconTooltip
                                                    stroke={getColor("videos")}
                                                    className={` rounded-[4px] ${
                                                        product?.videos
                                                            ? `cursor-pointer  bg-[${getIconColor("videos")}]`
                                                            : "cursor-not-allowed"
                                                    } `}
                                                />
                                                {type &&
                                                    product &&
                                                    product[type] &&
                                                    product[type]?.length &&
                                                    product[type]!.length > 2 && (
                                                        <div
                                                            className={`bg-[${
                                                                checked ? props.color.default : props.color.active
                                                            }] pt-[2.5px] pb-[2px] rounded-[3px] w-[16px] h-[14px] flex items-center justify-center `}
                                                        >
                                                            <PrimaryText
                                                                weight={"medium"}
                                                                className={`text-[7.5px] text-[${
                                                                    checked ? "#FFF" : props.color.default
                                                                }]`}
                                                            >
                                                                {product && product[type]!.length - 1}
                                                            </PrimaryText>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    </>
                                )
                            case "seller_info":
                                return (
                                    <>
                                        <div
                                            onMouseEnter={() => setHoveredIcon("seller_info")}
                                            onMouseLeave={() => setHoveredIcon("")}
                                            data-tooltip-content={JSON.stringify({
                                                color: color,

                                                data: product,
                                                type: "seller_info",
                                                id: id,
                                                totalIcons: props.totalIcons,
                                            })}
                                            data-tooltip-id={product?.seller_info ? "seller_info-tooltip" : ""}
                                            onClick={() =>
                                                product?.seller_info
                                                    ? dispatch(
                                                          RuntimeActionCreators.openPopup(
                                                              "seller_info-tooltip" + id + product?.timestamp
                                                          )
                                                      )
                                                    : null
                                            }
                                        >
                                            <div className="flex items-center gap-[3px] ">
                                                <SellerInfoIconTooltip
                                                    stroke={getColor("seller_info")}
                                                    className={` rounded-[4px] ${
                                                        product?.seller_info
                                                            ? `cursor-pointer  bg-[${getIconColor("seller_info")}]`
                                                            : "cursor-not-allowed"
                                                    } `}
                                                />
                                                {type &&
                                                    product &&
                                                    product[type] &&
                                                    product[type]?.length &&
                                                    product[type]!.length > 2 && (
                                                        <div
                                                            className={`bg-[${
                                                                checked ? props.color.default : props.color.active
                                                            }] pt-[2.5px] pb-[2px] rounded-[3px] w-[16px] h-[14px] flex items-center justify-center `}
                                                        >
                                                            <PrimaryText
                                                                weight={"medium"}
                                                                className={`text-[7.5px] text-[${
                                                                    checked ? "#FFF" : props.color.default
                                                                }]`}
                                                            >
                                                                {product && product[type]!.length - 1}
                                                            </PrimaryText>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    </>
                                )
                            case "stock":
                                return (
                                    <>
                                        <div
                                            onMouseEnter={() => setHoveredIcon("stock")}
                                            onMouseLeave={() => setHoveredIcon("")}
                                            data-tooltip-content={JSON.stringify({
                                                color: color,

                                                data: product,
                                                type: "stock",
                                                id: id,
                                                totalIcons: props.totalIcons,
                                            })}
                                            data-tooltip-id={product?.availability ? "stock-tooltip" : ""}
                                            onClick={() =>
                                                product?.availability
                                                    ? dispatch(
                                                          RuntimeActionCreators.openPopup(
                                                              "stock-tooltip" + id + product?.timestamp
                                                          )
                                                      )
                                                    : null
                                            }
                                        >
                                            <div className="flex items-center gap-[3px] ">
                                                <StockTooltipIcon
                                                    stroke={getColor("availability")}
                                                    className={` rounded-[4px] ${
                                                        product?.availability
                                                            ? `cursor-pointer bg-[${getIconColor("stock")}]`
                                                            : "cursor-not-allowed "
                                                    } `}
                                                />
                                                {type &&
                                                    product &&
                                                    product["availability"] &&
                                                    product["availability"]?.length &&
                                                    product["availability"]!.length > 2 && (
                                                        <div
                                                            className={`bg-[${
                                                                checked ? props.color.default : props.color.active
                                                            }] pt-[2.5px] pb-[2px] rounded-[3px] w-[16px] h-[14px] flex items-center justify-center `}
                                                        >
                                                            <PrimaryText
                                                                weight={"medium"}
                                                                className={`text-[7.54px] text-[${
                                                                    checked ? "#FFF" : props.color.default
                                                                }]`}
                                                            >
                                                                {product && product["availability"]!.length - 1}
                                                            </PrimaryText>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    </>
                                )
                            case "actual_asin":
                                return (
                                    <>
                                        <div
                                            onMouseEnter={() => setHoveredIcon("actual_asin")}
                                            onMouseLeave={() => setHoveredIcon("")}
                                            data-tooltip-content={JSON.stringify({
                                                color: color,

                                                data: product,
                                                type: "actual_asin",
                                                id: id,
                                                totalIcons: props.totalIcons,
                                            })}
                                            data-tooltip-id={
                                                UtilHelper.areIdsSame(product?.actual_asin as unknown as IActualAsin[])
                                                    ? "actual_asin-tooltip"
                                                    : ""
                                            }
                                            onClick={() =>
                                                UtilHelper.areIdsSame(product?.actual_asin as unknown as IActualAsin[])
                                                    ? dispatch(
                                                          RuntimeActionCreators.openPopup(
                                                              "actual_asin-tooltip" + id + product?.timestamp
                                                          )
                                                      )
                                                    : null
                                            }
                                        >
                                            <div className="flex items-center gap-[3px] ">
                                                <AsinChangeTooltip
                                                    stroke={getColor("actual_asin")}
                                                    // stroke={
                                                    //     UtilHelper.areIdsSame(
                                                    //         product?.actual_asin as unknown as IActualAsin[]
                                                    //     )
                                                    //         ? hoveredPrice === "actual_asin" && !checked
                                                    //             ? props.color.light
                                                    //             : "white"
                                                    //         : props.color.disabled
                                                    // }

                                                    className={
                                                        UtilHelper.areIdsSame(
                                                            product?.actual_asin as unknown as IActualAsin[]
                                                        )
                                                            ? ` rounded-[4px] cursor-pointer bg-[${getIconColor(
                                                                  "actual_asin"
                                                              )}]`
                                                            : "cursor-not-allowed"
                                                    }
                                                />
                                                {type &&
                                                    product &&
                                                    product[type] &&
                                                    product[type]?.length &&
                                                    product[type]!.length > 2 && (
                                                        <div
                                                            className={`bg-[${
                                                                checked ? props.color.default : props.color.active
                                                            }]  pt-[2.5px] pb-[2px] rounded-[3px] w-[16px] h-[14px] flex items-center justify-center `}
                                                        >
                                                            <PrimaryText
                                                                weight={"medium"}
                                                                className={`text-[7.5px] text-[${
                                                                    checked ? "#FFF" : props.color.default
                                                                }]`}
                                                            >
                                                                {product && product[type]!.length - 1}
                                                            </PrimaryText>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    </>
                                )
                            default:
                                return null
                        }
                    })()}
                </div>
            </div>
        </>
    )
}
