import { useDispatch } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { NotificationsActionsCreator } from "../../actions/notifications.action"
import { LinkExternalSvg } from "../../assets/svgs/link-external.svg"
import { ReactComponent as NoImage } from "../../assets/svgs/no-image.svg"
import { NotificationGreyIcon } from "../../assets/svgs/notificationGreyIcon.svg"
import { NotificationRedIcon } from "../../assets/svgs/notificationRedDot.svg"
import { SuccessIcon } from "../../assets/svgs/successIcon.svg"
import { AsinChangeTooltip } from "../../assets/svgs/tooltips/asin-change.svg"
import { CarouselIconTooltip } from "../../assets/svgs/tooltips/carousel.svg"
import { DescriptionIconTooltip } from "../../assets/svgs/tooltips/description.svg"
import { MainImageTooltip } from "../../assets/svgs/tooltips/main-image.svg"
import { PriceIconTooltip } from "../../assets/svgs/tooltips/price-icon.svg"
import { SellerInfoIconTooltip } from "../../assets/svgs/tooltips/seller-info.svg"
import { StockTooltipIcon } from "../../assets/svgs/tooltips/stock.svg"
import { TitleIconTooltip } from "../../assets/svgs/tooltips/title.svg"
import { BulletsIconTooltip } from "../../assets/svgs/tooltips/tooltip-bullets.svg"
import { VideoIconTooltip } from "../../assets/svgs/tooltips/video.svg"
import { WarningIcon } from "../../assets/svgs/warningIcon.svg"
import { img_url } from "../../config/dashboard.config"
import { ROUTES_CONFIG } from "../../config/routes.config"
import { getTooltipMessage, handleAmazonProductRedirect } from "../../helpers/util.helper"
import { PrimaryText } from "../elements/primaryText.element"

const NotificationAsinCard = (props: ProductNotification) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { pathname } = location

    const tooltipMessage = getTooltipMessage(props?.current_issue!)
    const additionalMessage =
        props?.current_issue! === "LISTING_CHANGE" ||
        props?.current_issue! === "DIFFERENT_ASIN_RETURNED" ||
        props?.current_issue! === "CHILD_ASIN_RETURNED"
            ? " returned"
            : props?.current_issue! === "DATA_UNAVAILABLE"
            ? " unavailable"
            : props?.current_issue! === "DIFFERENT_LARGE_CATEGORY"
            ? "Different"
            : ""

    const handleProductRedirect = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
        handleAmazonProductRedirect(props?.amazon_tld!, props?.requested_asin!)
    }
    const handleListingProductRedirect = () => {
        handleAmazonProductRedirect(props?.amazon_tld!, props?.requested_asin!)
    }

    const handleNotificationRead = (id: number, requested_asin: string, trackpackId?: number) => {
        if (props?.read_at === null) {
            const payload = {
                notification_ids: [id],
            }

            dispatch(
                NotificationsActionsCreator.markNotificationAsRead({ ...payload }, () => {
                    dispatch(NotificationsActionsCreator.getAllNotifications())
                })
            )
        }
        localStorage.setItem("notifiAsin", JSON.stringify([requested_asin]))

        localStorage.setItem("selectedTrackpackID", trackpackId!.toString())
        // @ts-ignore
        if (typeof window.updateSelectedTrackpackID === "function") {
            // @ts-ignore
            window.updateSelectedTrackpackID(trackpackId.toString())
        }

        // @ts-ignore
        if (typeof window.updateDefaultSelectedAsin === "function") {
            // @ts-ignore
            window.updateDefaultSelectedAsin(requested_asin)
        }
        if (pathname !== ROUTES_CONFIG.dashboard) {
            navigate(ROUTES_CONFIG.dashboard)
        }
        // props.onClose!()
    }

    const renderIcon = (key: string) => {
        switch (key) {
            case "price":
                return <PriceIconTooltip stroke={"#088AB2"} className={` rounded-[3px] p-[1px] bg-[#67E3F9]`} />
            case "videos":
                return <VideoIconTooltip stroke={"#088AB2"} className={` rounded-[3px] p-[1px] bg-[#67E3F9]`} />
            case "title_id":
                return <TitleIconTooltip stroke={"#088AB2"} className={` rounded-[3px] p-[1px] bg-[#67E3F9]`} />
            case "seller_id":
                return <SellerInfoIconTooltip stroke={"#088AB2"} className={` rounded-[3px] p-[1px] bg-[#67E3F9]`} />
            case "bullets_id":
                return <BulletsIconTooltip stroke={"#088AB2"} className={` rounded-[3px] p-[1px] bg-[#67E3F9]`} />
            case "main_image_id":
                return <MainImageTooltip stroke={"#088AB2"} className={` rounded-[3px] p-[1px] bg-[#67E3F9]`} />
            case "actual_asin_id":
                return <AsinChangeTooltip stroke={"#088AB2"} className={` rounded-[3px] p-[1px] bg-[#67E3F9]`} />
            case "availability_id":
                return <StockTooltipIcon stroke={"#088AB2"} className={` rounded-[3px] p-[1px] bg-[#67E3F9]`} />
            case "carousel_images_id":
                return <CarouselIconTooltip stroke={"#088AB2"} className={` rounded-[3px] p-[1px] bg-[#67E3F9]`} />
            case "description_id":
                return <DescriptionIconTooltip stroke={"#088AB2"} className={` rounded-[3px] p-[1px] bg-[#67E3F9]  `} />

            default:
                return null
        }
    }

    return (
        <div className="flex gap-[12px] mt-[12px] bg-white p-[8px] rounded-[10px] ml-[50px]">
            {props?.main_image_filename !== null ? (
                <img
                    src={`${img_url}${props?.main_image_filename}`}
                    alt="product images"
                    className="h-[44px] min-w-[44px] flex-1 border border-gray-300 overflow-hidden rounded-[6px] object-contain bg-white"
                />
            ) : (
                <div className="h-[44px] min-w-[44px] flex-1 flex items-center justify-center border border-gray-300 overflow-hidden rounded-[4px] object-contain bg-gray-800">
                    <NoImage />
                </div>
            )}
            {props?.islistingChange === true ? (
                <div
                    className="w-full cursor-pointer"
                    onClick={() =>
                        handleNotificationRead(
                            props?.notification_id! && props?.notification_id,
                            props.requested_asin!,
                            props?.trackpackId!
                        )
                    }
                >
                    <div className="mt-[3px] flex items-center justify-between w-full">
                        <div className="flex items-center gap-x-[4px]">
                            <SuccessIcon />
                            <PrimaryText size="xs-medium" weight="medium" className="leading-[12px] flex text-gray-700">
                                {props?.total_changes} {props?.total_changes === 1 ? "change" : "changes"} to&nbsp;
                                <PrimaryText
                                    weight="medium"
                                    size="xs-medium"
                                    className="text-[#06AED4] uppercase inline-flex gap-x-[2px]"
                                    onClick={handleListingProductRedirect}
                                >
                                    {props?.requested_asin}
                                    <LinkExternalSvg color={"#06AED4"} className="mb-[1px]" />
                                </PrimaryText>
                            </PrimaryText>
                        </div>

                        {props?.read_at === null ? <NotificationRedIcon /> : <NotificationGreyIcon />}
                    </div>
                    <div className="flex items-center gap-x-[4px] mt-[6px]">
                        {props?.changes &&
                            props?.changes.map((change, index) => <span key={index}>{renderIcon(change.key)}</span>)}
                    </div>
                </div>
            ) : (
                <div
                    className="w-full cursor-pointer"
                    onClick={() =>
                        handleNotificationRead(
                            props?.notification_id! && props?.notification_id,
                            props.requested_asin!,
                            props?.trackpackId!
                        )
                    }
                >
                    <div className="mt-[3px] flex items-center justify-between w-full">
                        {props.current_issue !== null ? (
                            <div className="flex items-center gap-x-[4px]">
                                <WarningIcon />
                                <PrimaryText size="xs-medium" weight="medium" className="leading-[12px]">
                                    {tooltipMessage} {additionalMessage}
                                </PrimaryText>
                            </div>
                        ) : (
                            <div className="flex items-center gap-x-[4px]">
                                <SuccessIcon />
                                <PrimaryText size="xs-medium" weight="medium">
                                    ASIN added{" "}
                                </PrimaryText>
                            </div>
                        )}

                        {props?.read_at === null ? <NotificationRedIcon /> : <NotificationGreyIcon />}
                    </div>
                    <div
                        className="bg-transparent border-[#088ab2f4] border w-fit mt-[6px] relative cursor-pointer rounded-[3px] px-[4px] py-[2.5px] h-4 flex gap-[3px] justify-center items-center"
                        onClick={(e) => handleProductRedirect(e)}
                    >
                        <PrimaryText
                            className="text-[#088AB2] uppercase tracking-[0.7px]"
                            weight="medium"
                            size="xs-medium"
                        >
                            {props?.requested_asin}
                        </PrimaryText>
                        <div className="cursor-pointer" onClick={(e) => handleProductRedirect(e)}>
                            <LinkExternalSvg color={"#088AB2"} className="mb-[1px]" />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default NotificationAsinCard
