export const ExpandIcon = (props: IArrowProps) => {
    return (
        <svg
            onClick={props.onClick}
            className={props.className}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_1536_42294)">
                <path
                    d="M5.83333 4.16667L8.75 1.25M8.75 1.25H6.25M8.75 1.25V3.75M4.16667 5.83333L1.25 8.75M1.25 8.75H3.75M1.25 8.75L1.25 6.25"
                    stroke={props.color}
                    stroke-width="0.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_1536_42294">
                    <rect width="10" height="10" fill="#fff" />
                </clipPath>
            </defs>
        </svg>
    )
}
