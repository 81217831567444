export const NotificationChevronDownIcon = (props: IArrowProps) => {
    return (
        <svg
            className={props.className}
            onClick={props.onClick}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4 6L8 10L12 6"
                stroke="#667085"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
