import dayjs from "dayjs"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import ReactPlayer from "react-player"
import { useSelector } from "react-redux"
import { PopupPlayIcon } from "../../../assets/svgs/popupPlayIcon.svg"
import { VideoUnavailable } from "../../../assets/svgs/videoUnavailable.svg"
import { useProductHistory } from "../../../hooks/productHistory.hook"
import { RunTimeStateSelector } from "../../../selectors/RunTimeStateSelector"
import ArrowModalActions from "../../common/arrowModalActions.component"
import InnerModalHeader from "../../common/innerModalHeader.component"
import { PrimaryText } from "../../elements/primaryText.element"
import Slider from "../../elements/slider.component"
import { ChartsWrapper } from "./chartsWrapper.component"

export const VideoModal = (data: IDialogOptionsProps) => {
    const [playingIndex, setPlayingIndex] = useState<number | null>(null)
    const { modal } = useSelector(RunTimeStateSelector)
    const { active, history, setActive, total } = useProductHistory(data.data as IProductData, "videos")
    const { t: tCommon } = useTranslation("tooltip", {
        keyPrefix: "commonLabels",
    })

    const videoIndex = modal?.index

    const pastVideos = history?.past?.value || []
    const presentVideos = history?.present?.value || []

    const reorderVideos = (videos: IProductDataVideo[], index: number | undefined): IProductDataVideo[] => {
        if (!videos || videos.length === 0 || index === undefined || index < 0 || index >= videos.length) {
            return videos
        }
        const reordered = [...videos]
        const [firstVideo] = reordered.splice(index, 1)
        reordered.unshift(firstVideo)
        return reordered
    }

    const getUniqueValues = (array1: IProductDataVideo[], array2: IProductDataVideo[]): IProductDataVideo[] => {
        return array1.filter((item1) => !array2.some((item2) => item2.video_url === item1.video_url))
    }

    const reorderAndFilterUnique = (
        pastVideos: IProductDataVideo[],
        presentVideos: IProductDataVideo[],
        index: number | undefined
    ): { reorderedPastVideos: IProductDataVideo[]; reorderedPresentVideos: IProductDataVideo[] } => {
        const reorderedPastVideos = reorderVideos(pastVideos, index)
        const reorderedPresentVideos = reorderVideos(presentVideos, index)

        const uniqueReorderedPastVideos = getUniqueValues(reorderedPastVideos, reorderedPresentVideos)
        const uniqueReorderedPresentVideos = getUniqueValues(reorderedPresentVideos, reorderedPastVideos)
        if (pastVideos.length > 0 && presentVideos.length > 0) {
            const maxLength = Math.max(uniqueReorderedPastVideos.length, uniqueReorderedPresentVideos.length)
            const paddedUniqueInPast = uniqueReorderedPastVideos.concat(
                Array(maxLength - uniqueReorderedPastVideos.length).fill({ video_url: null, thumbnail_url: null })
            )
            const paddedUniqueInPresent = uniqueReorderedPresentVideos.concat(
                Array(maxLength - uniqueReorderedPresentVideos.length).fill({ video_url: null, thumbnail_url: null })
            )
            return { reorderedPastVideos: paddedUniqueInPast, reorderedPresentVideos: paddedUniqueInPresent }
        }

        return { reorderedPastVideos: uniqueReorderedPastVideos, reorderedPresentVideos: uniqueReorderedPresentVideos }
    }

    // const { reorderedPastVideos, reorderedPresentVideos } = reorderAndFilterUnique(
    //     pastVideos,
    //     presentVideos,
    //     videoIndex
    // )

    const showUnavailablePast = data?.data?.videos?.length && !history.past
    const pastVideosPlaceholder = showUnavailablePast
        ? Array(presentVideos.length).fill({ video_url: null, thumbnail_url: null })
        : pastVideos
    const { reorderedPastVideos, reorderedPresentVideos } = reorderAndFilterUnique(
        pastVideosPlaceholder,
        presentVideos,
        videoIndex
    )

    return (
        <>
            <ChartsWrapper
                className={`p-[48px] absolute top-[50%] left-[50%] -translate-y-2/4 -translate-x-2/4 bg-white rounded-[24px] m-auto ${
                    reorderedPastVideos?.length && reorderedPresentVideos?.length ? "w-[900px]" : "w-[628px]"
                } shadow-modal-shadow border-[1px] border-solid border-[#E7EBEF] transition-all`}
            >
                <InnerModalHeader color={modal?.color} type="video" />
                <PrimaryText className="text-[#344054] text-[24px] ml-[6px] font-[500] leading-[32px] mb-[4px]">
                    Video
                </PrimaryText>
                <div className={` ${total > 1 ? "ml-[8px] flex items-center gap-[10px]" : "ml-[6px]"}`}>
                    <ArrowModalActions total={total} active={active} setActive={setActive} />
                    <div className="flex items-center justify-between w-full">
                        <PrimaryText className="text-[12px] uppercase leading-[12px] text-[#667085] font-[400]">
                            {tCommon("timestamp", {
                                timestamp: dayjs(history?.present?.timestamp).format("MMM-DD-YYYY @ HH:mm [GMT]"),
                            })}
                        </PrimaryText>
                        {history?.past?.value?.length && history.present?.value?.length && (
                            <PrimaryText className="py-[2px] px-[8px] border border-[#A5F0FC] bg-[#ECFDFF] rounded-[16px] text-[12px] leading-[18px] font-[400] text-[#0E7090]">
                                Videos for this product
                            </PrimaryText>
                        )}
                    </div>
                </div>
                <div className="mt-[40px]">
                    <div className="flex gap-[12px]">
                        <Slider isLargeIcons={true} color={modal?.color} type="Video" className="!m-0">
                            {Array.from(
                                {
                                    length: Math.max(reorderedPastVideos?.length, reorderedPresentVideos?.length),
                                },
                                (_, index) => {
                                    const pastLink = reorderedPastVideos[index]
                                    const presentLink = reorderedPresentVideos[index]
                                    const isPlaying = playingIndex === index
                                    return (
                                        <div
                                            key={index}
                                            className={`embla__slide flex ${
                                                reorderedPastVideos.length <= 1 && reorderedPresentVideos.length <= 1
                                                    ? ""
                                                    : "mr-[12px]"
                                            }`}
                                        >
                                            {pastLink && (
                                                <div className="bg-[#F2F4F7] rounded-[16px] p-4 pb-[18px] min-w-[260px] relative mr-[12px]">
                                                    <PrimaryText className="w-[32px] py-[5.5px] px-[6px] bg-[#F04438] text-white rounded-[6px] mb-[16px] text-[10px] leading-[9px] font-[500] flex items-center justify-center">
                                                        Was
                                                    </PrimaryText>
                                                    <div className="w-[228px] h-[128px] overflow-hidden border border-gray-200 rounded-[8px]">
                                                        {pastLink?.thumbnail_url === null ? (
                                                            <div className="object-contain chrome:w-full safari:w-full h-full rounded-[4px] flex items-center justify-center border border-gray-400 select-none cursor-pointer bg-gray-900">
                                                                <VideoUnavailable />
                                                            </div>
                                                        ) : (
                                                            <img
                                                                className="w-full h-full object-contain bg-gray-900"
                                                                src={pastLink?.thumbnail_url}
                                                                alt=""
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="absolute left-[16px] bottom-[18px]">
                                                        <PrimaryText
                                                            weight="book"
                                                            size="xs"
                                                            className="text-gray-500 leading-[10px] ml-[4px] italic"
                                                        >
                                                            Video unavailable
                                                        </PrimaryText>
                                                    </div>
                                                </div>
                                            )}
                                            {presentLink && (
                                                <div className="bg-[#F2F4F7] p-4 rounded-[16px] min-w-[532px] relative">
                                                    <PrimaryText className="w-[32px] py-[5.5px] px-[6px] text-white bg-[#47CD89] rounded-[6px] mb-[16px] text-[10px] leading-[9px] font-[500] flex items-center justify-center">
                                                        Now
                                                    </PrimaryText>
                                                    <div className="relative w-full h-[280px] overflow-hidden border border-gray-200 rounded-[8px]">
                                                        {isPlaying ? (
                                                            <ReactPlayer
                                                                url={presentLink?.video_url}
                                                                controls={true}
                                                                onEnded={() => setPlayingIndex(null)}
                                                                width="100%"
                                                                height="100%"
                                                                playing={true}
                                                                style={{ backgroundColor: "#101828" }}
                                                            />
                                                        ) : (
                                                            <>
                                                                <img
                                                                    className="w-full h-full object-contain bg-gray-900"
                                                                    src={presentLink?.thumbnail_url}
                                                                    alt=""
                                                                />
                                                                <div
                                                                    onClick={() => setPlayingIndex(index)}
                                                                    className="absolute bottom-[14px] left-[14px] cursor-pointer"
                                                                >
                                                                    <PopupPlayIcon />
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )
                                }
                            )}
                        </Slider>
                    </div>
                </div>
            </ChartsWrapper>
        </>
    )
}
