import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import InnerModalHeader from "../../common/innerModalHeader.component"
import { PrimaryText } from "../../elements/primaryText.element"
import Slider from "../../elements/slider.component"
import { ChartsWrapper } from "./chartsWrapper.component"

import { useProductHistory } from "../../../hooks/productHistory.hook"
import { RunTimeStateSelector } from "../../../selectors/RunTimeStateSelector"

import ArrowModalActions from "../../common/arrowModalActions.component"
import { StringDiffElement } from "../../elements/stringDiff.element"

const BulletModal = (data: IDialogOptionsProps) => {
    const { modal } = useSelector(RunTimeStateSelector)
    const { t: tCommon } = useTranslation("tooltip", {
        keyPrefix: "commonLabels",
    })

    const { active, history, setActive, total } = useProductHistory(data?.data as IProductData, "bullets")

    const bulletIndex = modal?.index

    function getUniqueValues(pastArray: string[] | undefined, presentArray: string[] | undefined) {
        let uniqueInPast = history?.past?.value
        let uniqueInPresent = history?.present?.value

        if (presentArray && pastArray) {
            let uniqueInPast: string[] | undefined = pastArray.filter(
                (pastItem, index) => pastItem !== presentArray[index]
            )
            let uniqueInPresent: string[] | undefined = presentArray.filter(
                (presentItem, index) => presentItem !== pastArray[index]
            )

            if (data?.data && data?.data?.bullets?.length > 1) {
                const maxLength = Math.max(uniqueInPast?.length, uniqueInPresent.length)
                const paddedUniqueInPast = uniqueInPast.concat(Array(maxLength - uniqueInPast.length).fill(""))
                const paddedUniqueInPresent = uniqueInPresent.concat(Array(maxLength - uniqueInPresent.length).fill(""))
                return { uniqueInPast: paddedUniqueInPast, uniqueInPresent: paddedUniqueInPresent }
            }
        }
        return { uniqueInPast, uniqueInPresent }
    }

    const { uniqueInPast, uniqueInPresent } = getUniqueValues(history.past?.value, history.present?.value)

    const pastBullets = uniqueInPast
    const presentBullets = uniqueInPresent

    const reorderItems = (items: any, index: number | undefined) => {
        if (!items || items?.length === 0) return []
        const reordered = [...items]
        const matchedItemIndex = items.findIndex((_: any, i: number) => i === index)
        if (matchedItemIndex !== -1) {
            const [firstItem] = reordered.splice(matchedItemIndex, 1)
            reordered.unshift(firstItem)
        }
        return reordered
    }

    const reorderedPastBullets = reorderItems(pastBullets, bulletIndex)
    const reorderedPresentBullets = reorderItems(presentBullets, bulletIndex)

    return (
        <>
            <ChartsWrapper
                className={`p-[48px] absolute top-[50%] left-[50%] -translate-y-2/4 -translate-x-2/4 bg-white rounded-[24px] m-auto ${
                    history?.present?.value.length && history.past?.value.length ? "w-[900px]" : "w-[356px]"
                } shadow-modal-shadow border-[1px] border-solid border-[#E7EBEF] transition-all`}
            >
                <InnerModalHeader color={modal?.color} type="bullets" />

                <PrimaryText className="text-[#344054] ml-[6px] text-[24px] font-[500] leading-[32px] mb-[4px]">
                    Bullets
                </PrimaryText>

                <div className={` ${total > 1 ? "ml-[8px] flex items-center gap-[10px]" : "ml-[6px]"}`}>
                    <ArrowModalActions total={total} active={active} setActive={setActive} />
                    <PrimaryText className="text-[12px] uppercase leading-[12px] text-[#667085] font-[400]">
                        {tCommon("timestamp", {
                            timestamp: dayjs(history?.present?.timestamp).format("MMM-DD-YYYY @ HH:mm [GMT]"),
                        })}
                    </PrimaryText>
                </div>

                <div className="mt-[40px]">
                    <div className="flex gap-[12px]">
                        <Slider isLargeIcons={true} color={modal?.color} type="Bullet" className="!m-0">
                            {Array.from(
                                {
                                    length: Math.max(reorderedPastBullets.length, reorderedPresentBullets.length),
                                },
                                (_, index) => {
                                    const pastLink = reorderedPastBullets[index]
                                    const presentLink = reorderedPresentBullets[index]

                                    return (
                                        <div
                                            className={`embla__slide flex ${
                                                reorderedPastBullets.length >= 0 && reorderedPresentBullets.length >= 0
                                                    ? ""
                                                    : "mr-[12px]"
                                            }`}
                                            key={index}
                                        >
                                            {typeof pastLink === "string" && (
                                                <div className="bg-[#F2F4F7] rounded-[16px] p-4 w-[260px] pb-[20px] h-[200px] mr-[12px]">
                                                    <PrimaryText className="w-[33px] py-[5.5px] px-[6px] bg-[#F04438] text-white rounded-[6px] mb-[16px] text-[10px] leading-[9px] font-[500] flex items-center justify-center">
                                                        Was
                                                    </PrimaryText>
                                                    <PrimaryText className="text-[12px] pr-[4px] leading-[16px] font-[300] w-[225px] max-h-[128px] modal-scrollbar-pr overflow-auto">
                                                        {pastLink}
                                                    </PrimaryText>
                                                </div>
                                            )}

                                            {typeof pastLink === "string" && typeof presentLink === "string" && (
                                                <div className="bg-[#F2F4F7] rounded-[16px] p-4 w-[260px] pb-[20px] h-[200px] mr-[12px]">
                                                    <PrimaryText className="w-[53px] py-[5.5px] px-[6px] bg-[#667085] text-white rounded-[6px] mb-[16px] text-[10px] leading-[9px] font-[500] flex items-center justify-center">
                                                        Changes
                                                    </PrimaryText>
                                                    <PrimaryText className="text-[12px] leading-[16px] pr-[4px] font-[300] w-[225px] max-h-[128px] modal-scrollbar-pr overflow-auto">
                                                        {history.past && history.present && (
                                                            <StringDiffElement
                                                                pastValue={pastLink}
                                                                presentValue={presentLink}
                                                            />
                                                        )}
                                                    </PrimaryText>
                                                </div>
                                            )}

                                            {typeof presentLink === "string" && (
                                                <div className="bg-[#F2F4F7] rounded-[16px] p-4 w-[260px] pb-[20px]  h-[200px] mr-[12px]">
                                                    <PrimaryText className="w-[33px] py-[5.5px] px-[6px] bg-[#47CD89] text-white rounded-[6px] mb-[16px] text-[10px] leading-[9px] font-[500] flex items-center justify-center">
                                                        Now
                                                    </PrimaryText>
                                                    <PrimaryText className="text-[12px] pr-[4px] leading-[16px] font-[300] w-[225px] max-h-[128px] modal-scrollbar-pr overflow-auto">
                                                        {presentLink}
                                                    </PrimaryText>
                                                </div>
                                            )}
                                        </div>
                                    )
                                }
                            )}
                        </Slider>
                    </div>
                </div>
            </ChartsWrapper>
        </>
    )
}

export default BulletModal
