import { useDispatch, useSelector } from "react-redux"

import { RunTimeStateSelector } from "../../../../../selectors/RunTimeStateSelector"

import { useEffect } from "react"
import { RuntimeActionCreators } from "../../../../../actions/runTime.action"
import THPStep1 from "../../../../../assets/images/TPH_QT_Step_1.png"
import THPStep2 from "../../../../../assets/images/TPH_QT_Step_2.png"
import THPStep3 from "../../../../../assets/images/TPH_QT_Step_3.png"
import THPStep4 from "../../../../../assets/images/TPH_QT_Step_4.png"
import THPStep5 from "../../../../../assets/images/TPH_QT_Step_5.png"
import THPStep6 from "../../../../../assets/images/TPH_QT_Step_6.png"
import THPStep7 from "../../../../../assets/images/TPH_QT_Step_7.png"
import THPStep8 from "../../../../../assets/images/TPH_QT_Step_8.png"
import THPStep9 from "../../../../../assets/images/TPH_QT_Step_9.png"
import { NotificationIcon } from "../../../../../assets/svgs/notificationIcon.svg"
import { AuthStateSelector } from "../../../../../selectors/authState.selector"
import ThQuickTourStepEight from "./thQuickTourStepEight.component"
import ThQuickTourStepFive from "./thQuickTourStepFive.component"
import ThQuickTourStepFour from "./thQuickTourStepFour.component"
import ThQuickTourStepNine from "./thQuickTourStepNine.component"
import ThQuickTourStepOne from "./thQuickTourStepOne.component"
import ThQuickTourStepSeven from "./thQuickTourStepSeven.component"
import ThQuickTourStepSix from "./thQuickTourStepSix.component"
import ThQuickTourStepThree from "./thQuickTourStepThree.component"
import ThQuickTourStepTwo from "./thQuickTourStepTwo.component"

const TrackpackHubTourOutlet = () => {
    const { trackpackHubQuickTour } = useSelector(RunTimeStateSelector)
    const { getAccessLevel } = useSelector(AuthStateSelector)

    const dispatch = useDispatch()

    const handleNextStep = (stepNumber: string) => {
        dispatch(
            RuntimeActionCreators.openTrackpackHubTourModal({
                open: true,
                stepNumber: stepNumber,
            })
        )
    }

    useEffect(() => {
        const images = [THPStep1, THPStep2, THPStep3, THPStep4, THPStep5, THPStep6, THPStep7, THPStep8, THPStep9]

        images.forEach((src) => {
            const img = new Image()
            img.src = src
        })
    }, [])

    const totalStepsData = [
        {
            stepNumber: "one",
            component: ThQuickTourStepOne,
            headerText: "Trackpack ",
            image: THPStep1,
        },
        { stepNumber: "two", component: ThQuickTourStepTwo, headerText: "Trackpack cards" },
        { stepNumber: "three", component: ThQuickTourStepThree, headerText: "Archiving Trackpacks" },
        { stepNumber: "four", component: ThQuickTourStepFour, headerText: "Archiving ASINs" },
        { stepNumber: "five", component: ThQuickTourStepFive, headerText: "Naming Trackpacks" },
        { stepNumber: "six", component: ThQuickTourStepSix, headerText: "Further functionality" },
        { stepNumber: "seven", component: ThQuickTourStepSeven, headerText: "Tracking status" },
        { stepNumber: "eight", component: ThQuickTourStepEight, headerText: "Keeping data for later" },
        { stepNumber: "nine", component: ThQuickTourStepNine, headerText: "View your way" },
    ]

    const filteredSteps = totalStepsData.filter((step) => {
        if (
            step.stepNumber === "ten" &&
            getAccessLevel &&
            getAccessLevel?.user_settings?.initial_qt_completed === true
        ) {
            return false
        }
        return true
    })

    const currentStepIndex = filteredSteps.findIndex((step) => step.stepNumber === trackpackHubQuickTour?.stepNumber)
    const currentStepData = filteredSteps[currentStepIndex]
    const totalSteps = filteredSteps.length

    const handleCloseDialog = () => {
        dispatch(RuntimeActionCreators.closeTrackpackHubTourModal())
    }

    const getTourModal = () => {
        if (!currentStepData) return null

        const CurrentComponent = currentStepData.component
        const handleCloseProp = { handleCloseDialog }

        return (
            <CurrentComponent
                icon={<NotificationIcon />}
                headerText={currentStepData.headerText}
                handleNext={() => handleNextStep(filteredSteps[currentStepIndex + 1]?.stepNumber || "")}
                currentStep={currentStepIndex + 1}
                totalSteps={totalSteps}
                image={currentStepData.image}
                {...handleCloseProp}
            />
        )
    }

    const getImageAndCss = () => {
        switch (trackpackHubQuickTour?.stepNumber) {
            case "two":
                return {
                    image: THPStep2,
                    imageCss: "w-[340px] absolute left-[86px] top-[118px]",
                    modalCss: "mx-auto top-[230px] left-[440px]  custom_arrow step2",
                }
            case "three":
                return {
                    image: THPStep3,
                    imageCss: "w-[316px] left-[98px] top-[218px]",
                    modalCss: "mx-auto top-[160px] left-[429px] custom_arrow step3",
                }
            case "four":
                return {
                    image: THPStep4,
                    imageCss: "w-[984px] absolute left-[428px] top-[136px]",
                    modalCss: "mx-auto top-[175px] left-[159px] right_custom_arrow step4",
                }
            case "five":
                return {
                    image: THPStep5,
                    imageCss: "w-[984px] absolute left-[428px] top-[136px]",
                    modalCss: "mx-auto top-[84px] left-[167px] right_custom_arrow step4",
                }
            case "six":
                return {
                    image: THPStep6,
                    imageCss: "w-[984px] absolute left-[428px] top-[136px]",
                    modalCss: "top-[75px] left-[932px] right_custom_arrow step6 translate-x-[0%] translate-y-[0%]",
                }
            case "seven":
                return {
                    image: THPStep7,
                    imageCss: "w-[1308px] h-[800px] absolute left-[104px] top-[136px]",
                    modalCss:
                        "chrome:top-[156px] safari:top-[142px] left-[858px] right_custom_arrow step7 translate-x-[0%] translate-y-[0%]",
                }
            case "eight":
                return {
                    image: THPStep8,
                    imageCss: " w-[984px] absolute left-[428px] top-[136px]",
                    modalCss:
                        " chrome:top-[148px] safari:top-[148px] left-[1005px]  right_custom_arrow step8 translate-x-[0%] translate-y-[0%]",
                }
            case "nine":
                return {
                    image: THPStep9,
                    imageCss: " w-[984px] absolute left-[428px] top-[136px]",
                    modalCss:
                        " max-w-[249px] top-[118px] left-[1089px]  right_custom_arrow step8 translate-x-[0%] translate-y-[0%]",
                }

            default:
                return {
                    image: "",
                    imageCss: "",
                    modalCss: "",
                }
        }
    }

    const modalClassNames =
        trackpackHubQuickTour?.stepNumber !== "one"
            ? `max-w-[248px] w-full bg-[#088AB2] rounded-[16px] p-[12px] absolute ${getImageAndCss().modalCss}`
            : ""

    return (
        <>
            {trackpackHubQuickTour?.open ? (
                <>
                    <div className="relative bg-gray-900 h-screen w-screen" id="trackpack-hub">
                        {getImageAndCss().image && (
                            <img
                                src={getImageAndCss().image}
                                alt="PT Step"
                                className={`absolute ${getImageAndCss().imageCss}`}
                            />
                        )}
                        <div
                            className={modalClassNames}
                            style={{
                                boxShadow: "0px 8px 8px -4px #10182808, 0px 20px 24px -4px #10182814",
                            }}
                        >
                            {getTourModal()}
                        </div>
                    </div>
                </>
            ) : null}
        </>
    )
}

export default TrackpackHubTourOutlet
