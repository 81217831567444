export const WarningTooltipIcon = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5 4.00112C5.0881 3.75068 5.26199 3.5395 5.49087 3.40499C5.71975 3.27047 5.98885 3.2213 6.25051 3.26618C6.51217 3.31107 6.7495 3.4471 6.92047 3.6502C7.09144 3.8533 7.18502 4.11036 7.18462 4.37584C7.18462 5.12528 6.06046 5.5 6.06046 5.5M6.07495 7H6.07995M3.5 9V10.1678C3.5 10.4342 3.5 10.5674 3.55461 10.6358C3.60211 10.6953 3.67413 10.7299 3.75027 10.7298C3.83781 10.7297 3.94184 10.6465 4.14988 10.4801L5.34261 9.52592C5.58626 9.33099 5.70808 9.23353 5.84374 9.16423C5.9641 9.10274 6.09221 9.0578 6.22461 9.03063C6.37383 9 6.52985 9 6.84187 9H8.1C8.94008 9 9.36012 9 9.68099 8.83651C9.96323 8.6927 10.1927 8.46323 10.3365 8.18099C10.5 7.86012 10.5 7.44008 10.5 6.6V3.9C10.5 3.05992 10.5 2.63988 10.3365 2.31901C10.1927 2.03677 9.96323 1.8073 9.68099 1.66349C9.36012 1.5 8.94008 1.5 8.1 1.5H3.9C3.05992 1.5 2.63988 1.5 2.31901 1.66349C2.03677 1.8073 1.8073 2.03677 1.66349 2.31901C1.5 2.63988 1.5 3.05992 1.5 3.9V7C1.5 7.46499 1.5 7.69748 1.55111 7.88823C1.68981 8.40587 2.09413 8.81019 2.61177 8.94889C2.80252 9 3.03501 9 3.5 9Z"
                stroke="#B54708"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
