import { useCallback, useEffect, useMemo, useState } from "react"
import ReactDOMServer from "react-dom/server"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { AccountActionsCreater } from "../../../actions/account.action"
import { ProductsActionCreator } from "../../../actions/products.action"
import { RuntimeActionCreators } from "../../../actions/runTime.action"
import { ReactComponent as AddIcon } from "../../../assets/svgs/new-add-icon.svg"
import { RuntimeHelper } from "../../../helpers/runtime.helper"
import { AccountStateSelector } from "../../../selectors/accountState.selector"
import { ProductStateSelector } from "../../../selectors/product.selector"
import { RunTimeStateSelector } from "../../../selectors/RunTimeStateSelector"
import { ButtonElement } from "../../elements/button.element"
import { PrimaryText } from "../../elements/primaryText.element"
import { SelectElement } from "../../elements/select.element"
import { AsinContrastCard } from "../asinCard.component"
import CommonTooltip from "../tooltipItems/commonTooltip.component"

export interface Root {
    is_new_data_available: boolean
}
let getNewData: any = null

export const GroupOptions = () => {
    const { control, watch, setValue } = useForm()
    const dispatch = useDispatch()
    const asinsValue = useMemo(() => watch("asins"), [watch("asins")])
    const { loading } = useSelector(RunTimeStateSelector)
    const isLoading: boolean = useMemo(() => RuntimeHelper.trackpackLoading(), [loading])
    const { userActionData } = useSelector(AccountStateSelector)
    const { selectedOption, selectedDropdownList, selectedGroupProducts } = useSelector(ProductStateSelector)
    const [defaultTrackpack, setDefaultTrackpack] = useState<string | undefined>()
    const [allTrackpacks, setAllTrackpacks] = useState<any>()
    const [selectedIndex, setSelectedIndex] = useState<number>()
    const [amazontld, setAmazontld] = useState<string>()
    let multiSelectProduct = selectedOption !== "Log"

    const storedTrackPackID = localStorage.getItem("selectedTrackpackID")

    const [selectedTrackPackID, setSelectedTrackpackID] = useState<string | null>(storedTrackPackID || null)

    const checkNewProductData = (id: string | null) => {
        if (!id) return

        dispatch(
            ProductsActionCreator.checkNewProductDataAvailable(
                id,
                {},
                { product_id: localStorage.getItem("product_id")! },
                (data: Root) => {
                    if (data?.is_new_data_available)
                        dispatch(
                            RuntimeActionCreators.openNotificaitonAlert({
                                open: true,
                                notificationType: "new_Data",
                                duration: 30000,
                            })
                        )
                }
            )
        )
    }

    useEffect(() => {
        if (getNewData) {
            clearInterval(getNewData)
        }
        getNewData = setInterval(
            () => {
                checkNewProductData(selectedTrackPackID)
            },
            5 * 60 * 1000
        )
        return () => clearInterval(getNewData)
    }, [selectedTrackPackID])

    const debounce = (func: Function, delay: number) => {
        let timeoutId: ReturnType<typeof setTimeout>
        return (...args: any[]) => {
            if (timeoutId) {
                clearTimeout(timeoutId)
            }
            timeoutId = setTimeout(() => {
                func(...args)
            }, delay)
        }
    }

    const debouncedGetProductsByGroupWithUser = useCallback(
        debounce((id: string) => {
            getProductsByGroupWithUser(id)
        }, 500),
        []
    )
    // Effect to set selectedTrackPackID if it doesn't exist in localStorage
    useEffect(() => {
        if (!storedTrackPackID && selectedIndex !== undefined) {
            const newTrackpackID = selectedIndex.toString()
            setSelectedTrackpackID(newTrackpackID)
            localStorage.setItem("selectedTrackpackID", newTrackpackID)
        }
    }, [selectedIndex, storedTrackPackID])
    useEffect(() => {
        // @ts-ignore
        window.updateSelectedTrackpackID = setSelectedTrackpackID
        return () => {
            // @ts-ignore
            delete window.updateSelectedTrackpackID
        }
    }, [])

    useEffect(() => {
        if (userActionData !== undefined) {
            dispatch(ProductsActionCreator.getDropdownList({}, { as_user: userActionData && userActionData?.id + "" }))
        } else dispatch(ProductsActionCreator.getDropdownList())
    }, [dispatch, userActionData])

    useEffect(() => {
        if (isLoading) return
        if (selectedDropdownList?.trackpacks && selectedDropdownList?.trackpacks?.length > 0) {
            const filteredTrackpacks = selectedDropdownList.trackpacks.filter(
                (group: any) => group.metadata.status !== "ARCHIVED"
            )
            const transformedResponse = {
                groups: filteredTrackpacks.map((group: any) => ({
                    name: group.metadata.name,
                    id: group.metadata.id,
                    amazon_tld: group.metadata.amazon_tld,
                })),
            }
            const defaultGroupID = transformedResponse.groups.find((group: any) => group.id == selectedTrackPackID)
            const defaultGroup = defaultGroupID ?? transformedResponse.groups[0]
            setAllTrackpacks(transformedResponse as any)
            setDefaultTrackpack(defaultGroup?.id)
            setSelectedIndex(defaultGroup?.id)
            setAmazontld(defaultGroup?.amazon_tld)
            setValue("asins", defaultGroup?.id)
        } else {
            setValue("asins", undefined)
            setAllTrackpacks([])
            setDefaultTrackpack("")
            setSelectedIndex(undefined)
        }
    }, [selectedDropdownList, setValue, userActionData, isLoading, selectedTrackPackID])

    function hasKey(obj: any, key: string) {
        return obj?.hasOwnProperty(key)
    }

    const getProductsByGroupWithUser = (groupID: string) => {
        const options: { as_user?: string; since?: string } = {}

        const currentDate = new Date()
        const sixMonthsAgo = new Date()
        sixMonthsAgo.setMonth(currentDate.getMonth() - 6)
        const sinceDate = sixMonthsAgo?.toISOString().split("T")[0]

        options.since = sinceDate

        if (userActionData) {
            options.as_user = userActionData.id + ""
        }
        dispatch(ProductsActionCreator.getProductsByGroup(groupID, amazontld, {}, options, false))
    }

    useEffect(() => {
        if (isLoading) return

        if (selectedDropdownList?.trackpacks && selectedDropdownList?.trackpacks?.length > 0 && selectedTrackPackID) {
            if (selectedTrackPackID) {
                if (hasKey(selectedGroupProducts?.groupProductData, selectedTrackPackID)) return
                debouncedGetProductsByGroupWithUser(selectedTrackPackID as string)
                dispatch(
                    ProductsActionCreator.selectedProducts({
                        selectedProducts: [],
                    })
                )
            }
        }
    }, [userActionData, selectedDropdownList?.trackpacks?.length, selectedTrackPackID])

    // useEffect(() => {
    //     if (
    //         selectedDropdownList &&
    //         selectedDropdownList?.trackpacks?.length === 0 &&
    //         getAccessLevel &&
    //         userActionData === undefined
    //     ) {
    //         dispatch(
    //             RuntimeActionCreators.openTrackpackModal({
    //                 open: true,
    //             })
    //         )
    //     }
    // }, [selectedDropdownList, getAccessLevel])

    // useEffect(() => {
    //     const hasWatchedTour = localStorage.getItem("hasWatchedPerformanceTour")
    //     if (
    //         selectedDropdownList &&
    //         selectedDropdownList?.trackpacks?.length === 0 &&
    //         pathname === ROUTES_CONFIG.dashboard &&
    //         !hasWatchedTour
    //     ) {
    //         dispatch(
    //             RuntimeActionCreators.openPerformanceTourModal({
    //                 open: true,
    //                 stepNumber: "one",
    //             })
    //         )
    //     }
    // }, [selectedDropdownList, pathname, dispatch])

    const handleOpen = () => {
        dispatch(
            RuntimeActionCreators.openNewTrackPackAsinModal({
                open: true,
                stepNumber: "one",
            })
        )
    }

    const handleSelectElementChange = (selectedValue: string) => {
        dispatch(
            ProductsActionCreator.selectedProducts({
                selectedProducts: [],
            })
        )
        const index = allTrackpacks.groups.find((group: any) => {
            return group.id === selectedValue
        })
        setSelectedIndex(index?.id)
        localStorage.setItem("selectedTrackpackID", index?.id)
        setSelectedTrackpackID(index?.id)
    }

    // useEffect(() => {
    //     if (selectedIndex !== undefined && allTrackpacks?.groups?.length > 0) {
    //         localStorage.removeItem("defaultSelectedAsin")
    //     }
    // }, [selectedIndex, allTrackpacks])

    useEffect(() => {
        dispatch(AccountActionsCreater.setSelectedTrackpackList(selectedIndex))
    }, [asinsValue])

    return (
        <>
            <div className="flex flex-col gap-[24px] w-[304px]">
                <div className="w-full">
                    <div className="w-full p-[12px] px-3 pt-3 justify-start items-start inline-flex rounded-[16px]  border border-gray-50 bg-gray-50 ">
                        <div className="w-full gap-[12px]  flex flex-col">
                            <div className="flex justify-between ">
                                <div className="flex gap-[6px] items-center">
                                    <PrimaryText
                                        // onClick={handleOpenTrackPack}
                                        weight="medium"
                                        size="sm-medium"
                                        className="text-cyan-500 font- leading-normal pl-[8px]"
                                    >
                                        Trackpacks
                                    </PrimaryText>
                                    {/* <InfoLabel /> */}
                                </div>
                                <ButtonElement
                                    onClick={handleOpen}
                                    viewType="only-icon"
                                    className="p-[10px] bg-gradient-to-br from-cyan-100 to-cyan-200 rounded-[10px] disabled:bg-gray-100
                                    hover:bg-gradient-to-r hover:from-cyan-200 hover:to-cyan-300
                                    active:bg-gradient-to-br active:from-cyan-300 active:to-cyan-400"
                                >
                                    <AddIcon
                                        className="cursor-pointer outline-none"
                                        data-tooltip-id="dashboard-tooltip"
                                        data-tooltip-place="bottom"
                                        data-tooltip-offset={-15}
                                        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                            <CommonTooltip className="ml-[80px] mt-[13px]" label="Add Trackpack" />
                                        )}
                                    />
                                </ButtonElement>
                            </div>
                            <div className="w-[280px] relative">
                                <SelectElement
                                    className="focus:outline focus:outline-cyan-300/30 pr-[11px]"
                                    customBoxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(103, 227, 249, 0.20)"
                                    buttonTextClass="truncate whitespace-nowrap max-w-[220px]"
                                    reactHookControl={control}
                                    type="check"
                                    name="asins"
                                    disabled={isLoading}
                                    defaultValue={defaultTrackpack}
                                    rightCheckBox={true}
                                    isToolitpShow={false}
                                    options={
                                        allTrackpacks &&
                                        allTrackpacks?.groups?.map(
                                            (g: { id: string; name: string; amazon_tld: string }) => {
                                                return {
                                                    value: g?.id,
                                                    label: g?.name,
                                                    amazon_tld: g?.amazon_tld,
                                                }
                                            }
                                        )
                                    }
                                    onChange={handleSelectElementChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <AsinContrastCard multipleProductSelected={multiSelectProduct} />
            </div>
            {/* {(getAccessLevel?.first_name || getAccessLevel?.username) && <TrackPackChatModal />} */}
        </>
    )
}
