import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useProductHistory } from "../../../hooks/productHistory.hook"
import { RunTimeStateSelector } from "../../../selectors/RunTimeStateSelector"
import ArrowModalActions from "../../common/arrowModalActions.component"
import InnerModalHeader from "../../common/innerModalHeader.component"
import { PrimaryText } from "../../elements/primaryText.element"
import { ChartsWrapper } from "./chartsWrapper.component"

const MainImgModal = (data: IDialogOptionsProps) => {
    const { t: tCommon } = useTranslation("tooltip", {
        keyPrefix: "commonLabels",
    })
    const { active, history, setActive, total } = useProductHistory(data?.data as IProductData, "main_image")
    const { modal } = useSelector(RunTimeStateSelector)

    return (
        <>
            <ChartsWrapper
                className={`p-[48px] absolute top-[50%] left-[50%] -translate-y-2/4 -translate-x-2/4 bg-white rounded-[24px] m-auto ${
                    history?.past && history?.past?.value?.length && history?.past && history.present?.value?.length
                        ? "w-[900px]"
                        : "w-[492px]"
                } shadow-modal-shadow border-[1px] border-solid border-[#E7EBEF] transition-all`}
            >
                <InnerModalHeader color={modal?.color} type="main_image" />
                <PrimaryText className="text-[#344054] text-[24px] font-[500] leading-[32px] mb-[4px] ml-[6px]">
                    Main Image
                </PrimaryText>
                <div className={` ${total > 1 ? "ml-[8px] flex items-center gap-[10px]" : "ml-[6px]"}`}>
                    <ArrowModalActions total={total} active={active} setActive={setActive} />

                    <PrimaryText className="text-[12px] uppercase leading-[12px] text-[#667085] font-[400]">
                        {tCommon("timestamp", {
                            timestamp: dayjs(history.present?.timestamp).format("MMM-DD-YYYY @ HH:mm [GMT]"),
                        })}
                    </PrimaryText>
                </div>
                <div className="flex gap-[12px] mt-[40px]">
                    {/* for past history  */}
                    {history.past && (
                        <div className="bg-[#F2F4F7] rounded-[16px] p-4">
                            <div className="w-[32px] py-[5.5px] px-[6px] bg-[#F04438] text-white rounded-[6px] mb-[16px] text-[10px] leading-[9px] font-[500] flex items-center justify-center">
                                {tCommon("was")}
                            </div>
                            <div className="w-[364px] h-[364px] overflow-hidden border border-gray-200 rounded-[8px]">
                                <img
                                    className="w-full h-full object-contain bg-gray-800"
                                    src={history?.past?.value}
                                    alt=""
                                />
                            </div>
                        </div>
                    )}
                    {history.present && (
                        <div className="bg-[#F2F4F7] p-4 rounded-[16px]">
                            <div className="w-[32px] py-[5.5px] px-[6px] text-white bg-[#47CD89] rounded-[6px] mb-[16px] text-[10px] leading-[9px] font-[500] flex items-center justify-center">
                                {tCommon("now")}
                            </div>
                            <div className="w-[364px] h-[364px] overflow-hidden border border-gray-200 rounded-[8px]">
                                <img
                                    className="w-full h-full object-contain bg-gray-800"
                                    src={history?.present?.value}
                                    alt=""
                                />
                            </div>
                        </div>
                    )}
                </div>
            </ChartsWrapper>
        </>
    )
}

export default MainImgModal
