import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AccountActionsCreater } from "../../../actions/account.action"
import { ProductsActionCreator } from "../../../actions/products.action"
import { AccountNewIcon } from "../../../assets/svgs/account-Icon"
import { LogoutIcon } from "../../../assets/svgs/logoutIcon.svg"
import { getNameInitials, monthYearFormat } from "../../../helpers/util.helper"
import { AccountStateSelector } from "../../../selectors/accountState.selector"
import CustomTable from "../../common/customTable.component"
import { ButtonElement } from "../../elements/button.element"
import PaginationElement from "../../elements/tablePagination.element"

let apiCall: any = null
const recordsPerPage = 8

export const UserAccountsTable = () => {
    const headItems = [
        { name: "", width: "84px" },
        { name: "Name", width: "210px" },
        { name: "Email Address", width: "240px" },
        { name: "Member Since", width: "180px" },
        { name: "", width: "398px" },
    ]
    const columns = ["initials", "name", "email", "member", ""]
    const dispatch = useDispatch()

    const { data, userActionData, searchData } = useSelector(AccountStateSelector)
    useEffect(() => {
        if (apiCall) clearTimeout(apiCall)
        apiCall = setTimeout(() => {
            dispatch(AccountActionsCreater.getUserAccounts())
        }, 2000)

        return () => {
            clearTimeout(apiCall)
        }
    }, [])

    useEffect(() => {
        if (userActionData !== undefined) {
            localStorage.removeItem("selectedTrackpackID")
        }
    }, [userActionData])

    const handleLogoutAsUser = () => {
        dispatch(AccountActionsCreater.setUserAccountDataEmpty())
        dispatch(ProductsActionCreator.setDropdownList({} as IProductDropdownList))
        dispatch(ProductsActionCreator.storeProducts({} as IGroupProductDataResponse))
    }

    const totalPages = Math.ceil(data.length / recordsPerPage)
    const [currentPage, setCurrentPage] = useState<number>(userActionData?.page ?? 0)

    const handlePageChange = (page: number) => {
        setCurrentPage(page)
    }
    const startIndex = currentPage * recordsPerPage
    const currentData = useMemo(() => {
        return Array.isArray(searchData) && searchData.length > 0
            ? searchData.slice(startIndex, startIndex + recordsPerPage)
            : []
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchData, startIndex, recordsPerPage])

    const rowsData = useMemo(() => {
        return Array.isArray(currentData) && currentData.length > 0
            ? currentData.map((row: any) => ({
                  ...row,
                  initials: (
                      <p className="bg-gray-600 w-9 h-9 rounded-[10px] pt-[2px] text-gray-200 flex justify-center items-center uppercase">
                          {getNameInitials(row.first_name, row.last_name)}
                      </p>
                  ),
                  name: `${row.first_name} ${row.last_name}`,
                  member: `${monthYearFormat(row.date_joined)}`,
              }))
            : []
    }, [currentData])

    return (
        <>
            <div className="overflow-auto -mr-[30px]" style={{ height: "calc(100vh - 344px)" }}>
                <div
                    className="relative"
                    style={{
                        width: currentData?.length < 5 ? "calc(100% - 30px)" : "calc(100% - 25px)",
                    }}
                >
                    <CustomTable
                        rows={rowsData}
                        headItems={headItems}
                        columns={columns}
                        loading={false}
                        actions={(row) => [
                            <ButtonElement
                                onClick={() => {
                                    if (userActionData && row.id === userActionData.id) {
                                        handleLogoutAsUser()
                                        localStorage.removeItem("currentPage")
                                    } else {
                                        dispatch(
                                            AccountActionsCreater.setUserAccountsActionsData({
                                                id: row.id,
                                                email: row.email,
                                                member: row.member,
                                                name: row.name,
                                                page: currentPage,
                                            })
                                        )
                                        dispatch(ProductsActionCreator.setDropdownList({} as IProductDropdownList))
                                        dispatch(ProductsActionCreator.storeProducts({} as IGroupProductDataResponse))
                                    }
                                }}
                                size="small"
                                viewType="post-icon"
                                className={`px-[8px] py-[4px] w-[130px] rounded-[4px] font-[500] ${
                                    userActionData && row.id === userActionData.id
                                        ? "bg-[#7A5AF8] text-[#F4F3FF]"
                                        : "bg-[#F4F3FF] text-[#7A5AF8] border border-[#BDB4FE]"
                                }    `}
                                postIcon={
                                    userActionData && row.id === userActionData.id ? <LogoutIcon /> : <AccountNewIcon />
                                }
                            >
                                {userActionData && row.id === userActionData.id ? "Log Out as User" : "Control as User"}
                            </ButtonElement>,
                        ]}
                    />
                </div>
            </div>

            {data.length > recordsPerPage && (
                <PaginationElement currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
            )}
        </>
    )
}
