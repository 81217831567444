import Icon from "../../../../../assets/gifs/amaizing-icon.gif"
import { TourCloseIcon } from "../../../../../assets/svgs/tourCloseIcon.svg"
import { ButtonElement } from "../../../../elements/button.element"
import { PrimaryText } from "../../../../elements/primaryText.element"
const PtQuickTourStepThree: React.FC<PerformanceTrackerQuickTourProps> = ({
    headerText,
    currentStep,
    handleCloseDialog,
    handleNext,
    totalSteps,
}) => {
    return (
        <>
            {/* Header */}
            <div className="flex justify-between items-center">
                <div className="flex items-center space-x-2">
                    <img src={Icon} className="h-[26px] w-[28px]" alt="icon" />

                    <PrimaryText size="small" weight="medium" className="text-white">
                        {headerText}
                    </PrimaryText>
                </div>
                {handleCloseDialog && (
                    <div onClick={handleCloseDialog} className="cursor-pointer">
                        <TourCloseIcon />
                    </div>
                )}
            </div>

            {/* Content */}
            <div className="mt-[26px] p-[12px] bg-[#06AED4] rounded-[8px]">
                <PrimaryText weight="book" size="xs" className="text-white mb-[10px]">
                    All ASINs in a Trackpack are displayed here. You’ll see all of the live data for each ASIN within
                    the ASIN card. To hide an ASIN from view, simply click the eye symbol. To see it displayed again,
                    just click the eye symbol again.
                </PrimaryText>
                <PrimaryText weight="book" size="xs" className="text-white mb-[10px]">
                    To easily identify one ASIN from another, each card is highlighted with a color that matches to that
                    ASIN’s colored line in the graph.
                </PrimaryText>

                <PrimaryText weight="book" size="xs" className="text-white">
                    To add new&nbsp;
                    <PrimaryText size="xs" className="text-white inline font-[700]">
                        ASINs,&nbsp;
                    </PrimaryText>
                    simply click on the plus symbol and follow the
                    <br /> 2-step instructions.
                </PrimaryText>
                {/* Footer */}
                <div className="flex justify-between items-center mt-[24px]">
                    <PrimaryText weight="book" size="xs-medium" className="text-white">
                        {currentStep} / {totalSteps}
                    </PrimaryText>
                    <ButtonElement
                        size="small"
                        textClass="text-[500] text-[12px] text-white"
                        className="!w-[42px] !h-[26px] px-[8px] py-[4px] !bg-none text-white rounded-[6px] !hover:bg-transparent border border-white"
                        onClick={handleNext}
                    >
                        Next
                    </ButtonElement>
                </div>
            </div>
        </>
    )
}

export default PtQuickTourStepThree
