export const IndicatorIcon = (props: IArrowProps) => {
    return (
        <svg
            className="cursor-pointer"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                className="mr-10"
                d="M1.33325 5.99992H10.6666M10.6666 5.99992L5.99992 1.33325M10.6666 5.99992L5.99992 10.6666"
                stroke="#98A2B3"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
