import { all, fork, put, takeLatest } from "redux-saga/effects"
import { call, select } from "typed-redux-saga"

import { AuthAction, AuthActionCreator } from "../actions/auth.action"
import { RuntimeActionCreators } from "../actions/runTime.action"
import { ROUTES_CONFIG } from "../config/routes.config"
import { AuthStateSelector } from "../selectors/authState.selector"
import { AuthService } from "../services/auth.service"
import { handleErrorResponse } from "./onBoarding.saga"

/////////////////////////// -- GET Token -- ///////////////////////////
function* getTokenSaga() {
    yield takeLatest(AuthAction.GET_TOKEN, getTokenSagaHandler)
}
function* getTokenSagaHandler<T extends ISagaAction & { payload: { username: string; password: string } }>(action: T) {
    try {
        // Dispatch a start loading action
        yield put(RuntimeActionCreators.startLoading("getTokenSagaHandler", !action.ignorePreloader))

        const result = yield* call(AuthService.getToken, action.payload)

        if ("message" in result) {
            const messageResult = result as { message: string }
            action.onError && action.onError(messageResult)
        } else {
            yield put(AuthActionCreator.storeToken(result))
            action.onSuccess && action.onSuccess(result)
        }
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("getTokenSagaHandler"))

        action.onFinally && action.onFinally()
    }
}

/////////////////////////// -- Get Token End -- ///////////////////////////

/////////////////////////// -- Get Guest Account -- ///////////////////////////
function* getGuestAccountSaga() {
    yield takeLatest(AuthAction.GET_GUEST_ACCOUNT, getGuestAccountSagaHandler)
}

function* getGuestAccountSagaHandler<T extends ISagaAction & { payload: { id: string } }>(action: T) {
    try {
        // Dispatch a start loading action
        yield put(RuntimeActionCreators.startLoading("getGuestAccountSagaHandler", !action.ignorePreloader))

        const result = yield* call(AuthService.getGuessAccount, {
            guest_id: action.payload.id,
        })

        if (result) {
            yield put(AuthActionCreator.storeGuest(result))
        } else {
            action.onError && action.onError(result)
        }
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("getGuestAccountSagaHandler"))

        action.onFinally && action.onFinally()
    }
}

/////////////////////////// -- End Get Guest Account -- ///////////////////////////

/////////////////////////// -- Create Guest Account -- ///////////////////////////

function* createGuestAccountSaga() {
    yield takeLatest(AuthAction.CREATE_GUEST_ACCOUNT, createGuestAccountSagaHandler)
}

function* createGuestAccountSagaHandler<T extends ISagaAction & { payload: Partial<IGuestUser> }>(action: T) {
    try {
        // Dispatch a start loading action
        yield put(RuntimeActionCreators.startLoading("createGuestAccountSagaHandler", !action.ignorePreloader))

        const result = yield* call(AuthService.postGuestAccount, action.payload)

        if (result) {
            yield put(AuthActionCreator.storeGuest(result))
        } else {
            action.onError && action.onError(result)
        }
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("createGuestAccountSagaHandler"))

        action.onFinally && action.onFinally()
    }
}

/////////////////////////// -- End Create Guest Account -- ///////////////////////////

/////////////////////////// -- Update Asin User -- //////////////////////////////////

function* updateAsinUserSaga() {
    yield takeLatest(AuthAction.UPDATE_ASIN_USER, updateAsinUserSagaHandler)
}

function* updateAsinUserSagaHandler<T extends ISagaAction & { payload: Partial<IAsinUser> }>(action: T) {
    try {
        // Dispatch a start loading action
        yield put(RuntimeActionCreators.startLoading("updateAsinUserSagaHandler", !action.ignorePreloader))

        const result = yield* call(AuthService.updateAsinUser, action.payload)

        if (result) {
            yield put(AuthActionCreator.storeGuest(result))
        } else {
            action.onError && action.onError(result)
        }
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("putGuestAccountSagaHandler"))

        action.onFinally && action.onFinally()
    }
}

/////////////////////////// --End Update Asin User -- ///////////////////////////

/////////////////////////// -- Update Guest Account -- ///////////////////////////
function* updateGuestAccountSaga() {
    yield takeLatest(AuthAction.UPDATE_GUEST_ACCOUNT, updateGuestAccountSagaHandler)
}

function* updateGuestAccountSagaHandler<T extends ISagaAction & { payload: Partial<IGuestUser> }>(action: T) {
    try {
        // Dispatch a start loading action
        yield put(RuntimeActionCreators.startLoading("updateGuestAccountSagaHandler", !action.ignorePreloader))

        const result = yield* call(AuthService.updateGuestAccount, action.payload)

        if (result) {
            yield put(AuthActionCreator.storeGuest(result))
        } else {
            action.onError && action.onError(result)
        }
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("patchGuestAccountSagaHandler"))

        action.onFinally && action.onFinally()
    }
}

/////////////////////////// -- End Update Guest Account -- ///////////////////////////

/////////////////////////// -- GET Access Level -- ///////////////////////////

function* getAccessLevelSaga() {
    yield takeLatest(AuthAction.GET_ACCESS_LEVEL, getAccessLevelSagaHandler)
}

function* getAccessLevelSagaHandler<T extends ISagaAction>(action: T) {
    try {
        // Dispatch a start loading action
        yield put(RuntimeActionCreators.startLoading("getAccessLevelSagaHandler", !action.ignorePreloader))

        const result = yield* call(AuthService.getAccessLevel, action.payload)

        if (result) {
            yield put(AuthActionCreator.setAccessLevel(result as IGetAccess)) // Dispatch success action
        } else {
            action.onError && action.onError(result)
        }
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("getAccessLevelSagaHandler"))
        action.onFinally && action.onFinally()
    }
}

/////////////////////////// -- End GET Access Level -- ///////////////////////////

/////////////////////////// -- Start GET Refresh Token -- ///////////////////////////
export function* watchForRefresh() {
    yield takeLatest(AuthAction.REFRESH_TOKEN, refreshTokenSaga)
}

function* refreshTokenSaga<T extends ISagaAction>(action: T) {
    try {
        const refreshToken = yield* select(AuthStateSelector)
        const response = yield* call(AuthService.refreshTokensApi, refreshToken?.token?.refresh)
        if (response?.access && response?.refresh) {
            yield put(AuthActionCreator.refreshTokenSuccessAction(response as any))
            for (let i = 0; i < action?.payload?.length; i++) {
                yield put(action.payload[i])
            }
            action.onSuccess && action.onSuccess()
        } else {
            localStorage.clear()
            window.location.href = ROUTES_CONFIG.welcome
        }
    } catch (error) {
        localStorage.clear()
        window.location.href = ROUTES_CONFIG.welcome
    }
}

/////////////////// USER SIGNUP //////////////////////

function* creatUserSignup() {
    yield takeLatest(AuthAction.USER_SIGNUP, createUserSignupSagaHandler)
}

function* createUserSignupSagaHandler<T extends ISagaAction & { payload: ISignupInputProps }>(action: T) {
    try {
        // Dispatch a start loading action
        // yield put(RuntimeActionCreators.startLoading("createGuestAccountSagaHandler", !action.ignorePreloader))

        const result = yield* call(AuthService.userSignupPost, action.payload)

        if (result.status === 200) {
            action.onSuccess && action.onSuccess()
        } else {
            yield* handleErrorResponse(result, action)
        }
    } catch (error) {
    } finally {
        // yield put(RuntimeActionCreators.stopLoading("createGuestAccountSagaHandler"))

        action.onFinally && action.onFinally()
    }
}

///////////////////////// Verify SIGNUP Email  //////////////////////

function* verifySignupEmail() {
    yield takeLatest(AuthAction.VERIFY_SIGNUP_EMAIL, verifySignupEmailSagaHandler)
}

function* verifySignupEmailSagaHandler<T extends ISagaAction & { payload: Partial<IGuestUser> }>(action: T) {
    try {
        // Dispatch a start loading action
        // yield put(RuntimeActionCreators.startLoading("createGuestAccountSagaHandler", !action.ignorePreloader))

        const result = yield* call(AuthService.verifyEmailPost, action.payload)

        if (result) {
            action.onSuccess && action.onSuccess()
        } else {
            action.onError && action.onError(result)
        }
    } catch (error) {
    } finally {
        // yield put(RuntimeActionCreators.stopLoading("createGuestAccountSagaHandler"))

        action.onFinally && action.onFinally()
    }
}

/////////////////////////rest password/////////////////////////

function* userResetPassword() {
    yield takeLatest(AuthAction.RECOVER_PASSWORD, resetPasswordHandler)
}

function* resetPasswordHandler<T extends ISagaAction & { payload: { password: string } }>(action: T) {
    try {
        const result = yield* call(AuthService.resetPasswordPost, action.payload)

        if (result.status === 200) {
            action.onSuccess && action.onSuccess()
        } else {
            yield* handleErrorResponse(result, action)
        }

        if (result) {
            // yield put(AuthActionCreator.storeGuest(result))
        } else {
            action.onError && action.onError(result)
        }
    } catch (error) {
    } finally {
        action.onFinally && action.onFinally()
    }
}

function* verifyPasswordEmail() {
    yield takeLatest(AuthAction.VERIFY_PASSWORD_EMAIL, verifyPasswordEmailSagaHandler)
}

function* verifyPasswordEmailSagaHandler<T extends ISagaAction & { payload: Partial<IGuestUser> }>(action: T) {
    try {
        // Dispatch a start loading action
        // yield put(RuntimeActionCreators.startLoading("createGuestAccountSagaHandler", !action.ignorePreloader))

        const result = yield* call(AuthService.verifyPasswordPost, action.payload)

        if (result.status === 200) {
            action.onSuccess && action.onSuccess()
        } else {
            action.onError && action.onError(result)
        }
    } catch (error) {
    } finally {
        // yield put(RuntimeActionCreators.stopLoading("createGuestAccountSagaHandler"))

        action.onFinally && action.onFinally()
    }
}

// Resend verify Email

function* resendVerifySignupEmail() {
    yield takeLatest(AuthAction.RESEND_VERIFY_SIGNUP_EMAIL, resendVerifySignupEmailSagaHandler)
}

function* resendVerifySignupEmailSagaHandler<T extends ISagaAction & { payload: Partial<IGuestUser> }>(action: T) {
    try {
        // Dispatch a start loading action
        // yield put(RuntimeActionCreators.startLoading("createGuestAccountSagaHandler", !action.ignorePreloader))

        const result = yield* call(AuthService.resendVerifyEmailPost, action.payload)

        if (result) {
            action.onSuccess && action.onSuccess()
        } else {
            action.onError && action.onError(result)
        }
    } catch (error) {
    } finally {
        // yield put(RuntimeActionCreators.stopLoading("createGuestAccountSagaHandler"))

        action.onFinally && action.onFinally()
    }
}

export default function* rootSaga() {
    yield all([
        fork(getGuestAccountSaga),
        fork(createGuestAccountSaga),
        fork(updateAsinUserSaga),
        fork(updateGuestAccountSaga),
        fork(getTokenSaga),
        fork(getAccessLevelSaga),
        fork(watchForRefresh),
        fork(creatUserSignup),
        fork(userResetPassword),
        fork(verifySignupEmail),
        fork(verifyPasswordEmail),
        fork(resendVerifySignupEmail),
    ])
}
