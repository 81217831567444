export enum AccountAction {
    GET_USER_ACCOUNTS = "GET_USER_ACCOUNTS",
    SET_USER_ACCOUNTS = "SET_USER_ACCOUNTS",
    SET_USER_ACCOUNTS_ACTIONS_DATA = "SET_USER_ACCOUNTS_ACTIONS_DATA",
    SET_USER_ACCOUNTS_ACTIONS_DATA_EMPTY = "SET_USER_ACCOUNTS_ACTIONS_DATA_EMPTY",
    GET_TRACKPACK_DATA = "GET_TRACKPACK_DATA",
    SET_TRACKPACK_DATA = "SET_TRACKPACK_DATA",
    SELECTED_TRACKPACKS = "SELECTED_TRACKPACKS",
    SELECTED_ASINS = "SELECTED_ASINS",
    TRACKPACK_TYPE = "TRACKPACK_TYPE",
    SET_SEARCH_DATA = "SET_SEARCH_DATA",
}

export const AccountActionsCreater = {
    getUserAccounts: () => {
        return {
            type: AccountAction.GET_USER_ACCOUNTS,
        }
    },
    setUserAccounts: (data: IAccountUser[]) => ({
        type: AccountAction.SET_USER_ACCOUNTS,
        payload: data,
    }),
    setUserAccountsActionsData: (payload: IAccountActionsUser) => {
        localStorage.setItem("userActionData", JSON.stringify(payload))
        return {
            type: AccountAction.SET_USER_ACCOUNTS_ACTIONS_DATA,
            payload,
        }
    },
    setUserAccountDataEmpty: () => {
        localStorage.removeItem("userActionData")
        return {
            type: AccountAction.SET_USER_ACCOUNTS_ACTIONS_DATA_EMPTY,
        }
    },
    getTrackpacksList: () => {
        return {
            type: AccountAction.GET_TRACKPACK_DATA,
        }
    },
    setAllTrackpackData: (data: any) => ({
        type: AccountAction.SET_TRACKPACK_DATA,
        payload: data,
    }),
    setSelectedTrackpackList: (data: any) => ({
        type: AccountAction.SELECTED_TRACKPACKS,
        payload: data,
    }),
    setSelectedAsinsLength: (data: number, selectedRows?: string[]) => ({
        type: AccountAction.SELECTED_ASINS,
        payload: { data, selectedRows },
    }),
    setTrackpackType: (data: string) => ({
        type: AccountAction.TRACKPACK_TYPE,
        payload: data,
    }),
    setSearchData: (data: IAccountUser[]) => ({
        type: AccountAction.SET_SEARCH_DATA,
        payload: data,
    }),
}
