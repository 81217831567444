export const ContactIconSidebar = (props: IArrowProps) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.5724 7.49984C7.76832 6.94289 8.15502 6.47326 8.66403 6.17411C9.17303 5.87497 9.77148 5.76562 10.3534 5.86543C10.9353 5.96524 11.4631 6.26777 11.8433 6.71944C12.2235 7.17111 12.4316 7.74277 12.4307 8.33317C12.4307 9.99984 9.93073 10.8332 9.93073 10.8332V12.0832M9.9974 14.1665H10.0057M18.3307 9.99984C18.3307 14.6022 14.5998 18.3332 9.9974 18.3332C5.39502 18.3332 1.66406 14.6022 1.66406 9.99984C1.66406 5.39746 5.39502 1.6665 9.9974 1.6665C14.5998 1.6665 18.3307 5.39746 18.3307 9.99984Z"
                stroke={props.color}
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
