import Icon from "../../../../../assets/gifs/amaizing-icon.gif"
import { TourCloseIcon } from "../../../../../assets/svgs/tourCloseIcon.svg"
import { ButtonElement } from "../../../../elements/button.element"
import { PrimaryText } from "../../../../elements/primaryText.element"
const ThQuickTourStepSeven: React.FC<PerformanceTrackerQuickTourProps> = ({
    headerText,
    icon,
    handleCloseDialog,
    handleNext,
    image,
}) => {
    return (
        <>
            {/* <div className="relative bg-gray-900 h-screen" id="trackpack-hub">
                <img
                    src={image}
                    alt="PT Step Three"
                    className=" svg_image w-[1308px] h-[800px] absolute left-[104px] top-[136px]"
                />
                <div
                    className=" max-w-[248px] w-full bg-[#088AB2] rounded-[16px]  p-[12px] absolute top-[145px] left-[858px] right_custom_arrow step7 translate-x-[0%] translate-y-[0%]"
                    style={{
                        boxShadow: "0px 8px 8px -4px #10182808, 0px 20px 24px -4px #10182814",
                    }}
                > */}
            {/* Header */}
            <div className="flex justify-between items-center">
                <div className="flex items-center space-x-2">
                    <img src={Icon} className="h-[26px] w-[28px]" alt="icon" />
                    <PrimaryText size="small" weight="medium" className="text-white">
                        {headerText}
                    </PrimaryText>
                </div>
                {handleCloseDialog && (
                    <div onClick={handleCloseDialog} className="cursor-pointer">
                        <TourCloseIcon />
                    </div>
                )}
            </div>

            {/* Content */}
            <div className="mt-[26px] p-[12px] bg-[#06AED4] rounded-[8px]">
                <PrimaryText weight="book" size="xs" className="text-white mb-[10px]">
                    If tracking is all good for an ASIN,
                    <br /> I’ll show a green tick in the ‘Status’ column of the Trackpack tables. Sometimes though, I
                    can’t retrieve data for ASINs for these 4 reasons:
                    <br />
                    <ul className="list-decimal	pl-[22px] my-[10px]">
                        <li className="mb-[2px]">
                            <PrimaryText weight="book" size="xs" className="text-white">
                                Data is unavailable;
                            </PrimaryText>
                        </li>
                        <li>
                            <PrimaryText weight="book" size="xs" className="text-white">
                                A different ASIN is returned;
                            </PrimaryText>
                        </li>
                        <li>
                            <PrimaryText weight="book" size="xs" className="text-white">
                                A child ASIN is returned; or
                            </PrimaryText>
                        </li>
                        <li>
                            <PrimaryText weight="book" size="xs" className="text-white">
                                The ASIN returned has a different BSR L Cat. to the one provided.
                            </PrimaryText>
                        </li>
                    </ul>
                    You’ll be alerted to any tracking errors as I highlight the Trackpack Cards in yellow and display a
                    yellow warning symbol next to the ASIN in Trackpack tables. Hovering over these symbols reveals what
                    the issue is and suggested actions you can take to resolve.
                </PrimaryText>
                {/* Footer */}
                <div className="flex justify-between items-center mt-[24px]">
                    <PrimaryText weight="book" size="xs-medium" className="text-white">
                        7 / 9
                    </PrimaryText>
                    <ButtonElement
                        size="small"
                        textClass="text-[500] text-[12px] text-white"
                        className="!w-[42px] !h-[26px] px-[8px] py-[4px] !bg-none text-white rounded-[6px] !hover:bg-transparent border border-white"
                        onClick={handleNext}
                    >
                        Next
                    </ButtonElement>
                </div>
            </div>
            {/* </div>
            </div> */}
        </>
    )
}

export default ThQuickTourStepSeven
