import { TourCloseIcon } from "../../../../../assets/svgs/tourCloseIcon.svg"

import Icon from "../../../../../assets/gifs/amaizing-icon.gif"
import { ButtonElement } from "../../../../elements/button.element"
import { PrimaryText } from "../../../../elements/primaryText.element"
const ThQuickTourStepFive: React.FC<PerformanceTrackerQuickTourProps> = ({
    headerText,
    icon,
    handleCloseDialog,
    handleNext,
    image,
}) => {
    return (
        <>
            {/* <div className="relative bg-gray-900 h-screen" id="trackpack-hub">
                <img src={image} alt="PT Step One" className="  w-[984px] absolute left-[428px] top-[136px]" />

                <div
                    className=" mx-auto max-w-[248px] bg-[#088AB2] rounded-[16px] p-[12px] absolute top-[84px] left-[167px] right_custom_arrow step4"
                    style={{
                        boxShadow: "0px 8px 8px -4px #10182808, 0px 20px 24px -4px #10182814",
                    }}
                > */}
            {/* Header */}
            <div className="flex justify-between items-center mb-[26px]">
                <div className="flex items-center space-x-2">
                    <img src={Icon} className="h-[26px] w-[28px]" alt="icon" />
                    <PrimaryText size="small" weight="medium" className="text-white leading-[14px]">
                        {headerText}
                    </PrimaryText>
                </div>
                {handleCloseDialog && (
                    <div onClick={handleCloseDialog} className="cursor-pointer">
                        <TourCloseIcon />
                    </div>
                )}
            </div>

            {/* Content */}
            <div className="mt-[12px] p-[12px] bg-[#06AED4] rounded-[8px]">
                <PrimaryText weight="book" size="xs" className="text-white">
                    You can change the name of a Trackpack at any time. Just click <br /> the edit icon to the right.
                </PrimaryText>

                {/* Footer */}
                <div className="flex justify-between items-center mt-[24px]">
                    <PrimaryText weight="book" size="xs-medium" className="text-white">
                        5 / 9
                    </PrimaryText>
                    <ButtonElement
                        size="small"
                        textClass="text-[500] text-[12px] text-white"
                        className="!w-[42px] !h-[26px] px-[8px] py-[4px] !bg-none text-white rounded-[6px] !hover:bg-transparent border border-white"
                        onClick={handleNext}
                    >
                        Next
                    </ButtonElement>
                </div>
            </div>
            {/* </div>
            </div> */}
        </>
    )
}

export default ThQuickTourStepFive
