import { CustomTooltip } from "../common/tooltip.component"

export const TooltipGallery = (props: { selectedView: "Graph" | "Table" | "Log" }) => {
    return (
        <>
            <CustomTooltip selectedView={props.selectedView} key="price-tooltip" id="price-tooltip" />

            <CustomTooltip selectedView={props.selectedView} key="stock-tooltip" id="stock-tooltip" />

            <CustomTooltip selectedView={props.selectedView} key="actual_asin-tooltip" id="actual_asin-tooltip" />
            <CustomTooltip selectedView={props.selectedView} key="description-tooltip" id="description-tooltip" />
            <CustomTooltip selectedView={props.selectedView} key="bullets-tooltip" id="bullets-tooltip" />
            <CustomTooltip selectedView={props.selectedView} key="title-tooltip" id="title-tooltip" />
            <CustomTooltip
                selectedView={props.selectedView}
                key="carousel_images-tooltip"
                id="carousel_images-tooltip"
            />
            <CustomTooltip selectedView={props.selectedView} key="main_image-tooltip" id="main_image-tooltip" />
            <CustomTooltip selectedView={props.selectedView} key="videos-tooltip" id="videos-tooltip" />
            <CustomTooltip selectedView={props.selectedView} key="seller_info-tooltip" id="seller_info-tooltip" />
        </>
    )
}
