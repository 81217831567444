import dayjs from "dayjs"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { CartesianGrid, DotProps, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"

import { ASIN_PRODUCTS_COLOR } from "../../../../config/colors.config"
import { useGraphData } from "../../../../hooks/graphData.hook"
import { ProductStateSelector } from "../../../../selectors/product.selector"
import { PrimaryText } from "../../../elements/primaryText.element"
import { GraphTooltip } from "./graphTooltip.component"

import { CustomDotSvg } from "../../../../assets/svgs/customDot.svg"
import { GraphSkeleton } from "../../../../assets/svgs/graph-skeleton.svg"
import { RuntimeHelper } from "../../../../helpers/runtime.helper"
import { AuthStateSelector } from "../../../../selectors/authState.selector"
import { RunTimeStateSelector } from "../../../../selectors/RunTimeStateSelector"
import GraphSkeletonWithoutTrackpacks from "../../../common/skeletonLoader/graphSkeletonComponent"

export const GraphView = ({ infoVisible, selectedRange }: IGraphViewProps) => {
    const tooltip = useRef<HTMLDivElement>(null)

    const [value, setValue] = useState<ISelectedProduct>()
    const [tooltipVisible, setTooltipVisible] = useState(false)

    const { selectedProductResult, selectedProducts, selectedProductView, selectedDropdownList } =
        useSelector(ProductStateSelector)
    const { rangeDataWithFilledGaps, rangeData, selectedResults, graphData } = useGraphData()
    const { loading } = useSelector(RunTimeStateSelector)
    const isLoading: boolean = useMemo(() => RuntimeHelper.productLoading(), [loading])
    const isTrackPackLoading: boolean = useMemo(() => RuntimeHelper.trackpackLoading(), [loading])
    const { getAccessLevel } = useSelector(AuthStateSelector)

    useEffect(() => {
        over()
    }, [rangeDataWithFilledGaps, selectedProductView])

    const customMouseClick = useCallback(
        (e: IDotProps) => {
            setTooltipVisible(false)
            setTimeout(() => {
                if (tooltip.current) {
                    setTooltipVisible(true)

                    if (e.payload.from === "error") {
                        tooltip.current.style.top = `${e.cy! - 15}px`
                        tooltip.current.style.left = `${e.cx! - 0}px`
                    } else if (e.payload.from === "alert") {
                        tooltip.current.style.top = `${e.cy! - 15}px`
                        tooltip.current.style.left = `${e.cx! - 1}px`
                    } else {
                        tooltip.current.style.top = `${e.cy! - 12}px`
                        tooltip.current.style.left = `${e.cx! - 0.85}px`
                    }

                    try {
                        let currencyData
                        for (let data of graphData) {
                            const currencyData = data[e.payload.id!]?.currency?.filter(
                                (item: { value: string }) =>
                                    item.value !== "" && item.value !== null && item.value !== undefined
                            )

                            if (currencyData && currencyData[0] && currencyData[0].value) {
                                // @ts-ignore
                                e.payload.product.currency = currencyData
                                break
                            }
                        }
                        // @ts-ignore
                        if (!currencyData || !currencyData[0] || !currencyData[0].value) {
                            const fallbackCurrency =
                                selectedProducts && selectedProducts?.selectedProducts[0]?.main_product_data?.currency
                            if (fallbackCurrency) {
                                // @ts-ignore
                                e.payload.product.currency = fallbackCurrency
                            } else {
                                console.log("No currency data found in graphData or selectedProducts.")
                            }
                        }
                    } catch (error) {}
                    setValue(e.payload)
                }
            }, 300)
        },

        [graphData]
    )

    const DotView = (props: any & { product: IGroupProductData }) => {
        const product = props.product
        const productData: IProductData = props.payload[product?.actual_asin]
        // const isSuccessWarning = productData && productData.warnings?.includes("success")
        // const val = selectedProductResult?.selectedResults?.value ?? "bsr_large"

        // const isWarning = productData?.warnings?.includes(
        //     val as "bsr_large" | "bsr_small" | "ratings_count" | "rating" | "actual_asin"
        // )

        // if (productData && productData.warnings) {
        //     return (
        //         <>
        //             {isSuccessWarning ? (
        //                 <CustomErrorSvg
        //                     customMoveClick={customMouseClick}
        //                     color={product.color}
        //                     id={product.actual_asin}
        //                     initialTimestamp={graphData ? graphData[0]?.timestamp : ""}
        //                     {...props}
        //                 />
        //             ) : (
        //                 isWarning && (
        //                     <CustomAlertSVG
        //                         customMoveClick={customMouseClick}
        //                         color={product.color}
        //                         id={product.actual_asin}
        //                         initialTimestamp={graphData ? graphData[0]?.timestamp : ""}
        //                         {...props}
        //                     />
        //                 )
        //             )}
        //         </>
        //     )
        // }

        return (
            <CustomDotSvg
                customMoveClick={customMouseClick}
                color={product?.color}
                id={product?.actual_asin}
                initialTimestamp={product ? product?.product_data?.[0]?.timestamp : ""}
                {...props}
            />
        )
    }

    const over = () => {
        setTooltipVisible(false)
    }

    const isTourwatched = getAccessLevel && getAccessLevel?.user_settings?.initial_qt_completed === true

    return (
        <>
            {isLoading || isTrackPackLoading ? (
                <GraphSkeleton />
            ) : selectedDropdownList?.trackpacks?.length === 0 && isTourwatched ? (
                <GraphSkeletonWithoutTrackpacks />
            ) : selectedDropdownList?.trackpacks?.length === 0 && !isTourwatched ? (
                <GraphSkeleton />
            ) : (
                <div
                    className={`rounded-[10px] border border-gray-200 bg-white mb-[12px]`}
                    style={{ height: infoVisible ? "calc(100vh - 372px)" : "calc(100vh - 243px)" }}
                >
                    <div className={"py-[24px] px-[24px] h-full"}>
                        <div className={"relative h-full"}>
                            <div
                                className={
                                    "h-[full] absolute top-1/2 left-0/4 -translate-x-1/2 -translate-y-1/2 -rotate-90 pt-[22px]"
                                }
                            >
                                <PrimaryText
                                    size={"xs-medium"}
                                    weight={"book"}
                                    className={" flex tracking-[3px] h-full text-[#344054]"}
                                    uppercase
                                >
                                    {selectedProductResult?.selectedResults?.LogViewLabel || "BSR Large Category"}
                                </PrimaryText>
                            </div>
                            <div className={"relative pb-[21px] graph"} style={{ height: "calc(100% - 20px)" }}>
                                <ResponsiveContainer width="100%" height="100%">
                                    <LineChart
                                        width={400}
                                        height={200}
                                        data={rangeDataWithFilledGaps}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="0 0" vertical={false} />

                                        <XAxis
                                            dataKey="timestamp"
                                            tickLine={{ display: "none" }}
                                            tick={{ fill: "#98A2B3" }}
                                            axisLine={{ stroke: "#EAECF0" }}
                                            className={
                                                "text-[6px] mt-[2px] tracking-[1px] text-[#98A2B3] font-normal uppercase"
                                            }
                                            dy={11.6}
                                            angle={-45}
                                            tickFormatter={(tickValue) => {
                                                if (tickValue) {
                                                    tickValue = dayjs(tickValue).format("MMM-DD")
                                                }

                                                return tickValue
                                            }}
                                        />

                                        <YAxis
                                            tickCount={8}
                                            domain={["dataMin", "dataMax"]}
                                            tickLine={{ display: "none" }}
                                            className={"text-[10px] tracking-[1px] font-normal"}
                                            tick={{ fill: "#98A2B3" }}
                                            axisLine={{ stroke: "#EAECF0" }}
                                            interval={0}
                                            tickFormatter={(tickValue) => {
                                                if (tickValue > 100) {
                                                    return Math.round(tickValue / 100) * 100
                                                }
                                                return tickValue
                                            }}
                                            reversed={
                                                selectedResults === "bsr_small" || selectedResults === "bsr_large"
                                            }
                                        />

                                        {selectedProducts &&
                                            selectedProducts.selectedProducts.map(
                                                (product: IGroupProductData, index) => {
                                                    return (
                                                        <>
                                                            <Line
                                                                key={index + product?.actual_asin}
                                                                type="bump"
                                                                dataKey={(data) => {
                                                                    try {
                                                                        if (
                                                                            data[product?.actual_asin].hasOwnProperty(
                                                                                "was_filled"
                                                                            ) &&
                                                                            data[product?.actual_asin]["was_filled"] ===
                                                                                true
                                                                        ) {
                                                                            return null
                                                                        }
                                                                        return data[product?.actual_asin][
                                                                            selectedProductResult?.selectedResults
                                                                                ?.value || "bsr_large"
                                                                        ]
                                                                    } catch (error) {
                                                                        return null
                                                                    }
                                                                }}
                                                                stroke={product?.color?.default}
                                                                activeDot={<></>}
                                                                dot={<></>}
                                                                strokeWidth={1.6}
                                                                isAnimationActive={true}
                                                            />
                                                            <Line
                                                                key={index + "graph" + product?.actual_asin}
                                                                strokeWidth={1.6}
                                                                connectNulls
                                                                strokeDasharray="4 4"
                                                                type={"bump"}
                                                                dataKey={
                                                                    product?.actual_asin +
                                                                    `[${
                                                                        selectedProductResult?.selectedResults?.value ||
                                                                        "bsr_large"
                                                                    }]`
                                                                }
                                                                dot={(props: DotProps) => {
                                                                    return <DotView {...props} product={product} />
                                                                }}
                                                                activeDot={(props: DotProps) => {
                                                                    return <DotView {...props} product={product} />
                                                                }}
                                                                stroke={product?.color?.default}
                                                            />
                                                        </>
                                                    )
                                                }
                                            )}
                                        <Tooltip cursor={false} wrapperStyle={{ display: "none" }} />
                                        {/* <Brush /> */}
                                    </LineChart>
                                </ResponsiveContainer>

                                <GraphTooltip
                                    id={value?.id!}
                                    over={over}
                                    tooltipVisible={tooltipVisible}
                                    tooltip={tooltip}
                                    product={value?.product}
                                    isFirst={
                                        //     graphData.some((item) => {
                                        //     debugger
                                        //     return Object.values(item).some(
                                        //         (productData: any) =>
                                        //             productData.isFirst === true &&
                                        //             productData.timestamp === value?.product?.timestamp
                                        //     )
                                        // })
                                        !!value?.product.isFirst
                                    }
                                    color={value?.color || ASIN_PRODUCTS_COLOR[0]}
                                />
                            </div>

                            <div className="flex flex-col">
                                <PrimaryText
                                    size={"xs-medium"}
                                    weight={"book"}
                                    uppercase
                                    className={
                                        "flex items-center justify-center text-gray-700 tracking-[5px] leading-[10px]"
                                    }
                                >
                                    {rangeData.length > 0 && (
                                        <>
                                            {dayjs(rangeData[0].timestamp).format(
                                                dayjs(rangeData[0].timestamp).year() !==
                                                    dayjs(rangeData[rangeData.length - 1].timestamp).year()
                                                    ? "MMMM YYYY"
                                                    : "MMMM"
                                            )}{" "}
                                            / {dayjs(rangeData[rangeData.length - 1].timestamp).format("MMMM YYYY")}
                                        </>
                                    )}
                                </PrimaryText>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
